import React from 'react';
import { Link } from 'react-router-dom';
import { DropDownMenu } from '../dropDownMenu';
import { DropDownLanguage } from '../dropDownLanguage/DropDownLanguage';

import './style.scss';

export const Header: React.FC = () => (
  <header>
    <Link
      className='logo'
      to='/'
      title='MenuApp'
    >
      <b>Menu</b>App
    </Link>
    <div
      className='menu'
      id='email'
    >
      <DropDownLanguage />
      <DropDownMenu />
    </div>
  </header>
);
