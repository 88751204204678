import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';

export type AssetAction = ActionType<typeof actions>;
export type AssetState = {
  readonly isFetching: boolean;
};

export const assetsReducer = combineReducers<AssetState, AssetAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.DELETE_ASSET_REQUEST:
        return true;

      case CONST.DELETE_ASSET_SUCCESS:
      case CONST.DELETE_ASSET_ERROR:
        return false;

      default:
        return state;
    }
  },
});
