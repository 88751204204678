import { FormikConfig } from 'formik';
import * as yup from 'yup';
import { AnySchema } from 'yup';

import { OnboardingFormProps, OnboardingFormValues } from '.';
import { User } from '../../reducers/user/model';
import { OrganisationModel } from '../../reducers/organisation/model';

export const initFormik = (
  props: OnboardingFormProps,
  user: User | null,
  organisation: OrganisationModel | null,
): FormikConfig<OnboardingFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: user?.name ?? '',
    surname: user?.surname ?? '',
    phone: user?.phone ?? '',
    isCompany: organisation?.invoice?.isCompany ?? true,
    street: organisation?.invoice?.street ?? '',
    postalCode: organisation?.invoice?.postalCode ?? '',
    city: organisation?.invoice?.city ?? '',
    country: organisation?.invoice?.country ?? '',
    companyName: organisation?.invoice?.companyName ?? '',
    taxNumber: organisation?.invoice?.taxNumber ?? '',
  },
  validationSchema: () =>
    yup.object().shape<Record<keyof OnboardingFormValues, AnySchema>>({
      name: yup.string().required('Imię jest wymagane'),
      surname: yup.string().required('Nazwisko jest wymagane'),
      phone: yup.string().required('Telefon jest wymagany'),
      street: yup.string().required('Ulica jest wymagana'),
      postalCode: yup.string().required('Kod pocztowy jest wymagany'),
      city: yup.string().required('Miejscowość jest wymagana'),
      country: yup.string().required('Kraj jest wymagany'),
      isCompany: yup.boolean(),
      companyName: yup.string().when(['isCompany'], {
        is: true,
        then: schema => schema.required('Nazwa firmy jest wymagana'),
        otherwise: schema => schema,
      }),
      taxNumber: yup.string().when(['isCompany'], {
        is: true,
        then: schema => schema.required('NIP jest wymagany'),
        otherwise: schema => schema,
      }),
    }),
  onSubmit: values => {
    props.onSubmit(values);
  },
});
