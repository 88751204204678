import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { GET_DISH_LIST_SUCCESS } from './consts';
import { Dish } from './model';
import * as categoryActions from '../category/actions';
import * as menuActions from '../menu/actions';
import { GET_CATEGORY_LIST_SUCCESS } from '../category/consts';
import { GET_RESTAURANT_MENUS_SUCCESS } from '../menu/consts';

export type DishAction =
  | ActionType<typeof actions>
  | ActionType<typeof categoryActions>
  | ActionType<typeof menuActions>;
export type DishState = {
  readonly isFetching: boolean;
  readonly dishList: Dish[];
  readonly dish: Dish | null;
};

export const dishReducer = combineReducers<DishState, DishAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_DISH_LIST_REQUEST:
      case CONST.ADD_DISH_REQUEST:
      case CONST.UPDATE_DISH_REQUEST:
      case CONST.DELETE_DISH_REQUEST:
      case CONST.GET_DISH_REQUEST:
        return true;

      case CONST.GET_DISH_LIST_SUCCESS:
      case CONST.GET_DISH_LIST_ERROR:
      case CONST.ADD_DISH_SUCCESS:
      case CONST.ADD_DISH_ERROR:
      case CONST.UPDATE_DISH_SUCCESS:
      case CONST.UPDATE_DISH_ERROR:
      case CONST.DELETE_DISH_SUCCESS:
      case CONST.DELETE_DISH_ERROR:
      case CONST.GET_DISH_SUCCESS:
      case CONST.GET_DISH_ERROR:
        return false;

      default:
        return state;
    }
  },
  dishList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_DISH_LIST_SUCCESS:
        return action.payload.dishList;

      case GET_CATEGORY_LIST_SUCCESS:
        return [];

      case CONST.ADD_DISH_SUCCESS:
        return [...state, action.payload.dish];

      case CONST.UPDATE_DISH_SUCCESS: {
        const dishIdx = state.findIndex(item => item._id === action.payload.dish._id);
        state.splice(dishIdx, 1, action.payload.dish);

        return [...state];
      }

      case CONST.DELETE_DISH_SUCCESS:
        return state.filter(dish => dish._id !== action.payload.dishId);

      default:
        return state;
    }
  },
  dish: (state = null, action) => {
    switch (action.type) {
      case CONST.GET_DISH_SUCCESS:
      case CONST.UPDATE_DISH_SUCCESS:
        return action.payload.dish;

      case GET_RESTAURANT_MENUS_SUCCESS:
      case GET_DISH_LIST_SUCCESS:
      case GET_CATEGORY_LIST_SUCCESS:
        return null;

      default:
        return state;
    }
  },
});
