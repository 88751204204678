import React from 'react';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Paths } from '../../router/paths';
import { initFormik } from './formik';
import { useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { FormItem } from '../../component/formItems/formItem/formItem';

import './style.scss';

export type ResetPasswordFormValues = {
  email: string;
};

export type ResetPasswordFormProps = {
  showMessage: boolean;
  onSubmit: (values: ResetPasswordFormValues) => void;
};

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = props => {
  const { showMessage } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.user.isFetching);
  const formik = useFormik(initFormik(props));

  return (
    <div className='resetPasswordForm'>
      {!showMessage && (
        <Form layout='vertical'>
          <FormItem
            label='E-mail'
            required={true}
            error={formik.touched.email && formik.errors.email}
          >
            <Input
              name='email'
              value={formik.values.email}
              placeholder='E-mail'
              onChange={formik.handleChange}
              prefix={<UserOutlined />}
            />
          </FormItem>
          <Row>
            <Button
              type='primary'
              size='large'
              htmlType='submit'
              loading={isFetching}
              onClick={formik.submitForm}
            >
              Zresetuj hasło
            </Button>
          </Row>
          <Link
            className='link'
            to={Paths.LOGIN_PAGE}
          >
            Powrót do strony logowania
          </Link>
        </Form>
      )}
      {showMessage && (
        <div className='informMessage'>
          Wysłaliśmy Ci e-mail, z linkiem do resetu hasła. Sprawdź pocztę z podanym przez Ciebie adresem e-mail.
        </div>
      )}
    </div>
  );
};
