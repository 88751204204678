import React, { ReactNode } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { authService } from '../../services/authService/authService';
import { Paths } from '../paths';

type Props = RouteProps & {
  children?: ReactNode;
};

export const AuthRoute: React.FC<Props> = props => {
  const { children, ...rest } = props;
  const isAuth = authService.isLoggedIn();
  if (!isAuth) {
    authService.logout();
  }

  return (
    <Route
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: Paths.LOGIN_PAGE,
              state: { from: location },
            }}
          />
        )
      }
      {...rest}
    />
  );
};
