import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';

import { LayoutMain } from '../../layout/main';
import { Paths } from '../../router/paths';
import { useDispatch } from 'react-redux';
import { getOrganisationRequest } from '../../reducers/organisation/actions';

export const SubscriptionSuccessPage: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrganisationRequest());
  }, [dispatch]);

  return (
    <LayoutMain>
      <Result
        status='success'
        title='Płatność zakończona sukcesem!'
        subTitle='Możesz zacząć konfigurować swoją restaurację.'
        extra={[
          <Link to={Paths.HOME_PAGE}>
            <Button
              type='primary'
              key='console'
            >
              Przejdź do strony głównej
            </Button>
          </Link>,
        ]}
      />
    </LayoutMain>
  );
};
