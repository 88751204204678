export const GET_RESTAURANT_MENUS_REQUEST = '@menu/GET_RESTAURANT_MENUS_REQUEST';
export const GET_RESTAURANT_MENUS_SUCCESS = '@menu/GET_RESTAURANT_MENUS_SUCCESS';
export const GET_RESTAURANT_MENUS_ERROR = '@menu/GET_RESTAURANT_MENUS_ERROR';

export const ADD_RESTAURANT_MENU_REQUEST = '@menu/ADD_RESTAURANT_MENU_REQUEST';
export const ADD_RESTAURANT_MENU_SUCCESS = '@menu/ADD_RESTAURANT_MENU_SUCCESS';
export const ADD_RESTAURANT_MENU_ERROR = '@menu/ADD_RESTAURANT_MENU_ERROR';

export const UPDATE_RESTAURANT_MENU_REQUEST = '@menu/UPDATE_RESTAURANT_MENU_REQUEST';
export const UPDATE_RESTAURANT_MENU_SUCCESS = '@menu/UPDATE_RESTAURANT_MENU_SUCCESS';
export const UPDATE_RESTAURANT_MENU_ERROR = '@menu/UPDATE_RESTAURANT_MENU_ERROR';

export const DELETE_RESTAURANT_MENU_REQUEST = '@menu/DELETE_RESTAURANT_MENU_REQUEST';
export const DELETE_RESTAURANT_MENU_SUCCESS = '@menu/DELETE_RESTAURANT_MENU_SUCCESS';
export const DELETE_RESTAURANT_MENU_ERROR = '@menu/DELETE_RESTAURANT_MENU_ERROR';

export const GET_RESTAURANT_MENU_REQUEST = '@category/GET_RESTAURANT_MENU_REQUEST';
export const GET_RESTAURANT_MENU_SUCCESS = '@category/GET_RESTAURANT_MENU_SUCCESS';
export const GET_RESTAURANT_MENU_ERROR = '@category/GET_RESTAURANT_MENU_ERROR';
