import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { Category } from './model';
import * as menuActions from '../menu/actions';
import { GET_RESTAURANT_MENUS_SUCCESS } from '../menu/consts';

export type CategoryAction = ActionType<typeof actions> | ActionType<typeof menuActions>;
export type CategoryState = {
  readonly isFetching: boolean;
  readonly categoryList: Category[];
  readonly category: Category | null;
};

export const categoryReducer = combineReducers<CategoryState, CategoryAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_CATEGORY_LIST_REQUEST:
      case CONST.ADD_CATEGORY_REQUEST:
      case CONST.UPDATE_CATEGORY_REQUEST:
      case CONST.DELETE_CATEGORY_REQUEST:
        return true;

      case CONST.GET_CATEGORY_LIST_SUCCESS:
      case CONST.GET_CATEGORY_LIST_ERROR:
      case CONST.ADD_CATEGORY_SUCCESS:
      case CONST.ADD_CATEGORY_ERROR:
      case CONST.UPDATE_CATEGORY_SUCCESS:
      case CONST.UPDATE_CATEGORY_ERROR:
      case CONST.DELETE_CATEGORY_SUCCESS:
      case CONST.DELETE_CATEGORY_ERROR:
        return false;

      default:
        return state;
    }
  },
  categoryList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_CATEGORY_LIST_SUCCESS:
        return action.payload.categoryList;

      case GET_RESTAURANT_MENUS_SUCCESS:
        return [];

      case CONST.ADD_CATEGORY_SUCCESS:
        return [...state, action.payload.category];

      case CONST.UPDATE_CATEGORY_SUCCESS: {
        const categoryIdx = state.findIndex(item => item._id === action.payload.category._id);
        state.splice(categoryIdx, 1, action.payload.category);

        return [...state];
      }

      case CONST.DELETE_CATEGORY_SUCCESS:
        return state.filter(category => category._id !== action.payload.categoryId);

      default:
        return state;
    }
  },
  category: (state = null, action) => {
    switch (action.type) {
      case CONST.GET_CATEGORY_SUCCESS:
      case CONST.UPDATE_CATEGORY_SUCCESS:
        return action.payload.category;

      default:
        return state;
    }
  },
});
