import { Form } from 'antd';
import { FC, ReactNode } from 'react';

type Props = {
  label?: string;
  required?: boolean;
  error?: string | boolean;
  hide?: boolean;
  children?: ReactNode;
};
export const FormItem: FC<Props> = ({ hide, children, error, ...rest }) => {
  if (hide) {
    return null;
  }

  return (
    <Form.Item
      {...rest}
      validateStatus={error ? 'error' : ''}
      hasFeedback={true}
      help={error}
    >
      {children}
    </Form.Item>
  );
};
