import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { notification } from 'antd';

import { apiClientUser } from '../../services/apiClient/users';
import { authService } from '../../services/authService/authService';
import {
  loginError,
  loginRequest,
  loginSuccess,
  resetPasswordError,
  resetPasswordRequest,
  resetPasswordSuccess,
  setUser,
  setPasswordError,
  setPasswordRequest,
  setPasswordSuccess,
  getUserError,
  getUserSuccess,
  getUserRequest,
  updateUserLoginRequest,
  updateUserLoginSuccess,
  updateUserLoginError,
  addUserRequest,
  addUserSuccess,
  addUserError,
  getUsersSuccess,
  getUsersError,
  updateUserRequest,
  updateUserSuccess,
  updateUserError,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserError,
  registerUserRequest,
  registerUserError,
  registerUserSuccess,
  activateUserRequest,
  activateUserSuccess,
  activateUserError,
  getMeSuccess,
  getMeError,
  getTokenSuccess,
  getTokenError,
  getTokenRequest,
  logoutUserSuccess,
  logoutUserError,
  onboardUserRequest,
  onboardUserSuccess,
  onboardUserError,
} from './actions';
import * as CONST from './consts';
import { LoginModel, User, UserOnboardResponse } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';

function* loginUser(
  action: ActionType<typeof loginRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<LoginModel>> {
  try {
    const { email, password } = action.payload;
    const response = yield call(apiClientUser.login, email, password);

    authService.setToken(response.data.token);
    yield put(loginSuccess());
    yield put(setUser(response.data.user));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during login',
      description: getErrorMessage(err),
    });
    yield put(loginError(err));
  }
}

function* logoutUser(): Generator<CallEffect | PutEffect, void, ResponseModel<LoginModel>> {
  try {
    yield call(apiClientUser.logout);
    yield put(logoutUserSuccess());
    authService.logout();
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during logout',
      description: getErrorMessage(err),
    });
    yield put(logoutUserError(err));
  }
}

function* resetPasswordUser(
  action: ActionType<typeof resetPasswordRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<User>> {
  try {
    const { email, onSuccess } = action.payload;
    const response = yield call(apiClientUser.resetPassword, email);

    yield put(resetPasswordSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during reset user password',
      description: getErrorMessage(err),
    });
    yield put(resetPasswordError(err));
  }
}

function* registerUser(
  action: ActionType<typeof registerUserRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<User>> {
  try {
    const { email, password, passwordConfirmation } = action.payload;
    const response = yield call(apiClientUser.register, email, password, passwordConfirmation);

    yield put(registerUserSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during register user',
      description: getErrorMessage(err),
    });
    yield put(registerUserError(err));
  }
}

function* activateUser(
  action: ActionType<typeof activateUserRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<User>> {
  const { hash, onSuccess, onError } = action.payload;
  try {
    const response = yield call(apiClientUser.activate, hash);

    yield put(activateUserSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (onError) {
      onError();
    }
    notification.error({
      duration: 0,
      message: 'Error during activate user',
      description: getErrorMessage(err),
    });
    yield put(activateUserError(err));
  }
}

function* setPasswordUser(
  action: ActionType<typeof setPasswordRequest>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { data, onSuccess } = action.payload;
    yield call(apiClientUser.setPassword, data);

    yield put(setPasswordSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during set user password',
      description: getErrorMessage(err),
    });
    yield put(setPasswordError(err));
  }
}

function* getUser(
  action: ActionType<typeof getUserRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<User>> {
  try {
    const { userId } = action.payload;
    const response = yield call(apiClientUser.getUser, userId);

    yield put(getUserSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during getting user',
      description: getErrorMessage(err),
    });
    yield put(getUserError(err));
  }
}

function* updateUserLogin(
  action: ActionType<typeof updateUserLoginRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<User>> {
  try {
    const { user, onSuccess } = action.payload;
    const response = yield call(apiClientUser.updateUser, user);

    yield put(updateUserLoginSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating user',
      description: getErrorMessage(err),
    });
    yield put(updateUserLoginError(err));
  }
}
function* onboardUser(
  action: ActionType<typeof onboardUserRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<UserOnboardResponse>> {
  try {
    const { data } = action.payload;
    const response = yield call(apiClientUser.onboardUser, data);

    yield put(onboardUserSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during onboarding user',
      description: getErrorMessage(err),
    });
    yield put(onboardUserError(err));
  }
}

function* getUsers(): Generator<CallEffect | PutEffect, void, ResponseModel<User[]>> {
  try {
    const response = yield call(apiClientUser.getUsers);

    yield put(getUsersSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get users',
      description: getErrorMessage(err),
    });
    yield put(getUsersError(err));
  }
}

function* addUser(
  action: ActionType<typeof addUserRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<User>> {
  try {
    const { user, onSuccess } = action.payload;
    const response = yield call(apiClientUser.addUser, user);

    yield put(addUserSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding user',
      description: getErrorMessage(err),
    });
    yield put(addUserError(err));
  }
}

function* updateUser(
  action: ActionType<typeof updateUserRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<User>> {
  try {
    const { user, onSuccess } = action.payload;
    const response = yield call(apiClientUser.updateUser, user);

    yield put(updateUserSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating user',
      description: getErrorMessage(err),
    });
    yield put(updateUserError(err));
  }
}

function* deleteUser(action: ActionType<typeof deleteUserRequest>): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { userId, onSuccess } = action.payload;
    yield call(apiClientUser.deleteUser, userId);

    yield put(deleteUserSuccess(userId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting user',
      description: getErrorMessage(err),
    });
    yield put(deleteUserError(err));
  }
}

function* getMe(): Generator<CallEffect | PutEffect, void, ResponseModel<User>> {
  try {
    const response = yield call(apiClientUser.getMe);

    yield put(getMeSuccess(response.data));
    yield put(loginSuccess());
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get me',
      description: getErrorMessage(err),
    });
    yield put(getMeError(err));
  }
}

function* getToken(
  action: ActionType<typeof getTokenRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<{ token: string }>> {
  try {
    const response = yield call(apiClientUser.getToken);

    yield put(getTokenSuccess(response.data.token));
    authService.setToken(response.data.token);
    if (action.payload.callback) {
      action.payload.callback(response.data.token);
    }
  } catch (err) {
    yield put(getTokenError(err));
    if (action.payload.callback) {
      action.payload.callback();
    }
  }
}

export function* watchUserSaga(): Generator {
  yield takeLatest(CONST.LOGIN_REQUEST, loginUser);
  yield takeLatest(CONST.LOGOUT_USER_REQUEST, logoutUser);
  yield takeLatest(CONST.RESET_PASSWORD_USER_REQUEST, resetPasswordUser);
  yield takeLatest(CONST.SET_PASSWORD_USER_REQUEST, setPasswordUser);
  yield takeLatest(CONST.GET_USER_REQUEST, getUser);
  yield takeLatest(CONST.UPDATE_USER_LOGIN_REQUEST, updateUserLogin);
  yield takeLatest(CONST.ONBOARD_USER_REQUEST, onboardUser);
  yield takeLatest(CONST.GET_USERS_REQUEST, getUsers);
  yield takeLatest(CONST.ADD_USER_REQUEST, addUser);
  yield takeLatest(CONST.UPDATE_USER_REQUEST, updateUser);
  yield takeLatest(CONST.DELETE_USER_REQUEST, deleteUser);
  yield takeLatest(CONST.REGISTER_USER_REQUEST, registerUser);
  yield takeLatest(CONST.ACTIVATE_USER_REQUEST, activateUser);
  yield takeLatest(CONST.GET_ME_REQUEST, getMe);
  yield takeLatest(CONST.GET_TOKEN_REQUEST, getToken);
}
