type RecurringModel = {
  interval: IntervalType;
  interval_count: number;
  usage_type: string;
};

type PriceModel = {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  livemode: boolean;
  product: string;
  recurring: RecurringModel;
  tax_behavior: string;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
};

export type ProductModel = {
  id: string;
  object: string;
  active: boolean;
  created: number;
  default_price: PriceModel;
  prices: PriceModel[];
  description: string;
  features: [];
  images: [];
  livemode: boolean;
  metadata: {};
  name: string;
  tax_code: string;
  type: string;
  updated: number;
};

export enum IntervalType {
  MONTH = 'month',
  YEAR = 'year',
}

export const IntervalTypeNames = {
  [IntervalType.MONTH]: 'miesiąc',
  [IntervalType.YEAR]: 'rok',
};
