import { BaseAPI } from '../base';
import { Restaurant } from '../../../reducers/restaurants/model';
import { ResponseModel } from '../../../reducers/model';

class APIRestaurants extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/restaurants`;
  }

  public getRestaurants = async (): Promise<ResponseModel<Restaurant[]>> => {
    return this.authFetch<ResponseModel<Restaurant[]>>(this.apiURL, { method: 'GET' });
  };

  public addRestaurant = async (
    data: Omit<Restaurant, '_id' | 'createdAt' | 'updatedAt'>,
  ): Promise<ResponseModel<Restaurant>> => {
    return this.authFetch<ResponseModel<Restaurant>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateRestaurant = async (data: Restaurant): Promise<ResponseModel<Restaurant>> => {
    return this.authFetch<ResponseModel<Restaurant>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteRestaurant = async (restaurantId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${restaurantId}`, { method: 'DELETE' });
  };
}

export const apiRestaurants = new APIRestaurants();
