import { BaseAPI } from '../base';
import { ProductModel } from '../../../reducers/subscriptions/model';
import { ResponseModel } from '../../../reducers/model';

class APISubscriptions extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/subscriptions`;
  }

  public getSubscriptions = async (): Promise<ResponseModel<ProductModel[]>> => {
    return this.authFetch<ResponseModel<ProductModel[]>>(`${this.apiURL}/products`, { method: 'GET' });
  };

  public createCheckoutSession = async (priceId: string): Promise<ResponseModel<void>> => {
    return this.authFetch<ResponseModel<void>>(`${this.apiURL}/create-checkout-session`, {
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify({
        priceId,
      }),
    });
  };
}

export const apiSubscriptions = new APISubscriptions();
