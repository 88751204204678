import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import type { RadioChangeEvent } from 'antd';
import { Button, Col, Divider, Form, Input, Radio, Row, Select, Switch } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
import { ColorPicker } from '../../component/formItems/colorPicker';
import { tabOptions, TabOptions } from '../utils/tabOptions';
import { Restaurant } from '../../reducers/restaurants/model';
import { AssetBucket, AssetModel } from '../../reducers/assets/model';
import { countriesMap } from '../../utils/countries';
import { MultiLangValue } from '../../types/internal/language';
import { initFormik } from './formik';
import { useRestaurantLanguages } from '../../utils/customHooks/useRestaurantLanguages';
import { RootReducerState } from '../../reducers';
import { FormItemMultiLang } from '../../component/formItems/formItemMultiLang/FormItemMultiLang';
import { TextareaMultiLang } from '../../component/formItems/textareaMultiLang/textareaMultiLang';
import { FormItem } from '../../component/formItems/formItem/formItem';
import { UploadImage } from '../../component/uploadImage /UploadImage';

import './style.scss';

export type RestaurantFormValues = {
  name: string;
  about: MultiLangValue;
  assets: AssetModel[];
  street: string;
  city: string;
  zip: string;
  province?: string;
  country: string;
  reviewRestaurant: boolean;
  reviewDish: boolean;
  bgColor?: string;
  buttonBgColor?: string;
  textColor?: string;
  facebook?: string;
  instagram?: string;
  linkedIn?: string;
  tiktok?: string;
  twitter?: string;
  languages: string[];
  defaultLanguage: string;
};

export type RestaurantFormProps = {
  restaurant?: Restaurant;
  onSubmit: (values: RestaurantFormValues) => void;
  onCancel: () => void;
};

export const RestaurantForm: FC<RestaurantFormProps> = props => {
  const { restaurant } = props;
  const [activeTab, setActiveTab] = useState(TabOptions.LANGUAGES);
  const [restaurantLanguages, setRestaurantLanguages] = useState<string[]>([]);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.restaurants.isFetching);
  const { languages } = useRestaurantLanguages(restaurantLanguages);
  const formik = useFormik(initFormik(props, languages));

  useEffect(() => {
    const languagesFromForm = [formik.values.defaultLanguage, ...formik.values.languages];
    if (!isEqual(restaurantLanguages, languagesFromForm)) {
      setRestaurantLanguages(languagesFromForm);
    }
  }, [formik.values, restaurantLanguages, setRestaurantLanguages]);

  const onChangeTab = ({ target: { value } }: RadioChangeEvent) => {
    setActiveTab(value);
  };

  const setErrorClass = (value: TabOptions) => {
    if (
      value === TabOptions.MAIN &&
      ((formik.errors.name && formik.touched.name) || (formik.errors.about && formik.touched.about))
    ) {
      return 'red';
    }
    if (
      value === TabOptions.ADDRESS &&
      ((formik.errors.street && formik.touched.street) ||
        (formik.errors.city && formik.touched.city) ||
        (formik.errors.zip && formik.touched.zip) ||
        (formik.errors.country && formik.touched.country))
    ) {
      return 'red';
    }

    if (
      value === TabOptions.LANGUAGES &&
      ((formik.errors.defaultLanguage && formik.touched.defaultLanguage) ||
        (formik.errors.languages && formik.touched.languages))
    ) {
      return 'red';
    }

    return '';
  };

  const removeLanguage = (language: string) => {
    const filterLang = formik.values.languages.filter(lang => lang !== language);
    void formik.setFieldValue('languages', filterLang);
  };

  return (
    <Form
      className='restaurantForm'
      layout={'vertical'}
    >
      <div className='tabFormMenu'>
        <div className='tabFormMenuWrapper'>
          <Radio.Group
            onChange={onChangeTab}
            value={activeTab}
            optionType='button'
          >
            {tabOptions.map(option => (
              <Radio.Button
                key={option.value}
                className={setErrorClass(option.value)}
                value={option.value}
                disabled={option.value !== TabOptions.LANGUAGES && !formik.values.defaultLanguage}
              >
                {option.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
      </div>
      <div className={activeTab !== TabOptions.LANGUAGES ? 'hidden restaurantFormTab' : 'restaurantFormTab'}>
        <div className='languageTab'>
          <p>Język domyślny</p>
          {formik.values.defaultLanguage && (
            <div className='selectedLanguage'>
              <div className='defaultImg'>{countriesMap[formik.values.defaultLanguage].flag}</div>
            </div>
          )}
          <div className='langFieldWrapper'>
            <FormItem error={formik.touched.defaultLanguage && formik.errors.defaultLanguage}>
              <Select
                showSearch
                className='defaultLanguageSelect'
                value={formik.values.defaultLanguage}
                onChange={(value: string) => formik.setFieldValue('defaultLanguage', value)}
                filterOption={(input: string, option: any) =>
                  (option?.key ?? '').toLowerCase().includes(input.toLowerCase())
                }
              >
                {Object.keys(countriesMap).map((iso3: string) => (
                  <Select.Option
                    key={countriesMap[iso3].name}
                    value={iso3}
                  >
                    {countriesMap[iso3].flag} {countriesMap[iso3].name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </div>
          <Divider />
          <p>Dodatkowe języki</p>
          <div className='selectedLanguage'>
            {formik.values.languages.map(language => (
              <div
                key={language}
                className='defaultImg'
              >
                {countriesMap[language].flag}
                <Button
                  onClick={() => removeLanguage(language)}
                  shape='circle'
                  icon={<CloseOutlined />}
                />
              </div>
            ))}
          </div>
          <div className='langFieldWrapper'>
            <FormItem error={formik.touched.languages && (formik.errors.languages as string)}>
              <Select
                showSearch
                disabled={!formik.values.defaultLanguage}
                mode='multiple'
                className='selectedLanguageSelect'
                //@ts-expect-error
                value={formik.values.languages}
                onChange={(value: string) => formik.setFieldValue('languages', value)}
                filterOption={(input: string, option: any) =>
                  (option?.key ?? '').toLowerCase().includes(input.toLowerCase())
                }
              >
                {Object.keys(countriesMap)
                  .filter(iso3 => iso3 != formik.values.defaultLanguage)
                  .map((iso3: string) => (
                    <Select.Option
                      key={countriesMap[iso3].name}
                      value={iso3}
                    >
                      {countriesMap[iso3].flag} {countriesMap[iso3].name}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
          </div>
        </div>
      </div>
      <div className={activeTab !== TabOptions.MAIN ? 'hidden restaurantFormTab' : 'restaurantFormTab'}>
        <Row gutter={48}>
          <Col span={12}>
            <FormItem
              label='Nazwa restauracji'
              required={true}
              error={formik.touched.name && formik.errors.name}
            >
              <Input
                name='name'
                placeholder='Nazwa restauracji'
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </FormItem>
            <FormItem
              label='Logo restauracji'
              error={formik.touched.assets && (formik.errors.assets as string)}
            >
              <UploadImage
                assets={formik.values.assets}
                bucket={AssetBucket.RESTAURANT_LOGO}
                onChange={assets => formik.setFieldValue('assets', assets)}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItemMultiLang
              label='Opis'
              required={true}
              errors={formik.touched.assets && formik.errors.about}
            >
              <TextareaMultiLang
                name='about'
                showCount
                maxLength={200}
                rows={7}
                placeholder='Opis'
                forceLanguages={restaurantLanguages}
                value={formik.values.about}
                onChange={value => formik.setFieldValue('about', value)}
              />
            </FormItemMultiLang>
          </Col>
        </Row>
      </div>
      <div className={activeTab !== TabOptions.ADDRESS ? 'hidden restaurantFormTab' : 'restaurantFormTab'}>
        <Row gutter={48}>
          <Col span={12}>
            <FormItem
              label='Ulica'
              required={true}
              error={formik.touched.street && formik.errors.street}
            >
              <Input
                name='street'
                placeholder='Ulica'
                value={formik.values.street}
                onChange={formik.handleChange}
              />
            </FormItem>
            <FormItem
              label='Kod pocztowy'
              required={true}
              error={formik.touched.zip && formik.errors.zip}
            >
              <Input
                name='zip'
                placeholder='Kod pocztowy'
                value={formik.values.zip}
                onChange={formik.handleChange}
              />
            </FormItem>
            <FormItem
              label='Kraj'
              required={true}
              error={formik.touched.country && formik.errors.country}
            >
              <Select
                defaultValue='PL'
                value={formik.values.country}
                onChange={(value: string) => formik.setFieldValue('country', value)}
              >
                <Select.Option value='PL'>Polska</Select.Option>
              </Select>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label='Miasto'
              required={true}
              error={formik.touched.city && formik.errors.city}
            >
              <Input
                name='city'
                placeholder='Miasto'
                value={formik.values.city}
                onChange={formik.handleChange}
              />
            </FormItem>
            <FormItem
              label='Województwo'
              error={formik.touched.province && formik.errors.province}
            >
              <Input
                name='province'
                placeholder='Województwo'
                value={formik.values.province}
                onChange={formik.handleChange}
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div className={activeTab !== TabOptions.BRANDING ? 'hidden restaurantFormTab' : 'restaurantFormTab'}>
        <Row gutter={48}>
          <Col span={12}>
            <FormItem
              label='Kolor tła'
              error={formik.touched.bgColor && formik.errors.bgColor}
            >
              <ColorPicker
                name='bgColor'
                value={formik.values.bgColor}
                onChange={formik.setFieldValue}
              />
            </FormItem>
            <FormItem
              label='Kolor przycisku'
              error={formik.touched.buttonBgColor && formik.errors.buttonBgColor}
            >
              <ColorPicker
                name='buttonBgColor'
                value={formik.values.buttonBgColor}
                onChange={formik.setFieldValue}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label='Kolor tekstu'
              error={formik.touched.textColor && formik.errors.textColor}
            >
              <ColorPicker
                value={formik.values.textColor}
                name='textColor'
                onChange={formik.setFieldValue}
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div className={activeTab !== TabOptions.SETTINGS ? 'hidden restaurantFormTab' : 'restaurantFormTab'}>
        <Row gutter={48}>
          <Col span={12}>
            <FormItem
              label='Link do profilu na Facebooku'
              error={formik.touched.facebook && formik.errors.facebook}
            >
              <Input
                name='facebook'
                placeholder='Link do profilu na Facebooku'
                value={formik.values.facebook}
                onChange={formik.handleChange}
              />
            </FormItem>
            <FormItem
              label='Link do profilu na Instagramie'
              error={formik.touched.instagram && formik.errors.instagram}
            >
              <Input
                name='instagram'
                placeholder='Link do profilu na Instagramie'
                value={formik.values.instagram}
                onChange={formik.handleChange}
              />
            </FormItem>
            <FormItem
              label='Link do profilu na LinkedIn'
              error={formik.touched.linkedIn && formik.errors.linkedIn}
            >
              <Input
                name='linkedIn'
                placeholder='Link do profilu na LinkedIn'
                value={formik.values.linkedIn}
                onChange={formik.handleChange}
              />
            </FormItem>
            <FormItem
              label='Link do profilu na Tiktok'
              error={formik.touched.tiktok && formik.errors.tiktok}
            >
              <Input
                name='tiktok'
                placeholder='Link do profilu na tiktok'
                value={formik.values.tiktok}
                onChange={formik.handleChange}
              />
            </FormItem>
            <FormItem
              label='Link do profilu na Twitterze'
              error={formik.touched.twitter && formik.errors.twitter}
            >
              <Input
                name='twitter'
                placeholder='Link do profilu na Twitterze'
                value={formik.values.twitter}
                onChange={formik.handleChange}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label='Recenzje restauracji'
              error={formik.touched.reviewRestaurant && formik.errors.reviewRestaurant}
            >
              <Switch
                defaultChecked
                checked={formik.values.reviewRestaurant}
                onChange={checked => formik.setFieldValue('reviewRestaurant', checked)}
              />
            </FormItem>
            <FormItem
              label='Recenzje dań'
              error={formik.touched.reviewDish && formik.errors.reviewDish}
            >
              <Switch
                defaultChecked
                checked={formik.values.reviewDish}
                onChange={checked => formik.setFieldValue('reviewDish', checked)}
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div className='buttonWrapper'>
        <Button onClick={props.onCancel}>Anuluj</Button>
        <Button
          type='primary'
          htmlType='submit'
          loading={isFetching}
          icon={!restaurant && <PlusOutlined />}
          onClick={formik.submitForm}
        >
          {restaurant ? 'Zapisz' : 'Dodaj'} restaurację
        </Button>
      </div>
    </Form>
  );
};
