import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Form, Input, Row, Typography } from 'antd';
import { CheckCircleOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import { initFormik } from './formik';
import { RootReducerState } from '../../reducers';
import { User } from '../../reducers/user/model';
import { Paths } from '../../router/paths';
import { FormItem } from '../../component/formItems/formItem/formItem';

import './style.scss';

const { Text } = Typography;

export type RegistrationFormValues = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

export type RegistrationFormProps = {
  user?: User;
  onSubmit: (values: RegistrationFormValues) => void;
  messageRegistration: boolean;
};

export const RegistrationForm: React.FC<RegistrationFormProps> = props => {
  const { messageRegistration } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.user.isFetching);

  const formik = useFormik(initFormik(props));

  return (
    <div className='registration-form'>
      {!messageRegistration && (
        <Form layout='vertical'>
          <FormItem
            label='E-mail'
            required={true}
            error={formik.touched.email && formik.errors.email}
          >
            <Input
              name='email'
              value={formik.values.email}
              placeholder='E-mail'
              onChange={formik.handleChange}
              prefix={<UserOutlined />}
            />
          </FormItem>
          <FormItem
            label='Hasło:'
            required={true}
            error={formik.touched.password && formik.errors.password}
          >
            <Input.Password
              name='password'
              value={formik.values.password}
              placeholder='Hasło'
              onChange={formik.handleChange}
              prefix={<LockOutlined />}
            />
          </FormItem>
          <FormItem
            label='Potwierdź hasło'
            required={true}
            error={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
          >
            <Input.Password
              name='passwordConfirmation'
              value={formik.values.passwordConfirmation}
              placeholder='Potwierdź hasło'
              onChange={formik.handleChange}
              prefix={<LockOutlined />}
            />
          </FormItem>
          <Row>
            <Button
              type='primary'
              size='large'
              htmlType='submit'
              loading={isFetching}
              onClick={formik.submitForm}
            >
              Stwórz konto
            </Button>
          </Row>
          <Link
            className='link'
            to={Paths.LOGIN_PAGE}
          >
            Masz konto? Zaloguj się
          </Link>
        </Form>
      )}
      {messageRegistration && (
        <div className='registrationMessageWrapper'>
          <div className='registrationMessage'>
            <CheckCircleOutlined className='message-icon' />
            <Text>
              Konto zostało utworzone. Na podany adres e-mail został wysłany link aktywacyjny. Aktywuj konto klikając w
              link wiadomości.
            </Text>
          </div>
          <Link
            className='link'
            to={Paths.LOGIN_PAGE}
          >
            Przejdź do strony logowania
          </Link>
        </div>
      )}
    </div>
  );
};
