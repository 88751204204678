import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { notification } from 'antd';

import { deleteAssetRequest, deleteAssetSuccess, deleteAssetError } from './actions';
import * as CONST from './consts';
import { apiAssets } from '../../services/apiClient/assets';
import { getErrorMessage } from '../../utils/error';

function* deleteAsset(action: ActionType<typeof deleteAssetRequest>): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { assetId, onSuccess } = action.payload;
    yield call(apiAssets.deleteAsset, assetId);

    yield put(deleteAssetSuccess(assetId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting asset',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(deleteAssetError(err));
  }
}

export function* watchAssetsSaga(): Generator {
  yield takeLatest(CONST.DELETE_ASSET_REQUEST, deleteAsset);
}
