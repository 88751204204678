import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { User } from './model';

export type LoginAction = ActionType<typeof actions>;
export type UserState = {
  readonly isFetching: boolean;
  readonly isFetchingResetPassword: boolean;
  readonly loggedIn: boolean;
  readonly userLogin: User | null;
  readonly usersList: User[];
  readonly userLastActivated: User | null;
};

export const userReducer = combineReducers<UserState, LoginAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.LOGIN_REQUEST:
      case CONST.GET_USER_REQUEST:
      case CONST.UPDATE_USER_LOGIN_REQUEST:
      case CONST.ADD_USER_REQUEST:
      case CONST.UPDATE_USER_REQUEST:
      case CONST.REGISTER_USER_REQUEST:
      case CONST.ACTIVATE_USER_REQUEST:
      case CONST.GET_ME_REQUEST:
      case CONST.GET_TOKEN_REQUEST:
      case CONST.ONBOARD_USER_REQUEST:
        return true;

      case CONST.LOGIN_SUCCESS:
      case CONST.LOGIN_ERROR:
      case CONST.GET_USER_SUCCESS:
      case CONST.GET_USER_ERROR:
      case CONST.UPDATE_USER_LOGIN_SUCCESS:
      case CONST.UPDATE_USER_LOGIN_ERROR:
      case CONST.ADD_USER_SUCCESS:
      case CONST.ADD_USER_ERROR:
      case CONST.UPDATE_USER_SUCCESS:
      case CONST.UPDATE_USER_ERROR:
      case CONST.REGISTER_USER_SUCCESS:
      case CONST.REGISTER_USER_ERROR:
      case CONST.ACTIVATE_USER_SUCCESS:
      case CONST.ACTIVATE_USER_ERROR:
      case CONST.GET_ME_SUCCESS:
      case CONST.GET_ME_ERROR:
      case CONST.GET_TOKEN_SUCCESS:
      case CONST.GET_TOKEN_ERROR:
      case CONST.ONBOARD_USER_SUCCESS:
      case CONST.ONBOARD_USER_ERROR:
        return false;

      default:
        return state;
    }
  },
  isFetchingResetPassword: (state = false, action) => {
    switch (action.type) {
      case CONST.RESET_PASSWORD_USER_REQUEST:
        return true;

      case CONST.RESET_PASSWORD_USER_SUCCESS:
      case CONST.RESET_PASSWORD_USER_ERROR:
        return false;

      default:
        return state;
    }
  },
  loggedIn: (state = false, action) => {
    switch (action.type) {
      case CONST.LOGIN_SUCCESS:
      case CONST.GET_TOKEN_SUCCESS:
        return true;

      case CONST.LOGOUT_USER_SUCCESS:
        return false;

      default:
        return state;
    }
  },
  userLogin: (state = null, action) => {
    switch (action.type) {
      case CONST.SET_USER:
        return action.payload;

      case CONST.LOGOUT_USER_SUCCESS:
        return null;

      case CONST.GET_USER_SUCCESS:
        return action.payload.data;

      case CONST.UPDATE_USER_LOGIN_SUCCESS:
      case CONST.GET_ME_SUCCESS:
        return action.payload.user;

      case CONST.ONBOARD_USER_SUCCESS:
        return action.payload.data.user;

      default:
        return state;
    }
  },
  usersList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_USERS_SUCCESS:
        return action.payload.users;

      case CONST.ADD_USER_SUCCESS:
        return [...state, action.payload.user];

      case CONST.UPDATE_USER_SUCCESS: {
        const userIdx = state.findIndex(item => item._id === action.payload.user._id);
        state.splice(userIdx, 1, action.payload.user);

        return [...state];
      }

      case CONST.DELETE_USER_SUCCESS:
        return state.filter(user => user._id !== action.payload.userId);

      default:
        return state;
    }
  },
  userLastActivated: (state = null, action) => {
    switch (action.type) {
      case CONST.ACTIVATE_USER_SUCCESS:
        return action.payload.user;

      default:
        return state;
    }
  },
});
