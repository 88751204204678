import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RegistrationForm, RegistrationFormValues } from '../../forms/registration';
import { LayoutLogin } from '../../layout/login';
import { registerUserRequest } from '../../reducers/user/actions';

export const RegistrationPage: React.FC = () => {
  const [messageRegistration, setMessageRegistration] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleLoginSubmit = (values: RegistrationFormValues) => {
    dispatch(
      registerUserRequest(values.email, values.password, values.passwordConfirmation, () => {
        setMessageRegistration(true);
      }),
    );
  };

  return (
    <LayoutLogin>
      <RegistrationForm
        onSubmit={handleLoginSubmit}
        messageRegistration={messageRegistration}
      />
    </LayoutLogin>
  );
};
