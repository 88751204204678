import { BaseAPI } from '../base';
import { LoginModel, User, UserOnboardRequest, UserOnboardResponse } from '../../../reducers/user/model';
import { ResponseModel } from '../../../reducers/model';

class APIClientUser extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/users`;
  }

  public login = async (email: string, password: string): Promise<ResponseModel<LoginModel>> => {
    return this.noAuthFetch<ResponseModel<LoginModel>>(`${this.apiURL}/login`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
      credentials: 'include',
    });
  };

  public logout = async (): Promise<ResponseModel<void>> => {
    return this.noAuthFetch<ResponseModel<void>>(`${this.apiURL}/logout`, {
      method: 'POST',
      credentials: 'include',
    });
  };

  public getToken = async (): Promise<ResponseModel<{ token: string }>> => {
    return this.noAuthFetch<ResponseModel<{ token: string }>>(`${this.apiURL}/token`, {
      method: 'GET',
      credentials: 'include',
    });
  };

  public getMe = async (): Promise<ResponseModel<User>> => {
    return this.authFetch<ResponseModel<User>>(`${this.apiURL}/me`, { method: 'GET' });
  };

  public resetPassword = async (email: string): Promise<ResponseModel<void>> => {
    return this.noAuthFetch<ResponseModel<void>>(`${this.apiURL}/reset-password`, {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    });
  };

  public setPassword = async (data: {
    password: string;
    passwordConfirmation: string;
    hash: string;
  }): Promise<ResponseModel<User>> => {
    return this.noAuthFetch<ResponseModel<User>>(`${this.apiURL}/set-password`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public getUser = async (userId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${userId}`, { method: 'GET' });
  };

  public updateUser = async (data: Omit<User, 'salt' | 'resetPasswordToken'>): Promise<ResponseModel<User>> => {
    return this.authFetch<ResponseModel<User>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };
  public onboardUser = async (data: UserOnboardRequest): Promise<ResponseModel<UserOnboardResponse>> => {
    return this.authFetch<ResponseModel<UserOnboardResponse>>(`${this.apiURL}/onboard`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public getUsers = async (): Promise<ResponseModel<User[]>> => {
    return this.authFetch<ResponseModel<User[]>>(this.apiURL, { method: 'GET' });
  };

  public addUser = async (
    data: Omit<User, '_id' | 'salt' | 'createdAt' | 'updatedAt' | 'resetPasswordToken' | 'organisationId' | 'ownerId'>,
  ): Promise<ResponseModel<User>> => {
    return this.authFetch<ResponseModel<User>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public deleteUser = async (userId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${userId}`, { method: 'DELETE' });
  };

  public register = async (
    email: string,
    password: string,
    passwordConfirmation: string,
  ): Promise<ResponseModel<User>> => {
    return this.noAuthFetch<ResponseModel<User>>(`${this.apiURL}/register`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
        passwordConfirmation,
        language: 'PL',
      }),
    });
  };

  public activate = async (hash: string): Promise<ResponseModel<User>> => {
    return this.noAuthFetch<ResponseModel<User>>(`${this.apiURL}/activate`, {
      method: 'PATCH',
      body: JSON.stringify({
        hash,
      }),
    });
  };
}

export const apiClientUser = new APIClientUser();
