import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import { CardPage } from '../cardPage/CardPage';
import { RootReducerState } from '../../reducers';
import { setAppSelectedDish } from '../../reducers/app/actions';
import { DishFormValues } from '../../forms/dish';
import { Dish, DishType } from '../../reducers/dish/model';
import { DishForm } from '../../forms/dish';
import {
  addDishRequest,
  deleteDishRequest,
  getDishListRequest,
  getDishRequest,
  updateDishRequest,
} from '../../reducers/dish/actions';
import { ModalHeader } from '../modalHeader/ModalHeader';
import { useRestaurantLanguages } from '../../utils/customHooks/useRestaurantLanguages';

import './style.scss';

export const DishList: React.FC = () => {
  const { defaultLanguage } = useRestaurantLanguages();
  const dishList = useSelector<RootReducerState, Dish[]>(state => state.dish.dishList);
  const isFetchingDishes = useSelector<RootReducerState, boolean>(state => state.dish.isFetching);
  const selectedMenuId = useSelector<RootReducerState, string | null>(state => state.app.selectedMenuId);
  const selectedCategoryId = useSelector<RootReducerState, string | null>(state => state.app.selectedCategoryId);
  const selectedDishId = useSelector<RootReducerState, string | null>(state => state.app.selectedDishId);
  const ownerId = useSelector<RootReducerState, string | undefined>(state => state.user.userLogin?._id);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDish, setSelectedDish] = useState<Dish | undefined>(undefined);
  const [isSearchInput, setSearchInput] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedCategoryId) {
      dispatch(getDishListRequest());
    }
  }, [dispatch, selectedCategoryId]);

  const filteredDishList = useMemo(() => {
    if (isSearchInput && defaultLanguage) {
      const filterDishList = dishList.filter(dish => dish.categoryId === selectedCategoryId);

      return filterDishList.filter(dish => {
        const name = dish.name[defaultLanguage] as string | undefined;
        return name?.toLowerCase().includes(isSearchInput.toLowerCase());
      });
    }
    return dishList.filter(dish => dish.categoryId === selectedCategoryId);
  }, [dishList, selectedCategoryId, isSearchInput, defaultLanguage]);

  const otherDishList = useMemo(() => {
    const dishListByMenu = dishList.filter(dish => dish.menuId === selectedMenuId);

    return dishListByMenu.filter(dish => dish.categoryId !== selectedCategoryId);
  }, [dishList, selectedCategoryId, selectedMenuId]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedDish(undefined);
  };

  const openEditItemModal = (restaurantId: string) => {
    const dish = dishList.find(item => item._id === restaurantId);
    setSelectedDish(dish);
    setIsModalVisible(true);
  };

  const handleSubmit = (values: DishFormValues) => {
    if (selectedMenuId && selectedCategoryId) {
      let dish;
      if (values.type === DishType.MEAL) {
        dish = {
          type: values.type,
          name: values.name,
          description: values.description,
          price: values.price,
          ingredients: values.ingredients,
          allergens: values.allergens,
          weight: values.weight,
          calories: values.calories,
          taste: values.taste,
          kind: values.kind,
          menuId: selectedMenuId,
          categoryId: selectedCategoryId,
          ownerId: ownerId ?? '',
          assets: values.assets,
        };
      } else {
        dish = {
          type: values.type,
          name: values.name,
          description: values.description,
          price: values.price,
          ingredients: values.ingredients,
          allergens: values.allergens,
          calories: values.calories,
          alcohol: values.alcohol,
          capacity: values.capacity,
          taste: values.taste,
          menuId: selectedMenuId,
          categoryId: selectedCategoryId,
          ownerId: ownerId ?? '',
          assets: values.assets,
        };
      }

      if (selectedDish) {
        dispatch(
          updateDishRequest(
            {
              ...selectedDish,
              ...dish,
            },
            () => {
              setIsModalVisible(false);
              setSelectedDish(undefined);
            },
          ),
        );
      } else {
        dispatch(
          addDishRequest(dish, () => {
            setIsModalVisible(false);
            setSelectedDish(undefined);
          }),
        );
      }
    }
  };

  const handleDeleteItem = (id: string) => {
    dispatch(deleteDishRequest(id));
  };

  const openDetailDish = (id: string) => {
    dispatch(getDishRequest(id));
    dispatch(setAppSelectedDish(id));
  };

  return selectedCategoryId ? (
    <>
      <div className='dishListWrapper'>
        <CardPage
          list={filteredDishList}
          isFetching={isFetchingDishes}
          title='Dania'
          selectedId={selectedDishId}
          openEditItemModal={openEditItemModal}
          handleDeleteItem={handleDeleteItem}
          openModal={() => setIsModalVisible(true)}
          label={filteredDishList.length > 0 ? 'danie' : 'pierwsze danie'}
          setSelectedId={setAppSelectedDish}
          searchField={true}
          setSearchInput={setSearchInput}
          openDetailDish={openDetailDish}
        >
          <img
            src='./images/cutlery_icon.png'
            className='dishIcon'
            alt='logo'
          />
        </CardPage>
        <CardPage
          list={otherDishList}
          isFetching={isFetchingDishes}
          title='Pozostałe'
          selectedId={selectedCategoryId}
          openEditItemModal={openEditItemModal}
          handleDeleteItem={handleDeleteItem}
          openModal={() => setIsModalVisible(true)}
          label={filteredDishList.length > 0 ? 'Dodaj danie' : 'Dodaj pierwsze danie'}
          setSelectedId={setAppSelectedDish}
          searchField={true}
          openDetailDish={openDetailDish}
        >
          <img
            src='./images/cutlery_icon.png'
            className='dishIcon'
            alt='logo'
          />
        </CardPage>
      </div>
      <Modal
        width='80%'
        title={
          <ModalHeader
            title='danie/napój'
            selectedElement={!!selectedDish}
            headerIcon={
              <img
                src='./images/cutlery_icon.png'
                className='dishIcon'
                alt='logo'
              />
            }
          />
        }
        open={isModalVisible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={null}
      >
        <DishForm
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          dish={selectedDish}
        />
      </Modal>
    </>
  ) : null;
};
