import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { notification } from 'antd';
import {
  getRestaurantsSuccess,
  getRestaurantsError,
  addRestaurantError,
  addRestaurantSuccess,
  addRestaurantRequest,
  updateRestaurantRequest,
  updateRestaurantSuccess,
  updateRestaurantError,
  deleteRestaurantRequest,
  deleteRestaurantSuccess,
  deleteRestaurantError,
} from './actions';
import * as CONST from './consts';
import { Restaurant } from './model';
import { ResponseModel } from '../model';
import { apiRestaurants } from '../../services/apiClient/restaurants';
import { getErrorMessage } from '../../utils/error';

function* getRestaurants(): Generator<CallEffect | PutEffect, void, ResponseModel<Restaurant[]>> {
  try {
    const response = yield call(apiRestaurants.getRestaurants);

    yield put(getRestaurantsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get restaurants',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(getRestaurantsError(err));
  }
}

function* addRestaurant(
  action: ActionType<typeof addRestaurantRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<Restaurant>> {
  try {
    const { restaurant, onSuccess } = action.payload;
    const response = yield call(apiRestaurants.addRestaurant, restaurant);

    yield put(addRestaurantSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding restaurant',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(addRestaurantError(err));
  }
}

function* updateRestaurant(
  action: ActionType<typeof updateRestaurantRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<Restaurant>> {
  try {
    const { restaurant, onSuccess } = action.payload;
    const response = yield call(apiRestaurants.updateRestaurant, restaurant);

    yield put(updateRestaurantSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating restaurant',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(updateRestaurantError(err));
  }
}

function* deleteRestaurant(
  action: ActionType<typeof deleteRestaurantRequest>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { restaurantId, onSuccess } = action.payload;
    yield call(apiRestaurants.deleteRestaurant, restaurantId);

    yield put(deleteRestaurantSuccess(restaurantId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting restaurant',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(deleteRestaurantError(err));
  }
}

export function* watchRestaurantSaga(): Generator {
  yield takeLatest(CONST.GET_RESTAURANTS_REQUEST, getRestaurants);
  yield takeLatest(CONST.ADD_RESTAURANT_REQUEST, addRestaurant);
  yield takeLatest(CONST.UPDATE_RESTAURANT_REQUEST, updateRestaurant);
  yield takeLatest(CONST.DELETE_RESTAURANT_REQUEST, deleteRestaurant);
}
