import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Button, Form, Input, Select, Switch } from 'antd';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { User, UserGroup, UserGroupNames } from '../../reducers/user/model';
import { RootReducerState } from '../../reducers';
import { countriesMap } from '../../utils/countries';
import { initFormik } from './formik';
import { FormItem } from '../../component/formItems/formItem/formItem';

import './style.scss';

export type UserFormValues = {
  name: string;
  surname: string;
  email: string;
  group: UserGroup;
  active: boolean;
  language: string;
};

export type UserFormProps = {
  user?: User;
  onSubmit: (values: UserFormValues) => void;
  onCancel: () => void;
};

export const UserForm = (props: UserFormProps) => {
  const { user } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.user.isFetching);
  const isEdit = !!user?._id;
  const formik = useFormik(initFormik(props));

  return (
    <Form
      className='userForm'
      layout='vertical'
    >
      <div className='userIcon'>
        <UserOutlined />
      </div>
      <div className='userFormWrapper'>
        <div className='leftForm'>
          <FormItem
            label='Imię'
            required={true}
            error={formik.touched.name && formik.errors.name}
          >
            <Input
              name='name'
              value={formik.values.name}
              placeholder='Imię'
              onChange={formik.handleChange}
            />
          </FormItem>
          <FormItem
            label='Jezyk'
            required={true}
            error={formik.touched.language && formik.errors.language}
          >
            <Select
              showSearch
              value={formik.values.language}
              style={{ width: '100%' }}
              onChange={value => formik.setFieldValue('language', value)}
              filterOption={(input: string, option: any) =>
                (option?.value ?? '').toLowerCase().includes(input.toLowerCase()) ||
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={Object.values(countriesMap).map(language => ({
                label: `${language.flag} ${language.name}`,
                value: language.shortName,
              }))}
            />
          </FormItem>
          <FormItem
            label='E-mail'
            required={true}
            error={formik.touched.email && formik.errors.email}
          >
            <Input
              name='email'
              value={formik.values.email}
              placeholder='E-mail'
              onChange={formik.handleChange}
              disabled={isEdit}
            />
          </FormItem>
        </div>
        <div className='rightForm'>
          <FormItem
            label='Nazwisko'
            required={true}
            error={formik.touched.surname && formik.errors.surname}
          >
            <Input
              name='surname'
              value={formik.values.surname}
              placeholder='Nazwisko'
              onChange={formik.handleChange}
            />
          </FormItem>
          <FormItem
            label='Rola użytkownika'
            required={true}
            error={formik.touched.group && formik.errors.group}
          >
            <Select
              showSearch
              value={formik.values.group}
              style={{ width: '100%' }}
              onChange={value => formik.setFieldValue('group', value)}
              options={Object.values([UserGroup.MANAGER, UserGroup.WAITER, UserGroup.COOK]).map(group => ({
                label: UserGroupNames[group],
                value: group,
              }))}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </FormItem>
          <FormItem
            label='Status aktywności'
            error={formik.touched.active && formik.errors.active}
          >
            <Switch
              defaultChecked
              checked={formik.values.active}
              onChange={checked => formik.setFieldValue('active', checked)}
            />
          </FormItem>
        </div>
      </div>
      <div className='buttonWrapper'>
        <Button onClick={props.onCancel}>Anuluj</Button>
        <Button
          type='primary'
          htmlType='submit'
          loading={isFetching}
          onClick={formik.submitForm}
          icon={!user && <PlusOutlined />}
        >
          {user ? 'Zapis' : 'Dodaj'} użytkownika
        </Button>
      </div>
    </Form>
  );
};
