import { Tooltip } from 'antd';
import { DishKind, DishKindNames, DishTaste, DishTasteNames } from '../../reducers/dish/model';

export const getDishKindIcon = (kind: string) => {
  switch (kind) {
    case DishKind.VEGAN:
      return (
        <Tooltip title={DishKindNames[DishKind.VEGAN]}>
          <img
            alt={DishKindNames[DishKind.VEGAN]}
            src='../images/carrot.svg'
          />
        </Tooltip>
      );

    case DishKind.MEAT:
      return (
        <Tooltip title={DishKindNames[DishKind.MEAT]}>
          <img
            alt={DishKindNames[DishKind.MEAT]}
            src='../images/meat.svg'
          />
        </Tooltip>
      );

    case DishKind.VEGETARIAN:
      return (
        <Tooltip title={DishKindNames[DishKind.VEGETARIAN]}>
          <img
            alt={DishKindNames[DishKind.VEGETARIAN]}
            src='../images/fish.svg'
          />
        </Tooltip>
      );

    default:
      return null;
  }
};

export const getDishTasteIcon = (taste: string) => {
  switch (taste) {
    case DishTaste.MILD:
      return (
        <Tooltip title={DishTasteNames[DishTaste.MILD]}>
          <img
            alt={DishTasteNames[DishTaste.MILD]}
            src='../images/leaf.svg'
          />
        </Tooltip>
      );

    case DishTaste.SPICY_LVL_1:
      return (
        <Tooltip title={DishTasteNames[DishTaste.SPICY_LVL_1]}>
          <img
            alt='papryczka'
            src='../images/pepper_red.svg'
          />
          <img
            alt='papryczka'
            src='../images/pepper.svg'
          />
          <img
            alt='papryczka'
            src='../images/pepper.svg'
          />
        </Tooltip>
      );

    case DishTaste.SPICY_LVL_2:
      return (
        <Tooltip title={DishTasteNames[DishTaste.SPICY_LVL_2]}>
          <img
            alt={DishTasteNames[DishTaste.SPICY_LVL_2]}
            src='../images/pepper_red.svg'
          />
          <img
            alt={DishTasteNames[DishTaste.SPICY_LVL_2]}
            src='../images/pepper_red.svg'
          />
          <img
            alt={DishTasteNames[DishTaste.SPICY_LVL_2]}
            src='../images/pepper.svg'
          />
        </Tooltip>
      );

    case DishTaste.SPICY_LVL_3:
      return (
        <Tooltip title={DishTasteNames[DishTaste.SPICY_LVL_3]}>
          <img
            alt={DishTasteNames[DishTaste.SPICY_LVL_2]}
            src='../images/pepper_red.svg'
          />
          <img
            alt={DishTasteNames[DishTaste.SPICY_LVL_2]}
            src='../images/pepper_red.svg'
          />
          <img
            alt={DishTasteNames[DishTaste.SPICY_LVL_2]}
            src='../images/pepper_red.svg'
          />
        </Tooltip>
      );

    default:
      return null;
  }
};
