import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import { CardPage } from '../cardPage/CardPage';
import { RootReducerState } from '../../reducers';
import { FileTextOutlined } from '@ant-design/icons';
import { setAppSelectedCategory } from '../../reducers/app/actions';
import { Category } from '../../reducers/category/model';
import {
  addCategoryRequest,
  deleteCategoryRequest,
  getCategoryListRequest,
  updateCategoryRequest,
} from '../../reducers/category/actions';
import { CategoryFormValues } from '../../forms/category';
import { CategoryForm } from '../../forms/category';
import { ModalHeader } from '../modalHeader/ModalHeader';

export const CategoryList: React.FC = () => {
  const categoryList = useSelector<RootReducerState, Category[]>(state => state.category.categoryList);
  const isFetchingCategories = useSelector<RootReducerState, boolean>(state => state.category.isFetching);
  const selectedMenuId = useSelector<RootReducerState, string | null>(state => state.app.selectedMenuId);
  const selectedCategoryId = useSelector<RootReducerState, string | null>(state => state.app.selectedCategoryId);
  const ownerId = useSelector<RootReducerState, string | undefined>(state => state.user.userLogin?._id);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedMenuId) {
      dispatch(getCategoryListRequest(selectedMenuId));
    }
  }, [dispatch, selectedMenuId]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedCategory(undefined);
  };

  const openEditItemModal = (restaurantId: string) => {
    const category = categoryList.find(item => item._id === restaurantId);
    setSelectedCategory(category);
    setIsModalVisible(true);
  };

  const handleSubmit = (values: CategoryFormValues) => {
    if (selectedCategory) {
      const category = {
        _id: selectedCategory._id,
        name: values.name,
        menuId: selectedMenuId ?? '',
        ownerId: selectedCategory.ownerId,
        createdAt: selectedCategory.createdAt,
        updatedAt: selectedCategory.updatedAt,
        assets: values.assets,
      };
      dispatch(
        updateCategoryRequest(
          {
            ...category,
          },
          () => {
            setSelectedCategory(undefined);
            setIsModalVisible(false);
          },
        ),
      );
    } else if (selectedMenuId) {
      const category = {
        name: values.name,
        menuId: selectedMenuId,
        ownerId: ownerId ?? '',
        assets: values.assets,
      };
      dispatch(
        addCategoryRequest(category, () => {
          setSelectedCategory(undefined);
          setIsModalVisible(false);
        }),
      );
    }
  };

  const handleDeleteItem = (id: string) => {
    dispatch(deleteCategoryRequest(id));
  };

  return selectedMenuId ? (
    <>
      <CardPage
        list={categoryList}
        isFetching={isFetchingCategories}
        title='Kategorie'
        selectedId={selectedCategoryId}
        openEditItemModal={openEditItemModal}
        handleDeleteItem={handleDeleteItem}
        openModal={() => setIsModalVisible(true)}
        label={categoryList.length > 0 ? 'kategorię' : 'pierwszą kategorię'}
        setSelectedId={setAppSelectedCategory}
      >
        <FileTextOutlined />
      </CardPage>
      <Modal
        width='60%'
        title={
          <ModalHeader
            title='kategorię'
            selectedElement={!!selectedCategory}
            headerIcon={<FileTextOutlined style={{ fontSize: '30px' }} />}
          />
        }
        open={isModalVisible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={null}
      >
        <CategoryForm
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          category={selectedCategory}
        />
      </Modal>
    </>
  ) : null;
};
