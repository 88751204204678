import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { RootReducerState } from '../../reducers';
import { User } from '../../reducers/user/model';
import { Header } from '../../component/header/Header';
import { RestaurantList } from '../../component/restaurant/RestaurantList';
import { MenuList } from '../../component/menu/MenuList';
import { CategoryList } from '../../component/category/CategoryList';
import { DishList } from '../../component/dish/DishList';
import { DishDetails } from '../../component/dishDetails';
import { Onboarding } from '../../component/onboarding/Onboarding';

import './style.scss';

export const HomePage: FC = () => {
  const user = useSelector<RootReducerState, User | null>(state => state.user.userLogin);

  if (!user) {
    return (
      <Spin
        spinning={true}
        tip={'Getting user'}
        fullscreen={true}
      />
    );
  }

  return (
    <div className='appContainer'>
      <Header />
      {user.onBoardingFinished && (
        <>
          <div className='listContainer'>
            <RestaurantList />
            <MenuList />
            <CategoryList />
            <DishList />
          </div>
          <DishDetails />
        </>
      )}
      <Onboarding />
    </div>
  );
};
