import { BaseAPI } from '../base';
import { Menu } from '../../../reducers/menu/model';
import { ResponseModel } from '../../../reducers/model';

class APIMenus extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/menus`;
  }

  public getRestaurantMenus = async (restaurantId: string): Promise<ResponseModel<Menu[]>> => {
    return this.authFetch<ResponseModel<Menu[]>>(`${this.apiURL}/restaurants/${restaurantId}`, { method: 'GET' });
  };

  public addRestaurantMenu = async (data: Omit<Menu, '_id' | 'qrCode'>): Promise<ResponseModel<Menu>> => {
    return this.authFetch<ResponseModel<Menu>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateRestaurantMenu = async (data: Menu): Promise<ResponseModel<Menu>> => {
    return this.authFetch<ResponseModel<Menu>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteRestaurantMenu = async (menuId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${menuId}`, { method: 'DELETE' });
  };

  public getMenu = async (menuId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${menuId}`, { method: 'GET' });
  };
}

export const apiMenus = new APIMenus();
