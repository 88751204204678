import { BaseAPI } from '../base';
import { Category } from '../../../reducers/category/model';
import { ResponseModel } from '../../../reducers/model';

class APICategory extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/categories`;
  }

  public getCategoriesMenu = async (menuId: string): Promise<ResponseModel<Category[]>> => {
    return this.authFetch<ResponseModel<Category[]>>(`${this.apiURL}/menus/${menuId}`, { method: 'GET' });
  };

  public addCategory = async (
    data: Omit<Category, '_id' | 'createdAt' | 'updatedAt'>,
  ): Promise<ResponseModel<Category>> => {
    return this.authFetch<ResponseModel<Category>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateCategory = async (data: Category): Promise<ResponseModel<Category>> => {
    return this.authFetch<ResponseModel<Category>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteCategory = async (categoryId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${categoryId}`, { method: 'DELETE' });
  };

  public getCategory = async (categoryId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${categoryId}`, { method: 'GET' });
  };
}

export const apiCategory = new APICategory();
