import { AssetModel } from '../assets/model';
import { MultiLangValue, MultiLangValueArray } from '../../types/internal/language';

export type Dish = {
  _id: string;
  type: DishType;
  name: MultiLangValue;
  description: MultiLangValue;
  price: number;
  ingredients?: MultiLangValueArray;
  allergens?: MultiLangValueArray;
  weight?: number;
  calories?: number;
  alcohol?: number;
  capacity?: number;
  taste?: DishTaste;
  kind?: DishKind;
  menuId: string;
  categoryId: string;
  ownerId: string;
  assets: AssetModel[];
  createdAt: string;
  updatedAt: string;
};

export enum DishType {
  MEAL = 'meal',
  DRINK = 'drink',
}

export enum DishTaste {
  MILD = 'mild',
  SPICY_LVL_1 = 'spicy_lvl_1',
  SPICY_LVL_2 = 'spicy_lvl_2',
  SPICY_LVL_3 = 'spicy_lvl_3',
}

export const DishTasteNames = {
  [DishTaste.MILD]: 'łagodne',
  [DishTaste.SPICY_LVL_1]: 'lekko pikantne',
  [DishTaste.SPICY_LVL_2]: 'średnio pikantne',
  [DishTaste.SPICY_LVL_3]: 'bardzo pikantne',
};

export enum DishKind {
  MEAT = 'meat',
  VEGETARIAN = 'vegetarian',
  VEGAN = 'vegan',
}

export const DishKindNames = {
  [DishKind.MEAT]: 'mięsne',
  [DishKind.VEGETARIAN]: 'wegetariańskie',
  [DishKind.VEGAN]: 'wegańskie',
};
