import jwt_decode from 'jwt-decode';

import { User, UserGroup } from '../../reducers/user/model';

type UserInfo = {
  id: string;
  email: string;
  name: string;
  surname: string;
  group: UserGroup;
  active: true;
  language: string;
  ownerId: string;
  createdAt: string;
  updatedAt: string;
  exp: number;
  iat: number;
  onBoardingFinished: boolean;
  organisationId: string;
  salt: string;
  phone: string;
};

class AuthService {
  private token?: string;

  public setToken = (token: string): void => {
    this.token = token;
  };

  public getToken = () => {
    return this.token;
  };

  public logout = (): void => {
    this.token = undefined;
  };

  public getUser = (): User | null => {
    const token = this.getToken();

    if (token) {
      const decodedToken: UserInfo = jwt_decode(token);

      return {
        _id: decodedToken.id,
        email: decodedToken.email,
        name: decodedToken.name,
        surname: decodedToken.surname,
        group: decodedToken.group,
        active: decodedToken.active,
        language: decodedToken.language,
        ownerId: decodedToken.ownerId,
        createdAt: decodedToken.createdAt,
        updatedAt: decodedToken.updatedAt,
        onBoardingFinished: decodedToken.onBoardingFinished,
        organisationId: decodedToken.organisationId,
        salt: decodedToken.salt,
        phone: decodedToken.phone,
      };
    } else {
      return null;
    }
  };

  public validateToken = (): boolean => {
    const token = this.getToken();
    if (token) {
      const user: UserInfo = jwt_decode(token);

      return user.exp > new Date().getTime() / 1000;
    }

    return false;
  };

  public isAdmin = (): boolean => {
    if (this.validateToken()) {
      const user = this.getUser();

      return user?.group === UserGroup.ADMIN;
    }

    return false;
  };

  public isLoggedIn = (): boolean => {
    return this.validateToken();
  };
}

export const authService = new AuthService();
