import * as yup from 'yup';
import { AnySchema } from 'yup';

export const createMultiLangSchema = (languages: string[], message: string) => {
  return yup.object().shape(
    languages.reduce<Record<string, AnySchema>>((shape, lang) => {
      shape[lang] = yup.string().required(message.replace('#lang', lang));
      return shape;
    }, {}),
  );
};
