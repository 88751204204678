import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { Menu } from './model';

export const getRestaurantMenusRequest = (restaurantId: string) =>
  action(CONST.GET_RESTAURANT_MENUS_REQUEST, { restaurantId });
export const getRestaurantMenusSuccess = (restaurantMenuList: Menu[]) =>
  action(CONST.GET_RESTAURANT_MENUS_SUCCESS, { restaurantMenuList });
export const getRestaurantMenusError = (error: Error) => action(CONST.GET_RESTAURANT_MENUS_ERROR, { error });

export const addRestaurantMenuRequest = (menu: Omit<Menu, '_id' | 'qrCode'>, onSuccess?: () => void) =>
  action(CONST.ADD_RESTAURANT_MENU_REQUEST, { menu, onSuccess });
export const addRestaurantMenuSuccess = (menu: Menu) => action(CONST.ADD_RESTAURANT_MENU_SUCCESS, { menu });
export const addRestaurantMenuError = (error: Error) => action(CONST.ADD_RESTAURANT_MENU_ERROR, { error });

export const updateRestaurantMenuRequest = (menu: Menu, onSuccess?: () => void) =>
  action(CONST.UPDATE_RESTAURANT_MENU_REQUEST, { menu, onSuccess });
export const updateRestaurantMenuSuccess = (menu: Menu) => action(CONST.UPDATE_RESTAURANT_MENU_SUCCESS, { menu });
export const updateRestaurantMenuError = (error: Error) => action(CONST.UPDATE_RESTAURANT_MENU_ERROR, { error });

export const deleteRestaurantMenuRequest = (menuId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_RESTAURANT_MENU_REQUEST, { menuId, onSuccess });
export const deleteRestaurantMenuSuccess = (menuId: string) => action(CONST.DELETE_RESTAURANT_MENU_SUCCESS, { menuId });
export const deleteRestaurantMenuError = (error: Error) => action(CONST.DELETE_RESTAURANT_MENU_ERROR, { error });

export const getRestaurantMenuRequest = (menuId: string) => action(CONST.GET_RESTAURANT_MENU_REQUEST, { menuId });
export const getRestaurantMenuSuccess = (menu: Menu) => action(CONST.GET_RESTAURANT_MENU_SUCCESS, { menu });
export const getRestaurantMenuError = (error: any) => action(CONST.GET_RESTAURANT_MENU_ERROR, { error });
