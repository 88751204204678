export const GET_CATEGORY_LIST_REQUEST = '@category/GET_CATEGORY_LIST_REQUEST';
export const GET_CATEGORY_LIST_SUCCESS = '@category/GET_CATEGORY_LIST_SUCCESS';
export const GET_CATEGORY_LIST_ERROR = '@category/GET_CATEGORY_LIST_ERROR';

export const ADD_CATEGORY_REQUEST = '@category/ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = '@category/ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR = '@category/ADD_CATEGORY_ERROR';

export const UPDATE_CATEGORY_REQUEST = '@category/UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = '@category/UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = '@category/UPDATE_CATEGORY_ERROR';

export const DELETE_CATEGORY_REQUEST = '@category/DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = '@category/DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = '@category/DELETE_CATEGORY_ERROR';

export const GET_CATEGORY_REQUEST = '@category/GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = '@category/GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_ERROR = '@category/GET_CATEGORY_ERROR';
