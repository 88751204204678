export enum Packages {
  BASIC = 'basic',
  STANDARD = 'standard',
  PREMIUM = 'premium',
}

export const subscriptionPackages = [
  {
    text: 'darmowa wersja dostępna przez 14 dni',
    active: {
      [Packages.BASIC]: true,
      [Packages.STANDARD]: true,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'dostęp do Twoich menu poprzez link (social media, strona www, google maps)',
    active: {
      [Packages.BASIC]: true,
      [Packages.STANDARD]: true,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'nieograniczona liczba produktów i kategorii',
    active: {
      [Packages.BASIC]: true,
      [Packages.STANDARD]: true,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'nieograniczona liczba skanów Twoich kodów',
    active: {
      [Packages.BASIC]: true,
      [Packages.STANDARD]: true,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'dodanie managera zarządzającego Twoim menu',
    active: {
      [Packages.BASIC]: true,
      [Packages.STANDARD]: true,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'zarządzanie 1 restauracją',
    active: {
      [Packages.BASIC]: true,
      [Packages.STANDARD]: true,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'możliwość stworzenia 3 menu',
    active: {
      [Packages.BASIC]: true,
      [Packages.STANDARD]: true,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'wielojęzykowość',
    active: {
      [Packages.BASIC]: true,
      [Packages.STANDARD]: true,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'podstawowe raporty (statystyki odwiedzin Twoje restauracji)',
    active: {
      [Packages.BASIC]: true,
      [Packages.STANDARD]: true,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'zarządzanie 3 restauracjami',
    active: {
      [Packages.BASIC]: false,
      [Packages.STANDARD]: true,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'nieograniczona liczba menu',
    active: {
      [Packages.BASIC]: false,
      [Packages.STANDARD]: true,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'wezwij kelnera do stolika',
    active: {
      [Packages.BASIC]: false,
      [Packages.STANDARD]: true,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'zaawansowane raporty (statystyki odwiedzin, wybierane dania, estymacja przychodów)',
    active: {
      [Packages.BASIC]: false,
      [Packages.STANDARD]: true,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'nieograniczona liczba restauracji',
    active: {
      [Packages.BASIC]: false,
      [Packages.STANDARD]: false,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'płatności online',
    active: {
      [Packages.BASIC]: false,
      [Packages.STANDARD]: false,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'możliwość zamówienia produktów online',
    active: {
      [Packages.BASIC]: false,
      [Packages.STANDARD]: false,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'ERP do zarządzania Twoimi restauracjami',
    active: {
      [Packages.BASIC]: false,
      [Packages.STANDARD]: false,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'Integracje z pyszne.pl',
    active: {
      [Packages.BASIC]: false,
      [Packages.STANDARD]: false,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'Integracje z Uber Eats',
    active: {
      [Packages.BASIC]: false,
      [Packages.STANDARD]: false,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'Integracje z Bolt',
    active: {
      [Packages.BASIC]: false,
      [Packages.STANDARD]: false,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'Integracje z Wolt',
    active: {
      [Packages.BASIC]: false,
      [Packages.STANDARD]: false,
      [Packages.PREMIUM]: true,
    },
  },
  {
    text: 'Integracje z Glovo',
    active: {
      [Packages.BASIC]: false,
      [Packages.STANDARD]: false,
      [Packages.PREMIUM]: true,
    },
  },
];
