import { FC } from 'react';
import { Tabs } from 'antd';
import './style.scss';

type Props = {
  activeLang?: string;
  languages: string[];
  setActiveLang: (lang: string) => void;
  languagesFlags: Record<string, string>;
};

export const TabsMultiLang: FC<Props> = ({ activeLang, languages, setActiveLang, languagesFlags }) => {
  return (
    <Tabs
      className='multi-lang-tab'
      activeKey={activeLang}
      size={'small'}
      items={languages.map(langItem => ({
        key: langItem,
        label: languagesFlags[langItem],
      }))}
      onChange={setActiveLang}
    />
  );
};
