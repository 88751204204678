export enum TabOptions {
  MAIN = 'mainOption',
  ADDRESS = 'addressOption',
  LANGUAGES = 'languagesOption',
  BRANDING = 'brandingOption',
  SETTINGS = 'settingsOption',
}

export const tabOptions = [
  { label: 'Języki', value: TabOptions.LANGUAGES },
  { label: 'Ogólne', value: TabOptions.MAIN },
  { label: 'Adres', value: TabOptions.ADDRESS },
  { label: 'Branding', value: TabOptions.BRANDING },
  { label: 'Ustawienia', value: TabOptions.SETTINGS },
];
