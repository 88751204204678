import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Button, Form, Input, Row } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { LayoutLogin } from '../../layout/login';
import { Paths } from '../../router/paths';
import { FormItem } from '../../component/formItems/formItem/formItem';
import { RootReducerState } from '../../reducers';
import { initFormik } from './formik';

import './style.scss';

export type LoginFormValues = {
  email: string;
  password: string;
};

export type LoginFormProps = {
  onSubmit: (values: LoginFormValues) => void;
};

export const LoginForm: FC<LoginFormProps> = props => {
  const isFetching = useSelector<RootReducerState, boolean>(state => state.user.isFetching);
  const formik = useFormik(initFormik(props));

  return (
    <LayoutLogin>
      <div className='login-form'>
        <Form layout='vertical'>
          <FormItem
            label='E-mail'
            error={formik.touched.email && formik.errors.email}
            required={true}
          >
            <Input
              name='email'
              value={formik.values.email}
              placeholder='E-mail'
              onChange={formik.handleChange}
              prefix={<UserOutlined />}
            />
          </FormItem>
          <FormItem
            label='Hasło'
            error={formik.touched.password && formik.errors.password}
            required={true}
          >
            <Input.Password
              name='password'
              value={formik.values.password}
              placeholder='Hasło'
              onChange={formik.handleChange}
              prefix={<LockOutlined />}
            />
          </FormItem>
          <Row>
            <Button
              type='primary'
              size='large'
              htmlType='submit'
              loading={isFetching}
              onClick={formik.submitForm}
            >
              Zaloguj
            </Button>
          </Row>
          <Row justify='space-between'>
            <Link
              className='link'
              to={Paths.RESET_PASSWORD}
            >
              Nie pamiętasz hasła?
            </Link>
            <Link
              className='link'
              to={Paths.REGISTRATION_USER}
            >
              Zarejestruj się
            </Link>
          </Row>
        </Form>
      </div>
    </LayoutLogin>
  );
};
