import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { RegistrationFormProps, RegistrationFormValues } from '.';

export const initFormik = (props: RegistrationFormProps): FormikConfig<RegistrationFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    email: '',
    password: '',
    passwordConfirmation: '',
  },
  validationSchema: () =>
    yup.object({
      email: yup.string().email('E-mail nie jest poprawny').required('E-mail jest wymagany'),
      password: yup.string().required('Hasło jest wymagane'),
      passwordConfirmation: yup
        .string()
        .required('Potwierdzenie hasła jest wymagane')
        .oneOf([yup.ref('password')], 'Hasła muszą być takie same'),
    }),
  onSubmit: values => {
    props.onSubmit(values);
  },
});
