import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';

import { LayoutMain } from '../../layout/main';
import { Paths } from '../../router/paths';

export const SubscriptionCancelPage: React.FC = () => (
  <LayoutMain>
    <Result
      status='error'
      title='Płatność się nie powiodła'
      subTitle='Wybierz jedną z poniższych możliwości lub skontaktuj się z nami.'
      extra={[
        <Link to={Paths.HOME_PAGE}>
          <Button key='console'>Przejdź do strony głównej</Button>
        </Link>,
        <Link to={Paths.SETTINGS_SUBSCRIPTIONS}>
          <Button
            type='primary'
            key='buy'
          >
            Zapłać ponownie
          </Button>
        </Link>,
      ]}
    />
  </LayoutMain>
);
