import React from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Packages, subscriptionPackages } from './package';

import './style.scss';

type PackageProps = {
  packageName: Packages;
  onCancel: () => void;
};

export const Package: React.FC<PackageProps> = props => {
  const { packageName, onCancel } = props;

  return (
    <div className='packageList'>
      <ul>
        {subscriptionPackages.map((item, idx) => (
          <li key={idx}>
            {item.active[packageName] ? (
              <CheckCircleOutlined className='green' />
            ) : (
              <CloseCircleOutlined className='grey' />
            )}
            {item.text}
          </li>
        ))}
      </ul>
      <Button
        className='cancelButton'
        onClick={onCancel}
      >
        <ArrowLeftOutlined /> Wróć do wszystkich pakietów
      </Button>
    </div>
  );
};
