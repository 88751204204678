import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Col, Row, Spin, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { LayoutLogin } from '../../layout/login';
import { useDispatch, useSelector } from 'react-redux';
import { activateUserRequest } from '../../reducers/user/actions';
import { Paths } from '../../router/paths';
import { RootReducerState } from '../../reducers';
import { User } from '../../reducers/user/model';

import './style.scss';

const { Text } = Typography;

enum UserStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const ActivatePage: React.FC = () => {
  const { search } = useLocation();
  const userLastActivated = useSelector<RootReducerState, User | null>(state => state.user.userLastActivated);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.user.isFetching);
  const [status, setStatus] = useState<UserStatus>(UserStatus.PENDING);
  const dispatch = useDispatch();
  const history = useHistory();

  const searchParams = new URLSearchParams(search);
  const hash = searchParams.get('hash');

  useEffect(() => {
    if (hash) {
      dispatch(
        activateUserRequest(
          hash,
          () => {
            setStatus(UserStatus.SUCCESS);
          },
          () => {
            setStatus(UserStatus.ERROR);
          },
        ),
      );
    }
  }, [dispatch, hash]);

  if (!hash) {
    history.push(Paths.PAGE_404);
  }

  return (
    <LayoutLogin>
      <div className='activatePage'>
        <Row className='activatePageBg'>
          <Col>
            <Row align='middle'>
              <Spin spinning={isFetching} />
              {!isFetching && status === UserStatus.SUCCESS && (
                <>
                  <CheckCircleOutlined className='success-icon' />
                  <Text>
                    Konto <b>{userLastActivated?.email}</b> zostało aktywowane przejdź do strony logowania.
                  </Text>
                </>
              )}
              {!isFetching && status === UserStatus.ERROR && (
                <>
                  <CloseCircleOutlined className='error-icon' />
                  <Text>Konto nie zostało aktywowane przejdź do strony logowania.</Text>
                </>
              )}
            </Row>
            {status !== UserStatus.PENDING && (
              <div>
                <Link
                  className='link'
                  to={Paths.LOGIN_PAGE}
                >
                  Przejdź do strony logowania
                </Link>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </LayoutLogin>
  );
};
