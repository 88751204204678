export const GET_DISH_LIST_REQUEST = '@dish/GET_DISH_LIST_REQUEST';
export const GET_DISH_LIST_SUCCESS = '@dish/GET_DISH_LIST_SUCCESS';
export const GET_DISH_LIST_ERROR = '@dish/GET_DISH_LIST_ERROR';

export const ADD_DISH_REQUEST = '@dish/ADD_DISH_REQUEST';
export const ADD_DISH_SUCCESS = '@dish/ADD_DISH_SUCCESS';
export const ADD_DISH_ERROR = '@dish/ADD_DISH_ERROR';

export const UPDATE_DISH_REQUEST = '@dish/UPDATE_DISH_REQUEST';
export const UPDATE_DISH_SUCCESS = '@dish/UPDATE_DISH_SUCCESS';
export const UPDATE_DISH_ERROR = '@dish/UPDATE_DISH_ERROR';

export const DELETE_DISH_REQUEST = '@dish/DELETE_DISH_REQUEST';
export const DELETE_DISH_SUCCESS = '@dish/DELETE_DISH_SUCCESS';
export const DELETE_DISH_ERROR = '@dish/DELETE_DISH_ERROR';

export const GET_DISH_REQUEST = '@dish/GET_DISH_REQUEST';
export const GET_DISH_SUCCESS = '@dish/GET_DISH_SUCCESS';
export const GET_DISH_ERROR = '@dish/GET_DISH_ERROR';
