import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import * as CONST from './consts';
import { ResponseModel } from '../model';
import { notification } from 'antd';
import { getErrorMessage } from '../../utils/error';
import { OrganisationModel } from './model';
import { apiClientOrganisation } from '../../services/apiClient/organisations';
import { getOrganisationError, getOrganisationSuccess } from './actions';

function* getOrganisation(): Generator<CallEffect | PutEffect, void, ResponseModel<OrganisationModel>> {
  try {
    const response = yield call(apiClientOrganisation.getOrganisation);

    yield put(getOrganisationSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get organisation',
      description: getErrorMessage(err),
    });
    yield put(getOrganisationError(err));
  }
}

export function* watchOrganisationSaga(): Generator {
  yield takeLatest(CONST.GET_ORGANISATION_REQUEST, getOrganisation);
}
