import { FC, useEffect } from 'react';
import { Modal } from 'antd';
import { OnboardingForm, OnboardingFormValues } from '../../forms/onBoarding';
import { Subscriptions } from '../subscriptions';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { User } from '../../reducers/user/model';
import { onboardUserRequest } from '../../reducers/user/actions';
import { OrganisationModel } from '../../reducers/organisation/model';
import { getOrganisationRequest } from '../../reducers/organisation/actions';

export const Onboarding: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector<RootReducerState, User | null>(state => state.user.userLogin);
  const organisation = useSelector<RootReducerState, OrganisationModel | null>(
    state => state.organisation.organisation,
  );

  useEffect(() => {
    dispatch(getOrganisationRequest());
  }, [dispatch]);

  const handleSubmit = (values: OnboardingFormValues) => {
    if (user) {
      dispatch(
        onboardUserRequest({
          user: {
            name: values.name,
            surname: values.surname,
            phone: values.phone,
          },
          organisation: {
            isCompany: values.isCompany,
            companyName: values.companyName,
            taxNumber: values.taxNumber,
            street: values.street,
            postalCode: values.postalCode,
            city: values.city,
            country: values.country,
          },
        }),
      );
    }
  };

  return (
    <Modal
      title='Dokończ aktywację swojego konta'
      open={!user?.onBoardingFinished || !organisation?.subscriptionActive}
      width='80%'
      footer={null}
      closeIcon={false}
    >
      {user && !user.onBoardingFinished && <OnboardingForm onSubmit={handleSubmit} />}
      {organisation && user?.onBoardingFinished && !organisation.subscriptionActive && <Subscriptions />}
    </Modal>
  );
};
