import { call, put, takeLatest, CallEffect, PutEffect, select } from 'redux-saga/effects';
import { notification } from 'antd';
import {
  getRestaurantMenusRequest,
  getRestaurantMenusError,
  getRestaurantMenusSuccess,
  addRestaurantMenuRequest,
  addRestaurantMenuSuccess,
  addRestaurantMenuError,
  updateRestaurantMenuRequest,
  updateRestaurantMenuSuccess,
  updateRestaurantMenuError,
  deleteRestaurantMenuRequest,
  deleteRestaurantMenuSuccess,
  deleteRestaurantMenuError,
  getRestaurantMenuRequest,
  getRestaurantMenuSuccess,
  getRestaurantMenuError,
} from './actions';
import * as CONST from './consts';
import { Menu } from './model';
import { ResponseModel } from '../model';
import { apiMenus } from '../../services/apiClient/menus';
import { ActionType } from 'typesafe-actions';
import { getErrorMessage } from '../../utils/error';
import { DELETE_RESTAURANT_SUCCESS } from '../restaurants/consts';
import { deleteRestaurantSuccess } from '../restaurants/actions';
import { RootReducerState } from '../index';

const getMenuList = (state: RootReducerState) => state.menus.restaurantMenuList;

function* getMenus(
  action: ActionType<typeof getRestaurantMenusRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<Menu[]>> {
  try {
    const { restaurantId } = action.payload;
    const response = yield call(apiMenus.getRestaurantMenus, restaurantId);

    yield put(getRestaurantMenusSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get menu list',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(getRestaurantMenusError(err));
  }
}

function* addMenu(
  action: ActionType<typeof addRestaurantMenuRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<Menu>> {
  try {
    const { menu, onSuccess } = action.payload;
    const response = yield call(apiMenus.addRestaurantMenu, menu);

    yield put(addRestaurantMenuSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding menu',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(addRestaurantMenuError(err));
  }
}

function* updateMenu(
  action: ActionType<typeof updateRestaurantMenuRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<Menu>> {
  try {
    const { menu, onSuccess } = action.payload;
    const response = yield call(apiMenus.updateRestaurantMenu, menu);

    yield put(updateRestaurantMenuSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating menu',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(updateRestaurantMenuError(err));
  }
}

function* deleteMenu(
  action: ActionType<typeof deleteRestaurantMenuRequest>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { menuId, onSuccess } = action.payload;
    yield call(apiMenus.deleteRestaurantMenu, menuId);

    yield put(deleteRestaurantMenuSuccess(menuId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting menu',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(deleteRestaurantMenuError(err));
  }
}

function* deleteInnerMenu(
  action: ActionType<typeof deleteRestaurantSuccess>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { restaurantId } = action.payload;
    // @ts-expect-error
    const menus: Menu[] = yield select(getMenuList);
    const menuInRestaurant = menus.filter(menu => menu.restaurantId === restaurantId);
    for (const cat of menuInRestaurant) {
      yield put(deleteRestaurantMenuSuccess(cat._id));
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting menu',
      description: getErrorMessage(err),
    });
  }
}

function* getMenu(
  action: ActionType<typeof getRestaurantMenuRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<Menu>> {
  try {
    const { menuId } = action.payload;
    const response = yield call(apiMenus.getMenu, menuId);

    yield put(getRestaurantMenuSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during getting menu',
      description: getErrorMessage(err),
    });
    yield put(getRestaurantMenuError(err));
  }
}

export function* watchMenuSaga(): Generator {
  yield takeLatest(CONST.GET_RESTAURANT_MENUS_REQUEST, getMenus);
  yield takeLatest(CONST.ADD_RESTAURANT_MENU_REQUEST, addMenu);
  yield takeLatest(CONST.UPDATE_RESTAURANT_MENU_REQUEST, updateMenu);
  yield takeLatest(CONST.DELETE_RESTAURANT_MENU_REQUEST, deleteMenu);
  yield takeLatest(DELETE_RESTAURANT_SUCCESS, deleteInnerMenu);
  yield takeLatest(CONST.GET_RESTAURANT_MENU_REQUEST, getMenu);
}
