export const GET_RESTAURANTS_REQUEST = '@restaurants/GET_RESTAURANTS_REQUEST';
export const GET_RESTAURANTS_SUCCESS = '@restaurants/GET_RESTAURANTS_SUCCESS';
export const GET_RESTAURANTS_ERROR = '@restaurants/GET_RESTAURANTS_ERROR';

export const ADD_RESTAURANT_REQUEST = '@restaurants/ADD_RESTAURANT_REQUEST';
export const ADD_RESTAURANT_SUCCESS = '@restaurants/ADD_RESTAURANT_SUCCESS';
export const ADD_RESTAURANT_ERROR = '@restaurants/ADD_RESTAURANT_ERROR';

export const UPDATE_RESTAURANT_REQUEST = '@restaurants/UPDATE_RESTAURANT_REQUEST';
export const UPDATE_RESTAURANT_SUCCESS = '@restaurants/UPDATE_RESTAURANT_SUCCESS';
export const UPDATE_RESTAURANT_ERROR = '@restaurants/UPDATE_RESTAURANT_ERROR';

export const DELETE_RESTAURANT_REQUEST = '@restaurants/DELETE_RESTAURANT_REQUEST';
export const DELETE_RESTAURANT_SUCCESS = '@restaurants/DELETE_RESTAURANT_SUCCESS';
export const DELETE_RESTAURANT_ERROR = '@restaurants/DELETE_RESTAURANT_ERROR';
