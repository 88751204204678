import { BaseAPI } from '../base';
import { ResponseModel } from '../../../reducers/model';
import { OrganisationModel } from '../../../reducers/organisation/model';

class APIClientOrganisation extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/organisations`;
  }

  public getOrganisation = async (): Promise<ResponseModel<OrganisationModel>> => {
    return this.authFetch<ResponseModel<OrganisationModel>>(`${this.apiURL}/my`, { method: 'GET' });
  };
}

export const apiClientOrganisation = new APIClientOrganisation();
