import { FC } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Button, Form, Switch } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Menu } from '../../reducers/menu/model';
import { InputMultiLang } from '../../component/formItems/inputMultiLang/InputMultiLang';
import { MultiLangValue } from '../../types/internal/language';
import { RootReducerState } from '../../reducers';
import { initFormik } from './formik';
import { useRestaurantLanguages } from '../../utils/customHooks/useRestaurantLanguages';
import { FormItemMultiLang } from '../../component/formItems/formItemMultiLang/FormItemMultiLang';
import { FormItem } from '../../component/formItems/formItem/formItem';

import './style.scss';

export type MenuFormValues = {
  name: MultiLangValue;
  active: boolean;
};

export type MenuFormProps = {
  menu?: Menu;
  onSubmit: (values: MenuFormValues) => void;
  onCancel: () => void;
};

export const MenuForm: FC<MenuFormProps> = props => {
  const { onCancel, menu } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.menus.isFetching);
  const { languages } = useRestaurantLanguages();
  const formik = useFormik(initFormik(props, languages));

  return (
    <Form className='menuForm'>
      <div className='menuFormContent'>
        <FormItemMultiLang
          className='fieldWrapper'
          label='Nazwa menu'
          errors={formik.errors.name}
        >
          <InputMultiLang
            name={'name'}
            placeholder='Nazwa menu'
            value={formik.values.name}
            onChange={value => formik.setFieldValue('name', value)}
            onBlur={formik.handleBlur('name')}
          />
        </FormItemMultiLang>
        <FormItem
          label='Aktywne menu'
          error={formik.touched.active && formik.errors.active}
        >
          <Switch
            defaultChecked
            checked={formik.values.active}
            onChange={checked => formik.setFieldValue('active', checked)}
          />
        </FormItem>
      </div>
      <div className='buttonWrapper'>
        <Button onClick={onCancel}>Anuluj</Button>
        <Button
          type='primary'
          htmlType='submit'
          loading={isFetching}
          icon={!menu && <PlusOutlined />}
          onClick={formik.submitForm}
        >
          {menu ? 'Zapisz' : 'Dodaj'} menu
        </Button>
      </div>
    </Form>
  );
};
