import { BaseAPI } from '../base';
import { AssetModel } from '../../../reducers/assets/model';
import { ResponseModel } from '../../../reducers/model';

class APIAssets extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/assets`;
  }

  public deleteAsset = async (assetId: string): Promise<ResponseModel<AssetModel[]>> => {
    return this.authFetch<ResponseModel<AssetModel[]>>(`${this.apiURL}/${assetId}`, { method: 'DELETE' });
  };
}

export const apiAssets = new APIAssets();
