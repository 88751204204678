import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { SettingsProfileFormValues, SettingsProfileFormProps } from '.';

export const initFormik = (props: SettingsProfileFormProps): FormikConfig<SettingsProfileFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: props.user?.name ?? '',
    surname: props.user?.surname ?? '',
    language: props.user?.language ?? '',
  },
  validationSchema: () =>
    yup.object({
      name: yup.string().required('Imię jest wymagane'),
      surname: yup.string().required('Nazwisko jest wymagane'),
    }),
  onSubmit: values => {
    props.onSubmit(values);
  },
});
