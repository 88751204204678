import { combineReducers } from 'redux';

import { userReducer, UserState } from './user/reducer';
import { restaurantsReducer, RestaurantState } from './restaurants/reducer';
import { appReducer, AppState } from './app/reducer';
import { menusReducer, MenuState } from './menu/reducer';
import { categoryReducer, CategoryState } from './category/reducer';
import { assetsReducer, AssetState } from './assets/reducer';
import { dishReducer, DishState } from './dish/reducer';
import { subscriptionsReducer, SubscriptionState } from './subscriptions/reducer';
import { organisationReducer, OrganisationState } from './organisation/reducer';

export type RootReducerState = {
  user: UserState;
  restaurants: RestaurantState;
  app: AppState;
  menus: MenuState;
  category: CategoryState;
  assets: AssetState;
  dish: DishState;
  subscriptions: SubscriptionState;
  organisation: OrganisationState;
};

export const rootReducer = () =>
  combineReducers<RootReducerState>({
    user: userReducer,
    restaurants: restaurantsReducer,
    app: appReducer,
    menus: menusReducer,
    category: categoryReducer,
    assets: assetsReducer,
    dish: dishReducer,
    subscriptions: subscriptionsReducer,
    organisation: organisationReducer,
  });
