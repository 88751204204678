import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { Menu } from './model';

export type MenuAction = ActionType<typeof actions>;
export type MenuState = {
  readonly isFetching: boolean;
  readonly restaurantMenuList: Menu[];
  readonly restaurantMenu: Menu | null;
};

export const menusReducer = combineReducers<MenuState, MenuAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_RESTAURANT_MENUS_REQUEST:
      case CONST.ADD_RESTAURANT_MENU_REQUEST:
      case CONST.UPDATE_RESTAURANT_MENU_REQUEST:
      case CONST.DELETE_RESTAURANT_MENU_REQUEST:
        return true;

      case CONST.GET_RESTAURANT_MENUS_SUCCESS:
      case CONST.GET_RESTAURANT_MENUS_ERROR:
      case CONST.ADD_RESTAURANT_MENU_SUCCESS:
      case CONST.ADD_RESTAURANT_MENU_ERROR:
      case CONST.UPDATE_RESTAURANT_MENU_SUCCESS:
      case CONST.UPDATE_RESTAURANT_MENU_ERROR:
      case CONST.DELETE_RESTAURANT_MENU_SUCCESS:
      case CONST.DELETE_RESTAURANT_MENU_ERROR:
        return false;

      default:
        return state;
    }
  },
  restaurantMenuList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_RESTAURANT_MENUS_SUCCESS:
        return action.payload.restaurantMenuList;

      case CONST.ADD_RESTAURANT_MENU_SUCCESS:
        return [...state, action.payload.menu];

      case CONST.UPDATE_RESTAURANT_MENU_SUCCESS: {
        const menuIdx = state.findIndex(item => item._id === action.payload.menu._id);
        state.splice(menuIdx, 1, action.payload.menu);

        return [...state];
      }

      case CONST.DELETE_RESTAURANT_MENU_SUCCESS:
        return state.filter(menu => menu._id !== action.payload.menuId);

      default:
        return state;
    }
  },
  restaurantMenu: (state = null, action) => {
    switch (action.type) {
      case CONST.GET_RESTAURANT_MENU_SUCCESS:
        return action.payload.menu;

      default:
        return state;
    }
  },
});
