import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import { CardPage } from '../cardPage/CardPage';
import { RootReducerState } from '../../reducers';
import { Restaurant } from '../../reducers/restaurants/model';
import {
  addRestaurantRequest,
  deleteRestaurantRequest,
  getRestaurantsRequest,
  updateRestaurantRequest,
} from '../../reducers/restaurants/actions';
import { RestaurantForm } from '../../forms/restaurant';
import { RestaurantFormValues } from '../../forms/restaurant';
import { HomeOutlined } from '@ant-design/icons';
import { setAppSelectedRestaurant } from '../../reducers/app/actions';
import { ModalHeader } from '../modalHeader/ModalHeader';

export const RestaurantList: React.FC = () => {
  const restaurantsList = useSelector<RootReducerState, Restaurant[]>(state => state.restaurants.restaurants);
  const isFetchingRestaurants = useSelector<RootReducerState, boolean>(state => state.restaurants.isFetching);
  const selectedRestaurantId = useSelector<RootReducerState, string | null>(state => state.app.selectedRestaurantId);
  const ownerId = useSelector<RootReducerState, string | undefined>(state => state.user.userLogin?._id);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState<Restaurant | undefined>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRestaurantsRequest());
  }, [dispatch]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRestaurant(undefined);
  };

  const openEditItemModal = (restaurantId: string) => {
    const restaurant = restaurantsList.find(item => item._id === restaurantId);
    setSelectedRestaurant(restaurant);
    setIsModalVisible(true);
  };

  const handleSubmit = (values: RestaurantFormValues) => {
    const restaurant = {
      name: values.name,
      about: values.about,
      address: {
        street: values.street,
        city: values.city,
        zip: values.zip,
        province: values.province,
        country: 'Polska',
      },
      ownerId: ownerId ?? '',
      branding: {
        bgColor: values.bgColor,
        textColor: values.textColor,
        buttonBgColor: values.buttonBgColor,
      },
      settings: {
        reviewRestaurant: values.reviewRestaurant,
        reviewDish: values.reviewDish,
        socialMediaLinks: {
          facebook: values.facebook,
          instagram: values.instagram,
          linkedIn: values.linkedIn,
          tiktok: values.tiktok,
          twitter: values.twitter,
        },
      },
      assets: values.assets,
      languages: values.languages,
      defaultLanguage: values.defaultLanguage,
    };

    if (selectedRestaurant) {
      dispatch(
        updateRestaurantRequest(
          {
            ...selectedRestaurant,
            ...restaurant,
          },
          () => {
            setSelectedRestaurant(undefined);
            setIsModalVisible(false);
          },
        ),
      );
    } else {
      dispatch(
        addRestaurantRequest(restaurant, () => {
          setSelectedRestaurant(undefined);
          setIsModalVisible(false);
        }),
      );
    }
  };

  const handleDeleteItem = (id: string) => {
    dispatch(deleteRestaurantRequest(id));
  };

  return (
    <>
      <CardPage
        list={restaurantsList}
        isFetching={isFetchingRestaurants}
        title='Restauracja'
        selectedId={selectedRestaurantId}
        openEditItemModal={openEditItemModal}
        handleDeleteItem={handleDeleteItem}
        openModal={() => setIsModalVisible(true)}
        label={restaurantsList.length > 0 ? 'restaurację' : 'pierwszą restaurację'}
        setSelectedId={setAppSelectedRestaurant}
      >
        <HomeOutlined />
      </CardPage>
      <Modal
        width='80%'
        title={
          <ModalHeader
            title='restaurację'
            selectedElement={!!selectedRestaurant}
            headerIcon={<HomeOutlined style={{ fontSize: '30px' }} />}
          />
        }
        open={isModalVisible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={null}
      >
        <RestaurantForm
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          restaurant={selectedRestaurant}
        />
      </Modal>
    </>
  );
};
