import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { MenuFormProps, MenuFormValues } from '.';
import { createMultiLangSchema } from '../../utils/validation';

export const initFormik = (props: MenuFormProps, languages: string[]): FormikConfig<MenuFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: props.menu?.name ?? {},
    active: props.menu?.active ?? false,
  },
  validationSchema: () =>
    yup.object({
      name: createMultiLangSchema(languages, 'Nazwa w języku #lang jest wymagana').required('Nazwa jest wymagana'),
    }),
  onSubmit: values => {
    props.onSubmit(values);
  },
});
