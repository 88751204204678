import { useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

import './style.scss';

type ColorPickerProps = {
  name: string;
  value?: string;
  onChange: (filedName: string, colorValue: string) => void;
};

export const ColorPicker = (props: ColorPickerProps) => {
  const { name, value, onChange } = props;
  const [sketchPicker, setSketchPicker] = useState(false);

  const handleChangeBackgroundColor = () => {
    setSketchPicker(true);
  };

  const handleSetBackgroundColor = (color: ColorResult) => {
    onChange(name, color.hex);
  };

  const handleClosePopover = () => {
    setSketchPicker(false);
  };

  return (
    <>
      <div className='backgroundColorWrapper'>
        <div
          role='presentation'
          className='backgroundColor'
          style={{ backgroundColor: value }}
          onClick={handleChangeBackgroundColor}
        ></div>
      </div>
      {sketchPicker && (
        <div className='backgroundColorPopover'>
          <div
            role='presentation'
            className='backgroundColorCover'
            onClick={handleClosePopover}
          />
          <SketchPicker
            color={value}
            onChangeComplete={handleSetBackgroundColor}
          />
        </div>
      )}
    </>
  );
};
