import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as userActions from '../user/actions';
import * as actions from './actions';
import * as USER_CONST from '../user/consts';
import * as CONST from './consts';
import { OrganisationModel } from './model';

export type OrganisationAction = ActionType<typeof userActions | typeof actions>;
export type OrganisationState = {
  readonly isFetching: boolean;
  readonly organisation: OrganisationModel | null;
};

export const organisationReducer = combineReducers<OrganisationState, OrganisationAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case USER_CONST.ONBOARD_USER_REQUEST:
      case CONST.GET_ORGANISATION_REQUEST:
        return true;

      case USER_CONST.ONBOARD_USER_SUCCESS:
      case USER_CONST.ONBOARD_USER_ERROR:
      case CONST.GET_ORGANISATION_SUCCESS:
      case CONST.GET_ORGANISATION_ERROR:
        return false;

      default:
        return state;
    }
  },
  organisation: (state = null, action) => {
    switch (action.type) {
      case USER_CONST.ONBOARD_USER_SUCCESS:
        return action.payload.data.organisation;
      case CONST.GET_ORGANISATION_SUCCESS:
        return action.payload.organisation;

      default:
        return state;
    }
  },
});
