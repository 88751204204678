import { OrganisationModel } from '../organisation/model';

export type LoginModel = {
  token: string;
  user: User;
};

export enum UserGroup {
  ADMIN = 'admin',
  OWNER = 'owner',
  MANAGER = 'manager',
  WAITER = 'waiter',
  COOK = 'cook',
  USER = 'user',
}

export const UserGroupNames = {
  [UserGroup.ADMIN]: 'administrator',
  [UserGroup.OWNER]: 'właściciel',
  [UserGroup.MANAGER]: 'menedżer',
  [UserGroup.WAITER]: 'kelner',
  [UserGroup.COOK]: 'kucharz',
  [UserGroup.USER]: 'użytkownik',
};

// TODO change model with backend
export type User = {
  _id: string;
  email: string;
  group: UserGroup;
  active: boolean;
  createdAt: string;
  name: string;
  surname: string;
  phone: string;
  language: string;
  onBoardingFinished: boolean;
  resetPasswordToken?: string;
  organisationId: string;
  ownerId: string;
  salt: string;
  updatedAt: string;
};

export type UserOnboardRequest = {
  user: {
    name: string;
    surname: string;
    phone: string;
  };
  organisation: {
    isCompany: boolean;
    taxNumber: string;
    companyName: string;
    street: string;
    city: string;
    postalCode: string;
    country: string;
  };
};

export type UserOnboardResponse = {
  user: User;
  organisation: OrganisationModel;
};
