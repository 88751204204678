import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { Restaurant } from './model';

export type RestaurantAction = ActionType<typeof actions>;
export type RestaurantState = {
  readonly isFetching: boolean;
  readonly restaurants: Restaurant[];
};

export const restaurantsReducer = combineReducers<RestaurantState, RestaurantAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_RESTAURANTS_REQUEST:
      case CONST.ADD_RESTAURANT_REQUEST:
      case CONST.UPDATE_RESTAURANT_REQUEST:
      case CONST.DELETE_RESTAURANT_REQUEST:
        return true;

      case CONST.GET_RESTAURANTS_SUCCESS:
      case CONST.GET_RESTAURANTS_ERROR:
      case CONST.ADD_RESTAURANT_SUCCESS:
      case CONST.ADD_RESTAURANT_ERROR:
      case CONST.UPDATE_RESTAURANT_SUCCESS:
      case CONST.UPDATE_RESTAURANT_ERROR:
      case CONST.DELETE_RESTAURANT_SUCCESS:
      case CONST.DELETE_RESTAURANT_ERROR:
        return false;

      default:
        return state;
    }
  },
  restaurants: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_RESTAURANTS_SUCCESS:
        return action.payload.restaurants;

      case CONST.ADD_RESTAURANT_SUCCESS:
        return [...state, action.payload.restaurant];

      case CONST.UPDATE_RESTAURANT_SUCCESS: {
        const restaurantIdx = state.findIndex(item => item._id === action.payload.restaurant._id);
        state.splice(restaurantIdx, 1, action.payload.restaurant);

        return [...state];
      }

      case CONST.DELETE_RESTAURANT_SUCCESS:
        return state.filter(restaurant => restaurant._id !== action.payload.restaurantId);

      default:
        return state;
    }
  },
});
