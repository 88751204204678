import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button, Form, Input, Row } from 'antd';
import { Paths } from '../../router/paths';
import { LockOutlined } from '@ant-design/icons';
import { initFormik } from './formik';
import { FormItem } from '../../component/formItems/formItem/formItem';
import { RootReducerState } from '../../reducers';

import './style.scss';

export type SetPasswordFormValues = {
  password: string;
  passwordConfirmation: string;
};

export type SetPasswordFormProps = {
  showMessage: boolean;
  onSubmit: (values: SetPasswordFormValues) => void;
};

export const SetPasswordForm: React.FC<SetPasswordFormProps> = props => {
  const { showMessage } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.user.isFetching);
  const formik = useFormik(initFormik(props));

  return (
    <div className='setPasswordForm'>
      <div className='formWrapper'>
        {!showMessage && (
          <Form layout='vertical'>
            <FormItem
              label='Hasło'
              required={true}
              error={formik.touched.password && formik.errors.password}
            >
              <Input
                name='password'
                value={formik.values.password}
                placeholder='Hasło'
                onChange={formik.handleChange}
                prefix={<LockOutlined />}
              />
            </FormItem>
            <FormItem
              label='Potwierdź hasło'
              required={true}
              error={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
            >
              <Input
                name='passwordConfirmation'
                value={formik.values.passwordConfirmation}
                placeholder='Potwierdź hasło'
                onChange={formik.handleChange}
                prefix={<LockOutlined />}
              />
            </FormItem>
            <Row>
              <Button
                type='primary'
                size='large'
                htmlType='submit'
                disabled={isFetching}
              >
                Zresetuj hasło
              </Button>
            </Row>
          </Form>
        )}
        {showMessage && (
          <div className='informMessage'>
            Zmieniliśmy Twoje hasło przejdź do strony logowania.
            <Link
              className='link'
              to={Paths.LOGIN_PAGE}
            >
              Zaloguj się
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
