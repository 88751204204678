import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Paths } from '../../router/paths';

import './style.scss';

type Props = {
  children?: ReactNode;
};

export const LayoutLogin: React.FC<Props> = props => (
  <div className='layoutLoginWrapper'>
    <div className='layoutLogin'>
      <div className='layoutForm'>
        <Link
          to={Paths.HOME_PAGE}
          className='logo'
        >
          <img
            src='./images/logo.svg'
            alt='logo'
          />
        </Link>
        {props.children}
      </div>
    </div>
  </div>
);
