import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { ProductModel } from './model';

export type SubscriptionAction = ActionType<typeof actions>;
export type SubscriptionState = {
  readonly isFetching: boolean;
  readonly products: ProductModel[];
};

export const subscriptionsReducer = combineReducers<SubscriptionState, SubscriptionAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_SUBSCRIPTIONS_REQUEST:
        return true;

      case CONST.GET_SUBSCRIPTIONS_SUCCESS:
      case CONST.GET_SUBSCRIPTIONS_ERROR:
        return false;

      default:
        return state;
    }
  },
  products: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_SUBSCRIPTIONS_SUCCESS:
        return action.payload.subscriptions;

      default:
        return state;
    }
  },
});
