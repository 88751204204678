import { useEffect, useState } from 'react';
import { authService } from './authService';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { User } from '../../reducers/user/model';
import { getTokenRequest } from '../../reducers/user/actions';

export const useAuth = () => {
  const user = useSelector<RootReducerState, User | null>(state => state.user.userLogin);
  const isLoggedIn = useSelector<RootReducerState, boolean>(state => state.user.loggedIn);
  const dispatch = useDispatch();

  const [isAuthenticated, setIsAuthenticated] = useState(isLoggedIn);
  const [token, setToken] = useState(authService.getToken());
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    if (!token) {
      setIsAuthenticating(true);
      dispatch(
        getTokenRequest(token => {
          if (token) {
            setIsAuthenticated(true);
            setToken(token);
          }
          setIsAuthenticating(false);
        }),
      );
    }
  }, [dispatch, token]);

  useEffect(() => {
    setIsAuthenticated(isLoggedIn);
  }, [isLoggedIn]);

  return { isAuthenticated, user, token, isAuthenticating };
};
