import React, { useEffect, useState } from 'react';
import { CardPage } from '../cardPage/CardPage';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { Menu } from '../../reducers/menu/model';
import { MenuFormValues, MenuForm } from '../../forms/menu';
import {
  addRestaurantMenuRequest,
  deleteRestaurantMenuRequest,
  getRestaurantMenusRequest,
  updateRestaurantMenuRequest,
} from '../../reducers/menu/actions';
import { FileOutlined } from '@ant-design/icons';
import { setAppSelectedMenu } from '../../reducers/app/actions';
import { ModalHeader } from '../modalHeader/ModalHeader';

export const MenuList: React.FC = () => {
  const menusList = useSelector<RootReducerState, Menu[]>(state => state.menus.restaurantMenuList);
  const isFetchingRestaurants = useSelector<RootReducerState, boolean>(state => state.menus.isFetching);
  const selectedRestaurantId = useSelector<RootReducerState, string | null>(state => state.app.selectedRestaurantId);
  const selectedMenuId = useSelector<RootReducerState, string | null>(state => state.app.selectedMenuId);
  const ownerId = useSelector<RootReducerState, string | undefined>(state => state.user.userLogin?._id);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<Menu | undefined>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedRestaurantId) {
      dispatch(getRestaurantMenusRequest(selectedRestaurantId));
    }
  }, [dispatch, selectedRestaurantId]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedMenu(undefined);
  };

  const handleSubmit = (values: MenuFormValues) => {
    if (selectedMenu) {
      const menu = {
        _id: selectedMenu._id,
        name: values.name,
        active: values.active,
        restaurantId: selectedMenu.restaurantId,
        qrCode: selectedMenu.qrCode,
        ownerId: selectedMenu.ownerId,
      };
      dispatch(
        updateRestaurantMenuRequest(
          {
            ...menu,
          },
          () => {
            setSelectedMenu(undefined);
            setIsModalVisible(false);
          },
        ),
      );
    } else if (selectedRestaurantId) {
      const menu = {
        name: values.name,
        active: values.active,
        restaurantId: selectedRestaurantId,
        ownerId: ownerId ?? '',
      };
      dispatch(
        addRestaurantMenuRequest(menu, () => {
          setSelectedMenu(undefined);
          setIsModalVisible(false);
        }),
      );
    }
  };

  const openEditItemModal = (menuId: string) => {
    const menuEdit = menusList.find(item => item._id === menuId);
    setSelectedMenu(menuEdit);
    setIsModalVisible(true);
  };

  const handleDeleteItem = (menuId: string) => {
    dispatch(
      deleteRestaurantMenuRequest(menuId, () => {
        setSelectedMenu(undefined);
        setIsModalVisible(false);
      }),
    );
  };

  return selectedRestaurantId ? (
    <>
      <CardPage
        list={menusList}
        isFetching={isFetchingRestaurants}
        title='Menu'
        selectedId={selectedMenuId}
        openEditItemModal={openEditItemModal}
        handleDeleteItem={handleDeleteItem}
        openModal={() => setIsModalVisible(true)}
        label={menusList.length > 0 ? 'menu' : 'pierwsze menu'}
        setSelectedId={setAppSelectedMenu}
      >
        <FileOutlined />
      </CardPage>
      <Modal
        width='60%'
        title={
          <ModalHeader
            title='menu'
            selectedElement={!!selectedMenu}
            headerIcon={<FileOutlined style={{ fontSize: '30px' }} />}
          />
        }
        open={isModalVisible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={null}
      >
        <MenuForm
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          menu={selectedMenu}
        />
      </Modal>
    </>
  ) : null;
};
