import { FormikConfig } from 'formik';
import * as yup from 'yup';
import { DishFormProps, DishFormValues } from '.';
import { DishKind, DishTaste, DishType } from '../../reducers/dish/model';
import { createMultiLangSchema } from '../../utils/validation';

export const initFormik = (props: DishFormProps, languages: string[]): FormikConfig<DishFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: props.dish?.name ?? {},
    description: props.dish?.description ?? {},
    weight: props.dish?.weight,
    calories: props.dish?.calories,
    kind: props.dish?.kind ?? DishKind.MEAT,
    taste: props.dish?.taste ?? DishTaste.MILD,
    price: props.dish?.price ?? 0,
    assets: props.dish?.assets ?? [],
    type: props.dish?.type ?? DishType.MEAL,
    ingredients: props.dish?.ingredients ?? {},
    allergens: props.dish?.allergens ?? {},
    capacity: props.dish?.capacity,
    alcohol: props.dish?.alcohol,
  },
  validationSchema: () =>
    yup.object({
      name: createMultiLangSchema(languages, `Nazwa w języku #lang jest wymagana`).required('Nazwa jest wymagana'),
      description: createMultiLangSchema(languages, `Opis w języku #lang jest wymagany`).required('Opis jest wymagany'),
      price: yup.number().required('Cena jest wymagana'),
      type: yup.string().required('Typ dania jest wymagany'),
    }),
  onSubmit: values => {
    props.onSubmit(values);
  },
});
