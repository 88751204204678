import React, { useEffect, useState } from 'react';
import { Button, Divider, Modal } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Package } from '../package';
import { Packages } from '../package/package';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { IntervalTypeNames, ProductModel } from '../../reducers/subscriptions/model';
import { createCheckoutSessionRequest, getSubscriptionsRequest } from '../../reducers/subscriptions/actions';

import './style.scss';
import { OrganisationModel } from '../../reducers/organisation/model';

export const Subscriptions: React.FC = () => {
  const products = useSelector<RootReducerState, ProductModel[]>(state => state.subscriptions.products);
  const organisation = useSelector<RootReducerState, OrganisationModel | null>(
    state => state.organisation.organisation,
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [packageName, setPackageName] = useState(Packages.BASIC);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubscriptionsRequest());
  }, [dispatch]);

  const handleShowProductDetails = (name: Packages) => {
    setPackageName(name);
    setIsModalVisible(true);
  };

  const handleSubmit = (priceId: string) => {
    dispatch(createCheckoutSessionRequest(priceId));
  };

  return (
    <section className='page'>
      <div className='pageHeader'>
        <h1>Pakiety</h1>
      </div>
      <div className='pageContent'>
        <ul className='subscriptions'>
          {products.map(product => {
            return (
              <li key={product.id}>
                <div className='subscriptionTileWrapper'>
                  <div className='subscriptionTile'>
                    <div className='subscriptionTileInternal'>
                      <p className='title'>{product.name}</p>
                      <p className='price'>
                        {product.default_price.unit_amount / 1200}{' '}
                        <span>{product.default_price.currency} / miesięcznie</span>
                      </p>
                      <p className='price-description'>przy płatności za rok</p>
                      <p>{product.description}</p>
                      <Button onClick={() => handleShowProductDetails(Packages.BASIC)}>
                        więcej <ArrowRightOutlined />
                      </Button>
                    </div>
                  </div>
                  <p className='text'>
                    Pakiet{' '}
                    {organisation?.subscriptionActive ? (
                      <>
                        aktywny do: <b>{dayjs(organisation.subscriptionEndsAt).format('D.MM.YYYY')}</b>
                      </>
                    ) : (
                      'nieaktywny'
                    )}
                  </p>
                  <Divider />
                </div>
                {product.prices.map(price => (
                  <Button
                    type='primary'
                    onClick={() => handleSubmit(price.id)}
                  >
                    Kup pakiet na {price.recurring.interval_count} {IntervalTypeNames[price.recurring.interval]} za{' '}
                    {price.unit_amount / 100} {price.currency}
                    <ArrowRightOutlined />
                  </Button>
                ))}
                <Divider />
              </li>
            );
          })}
        </ul>
      </div>
      <Modal
        className='modalPackage'
        width={'50%'}
        title={`Pakiet ${packageName.toUpperCase()}`}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose={true}
        footer={null}
      >
        <Package
          packageName={packageName}
          onCancel={() => setIsModalVisible(false)}
        />
      </Modal>
    </section>
  );
};
