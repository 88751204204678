import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Drawer, Modal, Row, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Dish, DishType } from '../../reducers/dish/model';
import { DishForm } from '../../forms/dish';
import { DishFormValues } from '../../forms/dish';
import { updateDishRequest } from '../../reducers/dish/actions';
import { useRestaurantLanguages } from '../../utils/customHooks/useRestaurantLanguages';
import { RootReducerState } from '../../reducers';
import { Category } from '../../reducers/category/model';
import { getCategoryRequest } from '../../reducers/category/actions';
import { getDishKindIcon, getDishTasteIcon } from './utils';
import { getRestaurantMenuRequest } from '../../reducers/menu/actions';
import { Menu } from '../../reducers/menu/model';
import { setAppSelectedDish } from '../../reducers/app/actions';

import './style.scss';

export const DishDetails: React.FC = () => {
  const dish = useSelector<RootReducerState, Dish | null>(state => state.dish.dish);
  const category = useSelector<RootReducerState, Category | null>(state => state.category.category);
  const menuId = useSelector<RootReducerState, string | null>(state => state.app.selectedMenuId);
  const dishId = useSelector<RootReducerState, string | null>(state => state.app.selectedDishId);
  const menu = useSelector<RootReducerState, Menu | null>(state => state.menus.restaurantMenu);
  const { defaultLanguage } = useRestaurantLanguages();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dish) {
      dispatch(getCategoryRequest(dish.categoryId));
    }
    if (menuId) {
      dispatch(getRestaurantMenuRequest(menuId));
    }
  }, [dispatch, dish, menuId]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = (values: DishFormValues) => {
    let updatedDish;
    if (dish) {
      if (values.type === DishType.MEAL) {
        updatedDish = {
          type: values.type,
          name: values.name,
          description: values.description,
          price: values.price,
          ingredients: values.ingredients,
          allergens: values.allergens,
          weight: values.weight,
          calories: values.calories,
          taste: values.taste,
          kind: values.kind,
          menuId: dish.menuId,
          categoryId: dish.categoryId,
          ownerId: dish.ownerId || '',
          assets: values.assets,
        };
      } else {
        updatedDish = {
          type: values.type,
          name: values.name,
          description: values.description,
          price: values.price,
          ingredients: values.ingredients,
          allergens: values.allergens,
          calories: values.calories,
          alcohol: values.alcohol,
          capacity: values.capacity,
          taste: values.taste,
          menuId: dish.menuId,
          categoryId: dish.categoryId,
          ownerId: dish.ownerId || '',
          assets: values.assets,
        };
      }

      dispatch(
        updateDishRequest(
          {
            ...dish,
            ...updatedDish,
          },
          () => {
            setIsModalVisible(false);
          },
        ),
      );
    }
  };

  const closeDetailDishModal = () => {
    dispatch(setAppSelectedDish(null));
  };

  return (
    dish && (
      <Drawer
        open={!!dishId}
        onClose={closeDetailDishModal}
        width='50%'
      >
        <section className='dishDetails'>
          {dish.assets[0] && (
            <div className='image'>
              <img
                className='image'
                alt={dish.name[defaultLanguage]}
                src={`${process.env.REACT_APP_HOST_ASSETS_URL ?? ''}${dish.assets[0].uri}`}
              />
            </div>
          )}
          <div className='dishDetailsCategory'>
            {menu?.name[defaultLanguage]} / {category?.name[defaultLanguage]}
          </div>
          <div className='dishDetailsContent'>
            <p className='dishName'>{dish.name[defaultLanguage]}</p>
            <p>{dish.description[defaultLanguage]}</p>
          </div>
          <div className='detailsInfoWrapper'>
            <Row>
              <Col
                span={8}
                className='detailsWrapperBox'
              >
                <p>Waga:</p>
                <Tag>{dish.weight} g</Tag>
              </Col>
              <Col
                span={8}
                className='detailsWrapperBox'
              >
                <p>Ilość kalorii:</p>
                <Tag>{dish.calories} kcal</Tag>
              </Col>
              <Col
                span={8}
                className='detailsWrapperBox'
              >
                <p>Cena:</p>
                <Tag>{dish.price} zł</Tag>
              </Col>
            </Row>
          </div>
          <div className='dishTypeWrapper'>
            <Row>
              <Col
                span={24}
                xl={12}
                className='detailsWrapperBox'
              >
                {dish.kind && (
                  <Tag className='tagWrapper'>
                    <span>
                      Typ dania: <span className='tagWrapperImg'>{getDishKindIcon(dish.kind)}</span>
                    </span>
                  </Tag>
                )}
              </Col>
              <Col
                span={24}
                xl={12}
                className='detailsWrapperBox'
              >
                {dish.taste && (
                  <Tag className='tagWrapper'>
                    <span>
                      Ostrość: <span className='tagWrapperImg'>{getDishTasteIcon(dish.taste)}</span>
                    </span>
                  </Tag>
                )}
              </Col>
            </Row>
          </div>
          {dish.ingredients && (
            <div className='ingredientsWrapper'>
              <div className='name'>
                <p>Składniki</p>
              </div>
              {dish.ingredients[defaultLanguage].length > 0 && (
                <div className='ingredients'>
                  {dish.ingredients[defaultLanguage].map((ingredient, id) => (
                    <Tag key={id}>{ingredient}</Tag>
                  ))}
                </div>
              )}
            </div>
          )}
          {dish.allergens && (
            <div className='allergensWrapper'>
              <div className='name'>
                <p>Alergeny</p>
              </div>
              {dish.allergens[defaultLanguage].length > 0 && (
                <div className='allergens'>
                  {dish.allergens[defaultLanguage].map((allergen, id) => (
                    <Tag key={id}>{allergen}</Tag>
                  ))}
                </div>
              )}
            </div>
          )}
          <Row justify='center'>
            <Button onClick={() => setIsModalVisible(true)}>
              <EditOutlined />
              <span>Edytuj</span>
            </Button>
          </Row>
          <Modal
            width='80%'
            title='Edytuj danie/napój'
            open={isModalVisible}
            onCancel={handleCancel}
            destroyOnClose={true}
            footer={null}
          >
            <DishForm
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              dish={dish}
            />
          </Modal>
        </section>
      </Drawer>
    )
  );
};
