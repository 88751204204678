import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Input, InputRef, Tag, TagProps, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useRestaurantLanguages } from '../../../utils/customHooks/useRestaurantLanguages';
import { MultiLangValueArray } from '../../../types/internal/language';
import { TabsMultiLang } from '../tabMultiLang/tabsMultiLang';

type Props = TagProps & {
  tags: MultiLangValueArray;
  addLabel?: string;
  forceLanguages?: string[];
  onChange: (tags: MultiLangValueArray) => void;
};

export const TagDynamicMultiLang: FC<Props> = ({ tags, addLabel, onChange, forceLanguages, ...rest }) => {
  const [activeLang, setActiveLang] = useState<string>('');
  const { defaultLanguage, languages, languagesFlags } = useRestaurantLanguages(forceLanguages);
  const [tagEditInputIndex, setTagEditInputIndex] = useState(-1);
  const [tagInputVisible, setTagInputVisible] = useState(false);
  const [tagInputValue, setTagInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  const tagsActiveLang = (tags[activeLang] as string[] | undefined) ?? [];

  useEffect(() => {
    setActiveLang(defaultLanguage);
  }, [defaultLanguage]);

  useEffect(() => {
    if (tagInputVisible) {
      inputRef.current?.focus();
    }
  }, [inputRef, tagInputVisible]);

  useEffect(() => {
    if (tagEditInputIndex > -1) {
      editInputRef.current?.focus();
    }
  }, [inputRef, tagEditInputIndex]);

  const handleEditInputConfirmTag = () => {
    if (activeLang) {
      const newTags = { ...tags };
      newTags[activeLang][tagEditInputIndex] = tagInputValue;
      onChange(newTags);
    }
    setTagEditInputIndex(-1);
    setTagInputValue('');
  };

  const handleRemoveTag = (removedTag: string) => {
    if (activeLang) {
      const newTags = { ...tags };
      newTags[activeLang] = tagsActiveLang.filter(tag => tag !== removedTag);
      onChange(newTags);
    }
  };

  const handleInputConfirmTag = () => {
    if (tagInputValue && !tagsActiveLang.includes(tagInputValue) && activeLang) {
      const newTags = { ...tags };
      newTags[activeLang] = [...tagsActiveLang, tagInputValue];
      onChange(newTags);
    }
    setTagInputVisible(false);
    setTagInputValue('');
  };

  return (
    <Fragment>
      <TabsMultiLang
        activeLang={activeLang}
        languages={languages}
        setActiveLang={setActiveLang}
        languagesFlags={languagesFlags}
      />
      {tagsActiveLang.map((tag, index) => {
        if (tagEditInputIndex === index) {
          return (
            <Input
              ref={editInputRef}
              key={index}
              size='small'
              className='tag-input'
              value={tagInputValue}
              onChange={evt => setTagInputValue(evt.target.value)}
              onBlur={handleEditInputConfirmTag}
              onPressEnter={handleEditInputConfirmTag}
            />
          );
        }

        const isLongTag = tag.length > 20;

        const tagElem = (
          <Tag
            {...rest}
            className='edit-tag'
            key={tag}
            closable
            onClose={() => handleRemoveTag(tag)}
          >
            <span
              onDoubleClick={e => {
                if (index !== 0) {
                  setTagEditInputIndex(index);
                  setTagInputValue(tag);
                  e.preventDefault();
                }
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip
            title={tag}
            key={tag}
          >
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {tagInputVisible ? (
        <Input
          ref={inputRef}
          size='small'
          className='tag-input'
          value={tagInputValue}
          onChange={evt => setTagInputValue(evt.target.value)}
          onBlur={handleInputConfirmTag}
          onPressEnter={handleInputConfirmTag}
        />
      ) : (
        <Tag
          className='site-tag-plus'
          onClick={() => setTagInputVisible(true)}
        >
          <PlusOutlined /> {addLabel ? addLabel : 'Dodaj'}
        </Tag>
      )}
    </Fragment>
  );
};
