import React, { useState } from 'react';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import './style.scss';

export const DropDownLanguage: React.FC = () => {
  const [defaultLanguage, setDefaultLanguage] = useState('PL');

  const items: MenuProps['items'] = [
    {
      key: 'PL',
      label: (
        <div className='wrapperFlag'>
          <img
            src='./images/PL.png'
            alt='Polski'
          />
          Polski
        </div>
      ),
    },
    {
      key: 'EN',
      label: (
        <div className='wrapperFlag'>
          <img
            src='./images/EN.png'
            alt='English'
          />
          English
        </div>
      ),
    },
  ];

  const handleSubmitLanguage = (value: string) => {
    setDefaultLanguage(value);
  };

  return (
    <>
      <Dropdown.Button
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: [defaultLanguage],
          onClick: value => handleSubmitLanguage(value.key),
        }}
        icon={<DownOutlined />}
        className='changeLanguageButton'
      >
        <div className='wrapperFlag'>
          <img
            src={`./images/${defaultLanguage}.png`}
            alt='Angielski'
          />
        </div>
      </Dropdown.Button>
    </>
  );
};
