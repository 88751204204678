import React from 'react';
import { SettingsProfileForm, SettingsProfileFormValues } from '../../forms/profile';
import { User } from '../../reducers/user/model';
import { updateUserLoginRequest } from '../../reducers/user/actions';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';

export const SettingsProfile: React.FC = () => {
  const user = useSelector<RootReducerState, User | null>(state => state.user.userLogin);
  const dispatch = useDispatch();

  const handleProfileSubmit = (values: SettingsProfileFormValues) => {
    if (user) {
      dispatch(
        updateUserLoginRequest(
          {
            ...user,
            ...values,
          },
          () => {
            notification.success({
              duration: 2,
              message: 'Twoje dane',
              description: 'zostały zaaktualizowane',
            });
          },
        ),
      );
    }
  };

  return (
    <section className='page'>
      <div className='pageHeader'>
        <h1>Profil</h1>
      </div>
      <div className='pageContent'>
        <SettingsProfileForm
          onSubmit={handleProfileSubmit}
          user={user}
        />
      </div>
    </section>
  );
};
