import { FC, useEffect, useState, ChangeEvent } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';

import { useRestaurantLanguages } from '../../../utils/customHooks/useRestaurantLanguages';
import { MultiLangValue } from '../../../types/internal/language';
import { getMultiLangValue } from '../../../utils/language';

import { TabsMultiLang } from '../tabMultiLang/tabsMultiLang';

type Props = Omit<TextAreaProps, 'value' | 'onChange'> & {
  value: MultiLangValue;
  onChange: (value: MultiLangValue) => void;
  forceLanguages?: string[];
};
export const TextareaMultiLang: FC<Props> = ({ forceLanguages, value, onChange, ...rest }) => {
  const [activeLang, setActiveLang] = useState<string | undefined>();
  const { languages, defaultLanguage, languagesFlags } = useRestaurantLanguages(forceLanguages);

  useEffect(() => {
    setActiveLang(defaultLanguage);
  }, [defaultLanguage]);

  const handleOnChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    if (activeLang) {
      const newValue = { ...value };
      newValue[activeLang] = evt.target.value;
      onChange(newValue);
    }
  };

  return (
    <>
      <TabsMultiLang
        activeLang={activeLang}
        languages={languages}
        setActiveLang={setActiveLang}
        languagesFlags={languagesFlags}
      />
      <Input.TextArea
        {...rest}
        value={getMultiLangValue(value, activeLang)}
        onChange={handleOnChange}
      />
    </>
  );
};
