import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';
import { Spin } from 'antd';

import { Paths } from './router/paths';
import { LoginPage } from './pages/login';
import { getMeRequest } from './reducers/user/actions';
import { AuthRoute } from './router/auth';
import { HomePage } from './pages/home';
import { Page404 } from './pages/404';
import { ResetPasswordPage } from './pages/password/reset/ResetPasswordPage';
import { LayoutSettings } from './layout/settings';
import { SettingsProfile } from './component/profile';
import { ProfileSettingsMenu } from './utils/profileSettings';
import { RegistrationPage } from './pages/registration/RegistrationPage';
import { SetPasswordPage } from './pages/password/set/SetPasswordPage';
import { ActivatePage } from './pages/activate/ActivatePage';
import { Subscriptions } from './component/subscriptions';
import { SettingsUsers } from './component/profileUsers';
import { SubscriptionCancelPage } from './pages/subscription/SubscriptionCancelPage';
import { SubscriptionSuccessPage } from './pages/subscription/SubscriptionSuccessPage';
import { useAuth } from './services/authService/useAuth';

import './index.css';
import { getOrganisationRequest } from './reducers/organisation/actions';

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, isAuthenticating } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getMeRequest());
      dispatch(getOrganisationRequest());
    }
  }, [dispatch, isAuthenticated]);

  if (isAuthenticating) {
    return (
      <Spin
        spinning={true}
        tip={'Logowanie...'}
        fullscreen={true}
      />
    );
  }

  if (!isAuthenticated) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path={Paths.RESET_PASSWORD}>
            <ResetPasswordPage />
          </Route>
          <Route path={Paths.SET_PASSWORD}>
            <SetPasswordPage />
          </Route>
          <Route path={Paths.LOGIN_PAGE}>
            <LoginPage />
          </Route>
          <Route path={Paths.REGISTRATION_USER}>
            <RegistrationPage />
          </Route>
          <Route path={Paths.SUBSCRIPTION_SUCCESS}>
            <SubscriptionSuccessPage />
          </Route>
          <Route path={Paths.SUBSCRIPTION_CANCEL}>
            <SubscriptionCancelPage />
          </Route>
          <Redirect to={Paths.LOGIN_PAGE} />
        </Switch>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path={Paths.RESET_PASSWORD}>
          <ResetPasswordPage />
        </Route>
        <Route path={Paths.SET_PASSWORD}>
          <SetPasswordPage />
        </Route>
        <Route path={Paths.LOGIN_PAGE}>
          <LoginPage />
        </Route>
        <Route path={Paths.REGISTRATION_USER}>
          <RegistrationPage />
        </Route>
        <Route path={Paths.SETTINGS_PROFILE}>
          <LayoutSettings showProfileMenu={ProfileSettingsMenu.PROFILE}>
            <SettingsProfile />
          </LayoutSettings>
        </Route>
        <Route path={Paths.SETTINGS_USERS}>
          <LayoutSettings showProfileMenu={ProfileSettingsMenu.USERS}>
            <SettingsUsers />
          </LayoutSettings>
        </Route>
        <Route path={Paths.SETTINGS_SUBSCRIPTIONS}>
          <LayoutSettings showProfileMenu={ProfileSettingsMenu.SUBSCRIPTIONS}>
            <Subscriptions />
          </LayoutSettings>
        </Route>
        <Route path={Paths.SUBSCRIPTION_CANCEL}>
          <SubscriptionCancelPage />
        </Route>
        <Route path={Paths.SUBSCRIPTION_SUCCESS}>
          <SubscriptionSuccessPage />
        </Route>
        <Route path={Paths.ACTIVATE_USER}>
          <ActivatePage />
        </Route>
        <AuthRoute
          path={Paths.HOME_PAGE}
          exact={true}
        >
          <HomePage />
        </AuthRoute>
        <Route path={Paths.PAGE_404}>
          <Page404 />
        </Route>
        <Redirect to={Paths.PAGE_404} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
