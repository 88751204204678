import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Dropdown } from 'antd';

import { authService } from '../../services/authService/authService';
import { UserOutlined } from '@ant-design/icons';
import { User } from '../../reducers/user/model';
import { Paths } from '../../router/paths';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { logoutUserRequest } from '../../reducers/user/actions';

type DropDownMenuProps = {};

export const DropDownMenu: React.FC<DropDownMenuProps> = () => {
  const history = useHistory();
  const user = useSelector<RootReducerState, User | null>(state => state.user.userLogin);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(
      logoutUserRequest(() => {
        authService.logout();
        history.push(Paths.LOGIN_PAGE);
      }),
    );
  };

  const items = [
    {
      key: '1',
      icon: <UserOutlined />,
      label: (
        <>
          {user?.email && (
            <span>
              {user.name} {user.surname} ({user.email})
            </span>
          )}
        </>
      ),
    },
    {
      key: '2',
      label: <Link to={Paths.SETTINGS_PROFILE}>Profil</Link>,
    },
    {
      key: '3',
      label: <Link to={Paths.SETTINGS_USERS}>Użytkownicy</Link>,
    },
    {
      key: '4',
      label: <Link to={Paths.SETTINGS_SUBSCRIPTIONS}>Subskrypcje</Link>,
    },
    {
      key: '5',
      label: (
        <div
          role={'presentation'}
          onClick={handleLogout}
        >
          Wyloguj
        </div>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      placement='bottomRight'
    >
      <Button
        className='avatarButton'
        shape='circle'
        icon={<UserOutlined />}
        size='large'
      />
    </Dropdown>
  );
};
