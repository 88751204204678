import React, { ReactNode } from 'react';

import { Header } from '../../component/header/Header';

import './style.scss';

type Props = {
  children?: ReactNode;
};
export const LayoutMain: React.FC<Props> = props => (
  <div className='appContainer'>
    <Header />
    {props.children}
  </div>
);
