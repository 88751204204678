import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { ResetPasswordFormValues, ResetPasswordFormProps } from '.';

export const initFormik = (props: ResetPasswordFormProps): FormikConfig<ResetPasswordFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    email: '',
  },
  validationSchema: () =>
    yup.object({
      email: yup.string().email('E-mail nie jest poprawny').required('E-mail jest wymagany'),
    }),
  onSubmit: values => {
    props.onSubmit(values);
  },
});
