import { call, put, takeLatest, CallEffect, PutEffect, select } from 'redux-saga/effects';
import { notification } from 'antd';
import {
  getDishListError,
  getDishListSuccess,
  addDishRequest,
  addDishSuccess,
  addDishError,
  updateDishRequest,
  updateDishSuccess,
  updateDishError,
  deleteDishRequest,
  deleteDishSuccess,
  deleteDishError,
  getDishRequest,
  getDishError,
  getDishSuccess,
} from './actions';
import * as CONST from './consts';
import { Dish } from './model';
import { ResponseModel } from '../model';
import { apiDishes } from '../../services/apiClient/dish';
import { ActionType } from 'typesafe-actions';
import { getErrorMessage } from '../../utils/error';
import { DELETE_CATEGORY_SUCCESS } from '../category/consts';
import { deleteCategorySuccess } from '../category/actions';
import { RootReducerState } from '../index';

const getDishes = (state: RootReducerState) => state.dish.dishList;

function* getDishList(): Generator<CallEffect | PutEffect, void, ResponseModel<Dish[]>> {
  try {
    const response = yield call(apiDishes.getDishes);

    yield put(getDishListSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get dish list',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(getDishListError(err));
  }
}

function* addDish(
  action: ActionType<typeof addDishRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<Dish>> {
  try {
    const { dish, onSuccess } = action.payload;
    const response = yield call(apiDishes.addDish, dish);

    yield put(addDishSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding dish',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(addDishError(err));
  }
}

function* updateDish(
  action: ActionType<typeof updateDishRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<Dish>> {
  try {
    const { dish, onSuccess } = action.payload;
    const response = yield call(apiDishes.updateDish, dish);

    yield put(updateDishSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating dish',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(updateDishError(err));
  }
}

function* deleteDish(action: ActionType<typeof deleteDishRequest>): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { dishId, onSuccess } = action.payload;
    yield call(apiDishes.deleteDish, dishId);

    yield put(deleteDishSuccess(dishId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting dish',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(deleteDishError(err));
  }
}

function* deleteInnerDish(
  action: ActionType<typeof deleteCategorySuccess>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { categoryId } = action.payload;
    // @ts-expect-error
    const dishes: Dish[] = yield select(getDishes);
    const dishesInCat = dishes.filter(dish => dish.categoryId === categoryId);
    for (const dish of dishesInCat) {
      yield put(deleteDishSuccess(dish._id));
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting dish',
      description: getErrorMessage(err),
    });
  }
}

function* getDish(
  action: ActionType<typeof getDishRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<Dish>> {
  try {
    const { dishId } = action.payload;
    const response = yield call(apiDishes.getDish, dishId);

    yield put(getDishSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during getting dish',
      description: getErrorMessage(err),
    });
    // @ts-expect-error
    yield put(getDishError(err));
  }
}

export function* watchDishesSaga(): Generator {
  yield takeLatest(CONST.GET_DISH_LIST_REQUEST, getDishList);
  yield takeLatest(CONST.ADD_DISH_REQUEST, addDish);
  yield takeLatest(CONST.UPDATE_DISH_REQUEST, updateDish);
  yield takeLatest(CONST.DELETE_DISH_REQUEST, deleteDish);
  yield takeLatest(DELETE_CATEGORY_SUCCESS, deleteInnerDish);
  yield takeLatest(CONST.GET_DISH_REQUEST, getDish);
}
