import { call, put, takeLatest, CallEffect, PutEffect, select } from 'redux-saga/effects';
import { notification } from 'antd';
import {
  getCategoryListRequest,
  getCategoryListError,
  getCategoryListSuccess,
  addCategoryRequest,
  addCategorySuccess,
  addCategoryError,
  updateCategoryRequest,
  updateCategorySuccess,
  updateCategoryError,
  deleteCategoryRequest,
  deleteCategorySuccess,
  deleteCategoryError,
  getCategoryRequest,
  getCategorySuccess,
  getCategoryError,
} from './actions';
import * as CONST from './consts';
import { Category } from './model';
import { ResponseModel } from '../model';
import { ActionType } from 'typesafe-actions';
import { apiCategory } from '../../services/apiClient/category';
import { getErrorMessage } from '../../utils/error';
import { DELETE_RESTAURANT_MENU_SUCCESS } from '../menu/consts';
import { deleteRestaurantMenuSuccess } from '../menu/actions';
import { RootReducerState } from '../index';

const getCategories = (state: RootReducerState) => state.category.categoryList;

function* getCategoryList(
  action: ActionType<typeof getCategoryListRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<Category[]>> {
  try {
    const { menuId } = action.payload;
    const response = yield call(apiCategory.getCategoriesMenu, menuId);

    yield put(getCategoryListSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get category list',
      description: getErrorMessage(err),
    });
    yield put(getCategoryListError(err));
  }
}

function* addCategory(
  action: ActionType<typeof addCategoryRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<Category>> {
  try {
    const { category, onSuccess } = action.payload;
    const response = yield call(apiCategory.addCategory, category);

    yield put(addCategorySuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding category',
      description: getErrorMessage(err),
    });
    yield put(addCategoryError(err));
  }
}

function* updateCategory(
  action: ActionType<typeof updateCategoryRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<Category>> {
  try {
    const { category, onSuccess } = action.payload;
    const response = yield call(apiCategory.updateCategory, category);

    yield put(updateCategorySuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating category',
      description: getErrorMessage(err),
    });
    yield put(updateCategoryError(err));
  }
}

function* deleteCategory(
  action: ActionType<typeof deleteCategoryRequest>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { categoryId, onSuccess } = action.payload;
    yield call(apiCategory.deleteCategory, categoryId);

    yield put(deleteCategorySuccess(categoryId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting category',
      description: getErrorMessage(err),
    });
    yield put(deleteCategoryError(err));
  }
}

function* deleteInnerCategory(
  action: ActionType<typeof deleteRestaurantMenuSuccess>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { menuId } = action.payload;
    // @ts-expect-error
    const categories: Category[] = yield select(getCategories);
    const catInMenu = categories.filter(cat => cat.menuId === menuId);
    for (const cat of catInMenu) {
      yield put(deleteCategorySuccess(cat._id));
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting menu',
      description: getErrorMessage(err),
    });
  }
}

function* getCategory(
  action: ActionType<typeof getCategoryRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<Category>> {
  try {
    const { categoryId } = action.payload;
    const response = yield call(apiCategory.getCategory, categoryId);

    yield put(getCategorySuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during getting category',
      description: getErrorMessage(err),
    });
    yield put(getCategoryError(err));
  }
}

export function* watchCategorySaga(): Generator {
  yield takeLatest(CONST.GET_CATEGORY_LIST_REQUEST, getCategoryList);
  yield takeLatest(CONST.ADD_CATEGORY_REQUEST, addCategory);
  yield takeLatest(CONST.UPDATE_CATEGORY_REQUEST, updateCategory);
  yield takeLatest(CONST.DELETE_CATEGORY_REQUEST, deleteCategory);
  yield takeLatest(DELETE_RESTAURANT_MENU_SUCCESS, deleteInnerCategory);
  yield takeLatest(CONST.GET_CATEGORY_REQUEST, getCategory);
}
