import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash';
import { getEmojiFlag } from 'countries-list';

import { RootReducerState } from '../../reducers';
import { Restaurant } from '../../reducers/restaurants/model';

const getMapFlags = (languages: string[]) => {
  const flags: Record<string, string> = {};
  languages.forEach(langItem => {
    flags[langItem] = getEmojiFlag(langItem);
  });

  return flags;
};

export const useRestaurantLanguages = (forceLanguages?: string[]) => {
  const [defaultLanguage, setDefaultLanguage] = useState<string>('');
  const [languages, setLanguages] = useState<string[]>([]);
  const [languagesFlags, setLanguagesFlags] = useState<Record<string, string>>({});
  const restaurant = useSelector<RootReducerState, Restaurant | undefined>(state =>
    state.restaurants.restaurants.find(restaurantItem => restaurantItem._id === state.app.selectedRestaurantId),
  );

  useEffect(() => {
    if (forceLanguages) {
      const restaurantLanguages = uniq(forceLanguages);
      setDefaultLanguage(restaurantLanguages[0]);
      setLanguages(restaurantLanguages);
      setLanguagesFlags(getMapFlags(restaurantLanguages));
      return;
    }

    if (restaurant) {
      const restaurantLanguages = uniq([restaurant.defaultLanguage, ...restaurant.languages]);
      setDefaultLanguage(restaurantLanguages[0]);
      setLanguages(restaurantLanguages);
      setLanguagesFlags(getMapFlags(restaurantLanguages));
    }
  }, [restaurant, forceLanguages]);

  return { languages, languagesFlags, defaultLanguage };
};
