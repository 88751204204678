import React from 'react';
import { Button } from 'antd';

import './style.scss';

export const Page404: React.FC = () => {
  return (
    <div className='appContainer'>
      <div className='page-404'>
        <img
          className='logo-404'
          alt='MenuApp'
          src='./images/logo_text.png'
        />
        <img
          alt='404'
          src='./images/404.png'
        />
        <h1>Ta przestrzeń jest pusta</h1>
        <p>
          Strona, której szukasz, <b>nie istnieje</b> (błąd 404)
        </p>
        <Button href='/'>Wróć do Strony Głównej</Button>
      </div>
    </div>
  );
};
