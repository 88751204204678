import { FC, useEffect } from 'react';
import { loginRequest } from '../../reducers/user/actions';
import { LoginForm, LoginFormValues } from '../../forms/login';
import { Paths } from '../../router/paths';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../services/authService/useAuth';
import { useDispatch } from 'react-redux';

export const LoginPage: FC = () => {
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();

  const handleLoginSubmit = (values: LoginFormValues) => {
    dispatch(loginRequest(values.email, values.password));
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push(Paths.HOME_PAGE);
    }
  }, [isAuthenticated, history]);

  return <LoginForm onSubmit={handleLoginSubmit} />;
};
