import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { UserFormProps, UserFormValues } from '.';
import { UserGroup } from '../../reducers/user/model';

export const initFormik = (props: UserFormProps): FormikConfig<UserFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: props.user?.name ?? '',
    surname: props.user?.surname ?? '',
    group: props.user?.group ?? ('' as UserGroup),
    active: props.user?.active ?? false,
    email: props.user?.email ?? '',
    language: props.user?.language ?? '',
  },
  validationSchema: () =>
    yup.object({
      name: yup.string().required('Imię jest wymagane'),
      surname: yup.string().required('Nazwisko jest wymagane'),
      email: yup.string().email('E-mail jest nie poprawny').required('E-mail jest wymagany'),
      language: yup.string().required('Język jest wymagany'),
      group: yup.string().required('Rola jest wymagana'),
    }),
  onSubmit: values => {
    props.onSubmit(values);
  },
});
