import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { ProductModel } from './model';

export const getSubscriptionsRequest = () => action(CONST.GET_SUBSCRIPTIONS_REQUEST);
export const getSubscriptionsSuccess = (subscriptions: ProductModel[]) =>
  action(CONST.GET_SUBSCRIPTIONS_SUCCESS, { subscriptions });
export const getSubscriptionsError = (error: unknown) => action(CONST.GET_SUBSCRIPTIONS_ERROR, { error });

export const createCheckoutSessionRequest = (priceId: string) =>
  action(CONST.CREATE_CHECKOUT_SESSION_REQUEST, { priceId });
export const createCheckoutSessionSuccess = () => action(CONST.CREATE_CHECKOUT_SESSION_SUCCESS);
export const createCheckoutSessionError = (error: unknown) => action(CONST.CREATE_CHECKOUT_SESSION_ERROR, { error });
