import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { Restaurant } from './model';

export const getRestaurantsRequest = () => action(CONST.GET_RESTAURANTS_REQUEST);
export const getRestaurantsSuccess = (restaurants: Restaurant[]) =>
  action(CONST.GET_RESTAURANTS_SUCCESS, { restaurants });
export const getRestaurantsError = (error: Error) => action(CONST.GET_RESTAURANTS_ERROR, { error });

export const addRestaurantRequest = (
  restaurant: Omit<Restaurant, '_id' | 'createdAt' | 'updatedAt'>,
  onSuccess?: () => void,
) => action(CONST.ADD_RESTAURANT_REQUEST, { restaurant, onSuccess });
export const addRestaurantSuccess = (restaurant: Restaurant) => action(CONST.ADD_RESTAURANT_SUCCESS, { restaurant });
export const addRestaurantError = (error: Error) => action(CONST.ADD_RESTAURANT_ERROR, { error });

export const updateRestaurantRequest = (restaurant: Restaurant, onSuccess?: () => void) =>
  action(CONST.UPDATE_RESTAURANT_REQUEST, { restaurant, onSuccess });
export const updateRestaurantSuccess = (restaurant: Restaurant) =>
  action(CONST.UPDATE_RESTAURANT_SUCCESS, { restaurant });
export const updateRestaurantError = (error: Error) => action(CONST.UPDATE_RESTAURANT_ERROR, { error });

export const deleteRestaurantRequest = (restaurantId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_RESTAURANT_REQUEST, { restaurantId, onSuccess });
export const deleteRestaurantSuccess = (restaurantId: string) =>
  action(CONST.DELETE_RESTAURANT_SUCCESS, { restaurantId });
export const deleteRestaurantError = (error: Error) => action(CONST.DELETE_RESTAURANT_ERROR, { error });
