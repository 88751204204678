export const LOGIN_REQUEST = '@users/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@users/LOGIN_SUCCESS';
export const LOGIN_ERROR = '@users/LOGIN_ERROR';

export const GET_TOKEN_REQUEST = '@users/GET_TOKEN_REQUEST';
export const GET_TOKEN_SUCCESS = '@users/GET_TOKEN_SUCCESS';
export const GET_TOKEN_ERROR = '@users/GET_TOKEN_ERROR';

export const SET_USER = '@users/SET_USER';
export const LOGOUT_USER_REQUEST = '@users/LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = '@users/LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_ERROR = '@users/LOGOUT_USER_ERROR';

export const RESET_PASSWORD_USER_REQUEST = '@users/RESET_PASSWORD_USER_REQUEST';
export const RESET_PASSWORD_USER_SUCCESS = '@users/RESET_PASSWORD_USER_SUCCESS';
export const RESET_PASSWORD_USER_ERROR = '@users/RESET_PASSWORD_USER_ERROR';

export const SET_PASSWORD_USER_REQUEST = '@users/SET_PASSWORD_USER_REQUEST';
export const SET_PASSWORD_USER_SUCCESS = '@users/SET_PASSWORD_USER_SUCCESS';
export const SET_PASSWORD_USER_ERROR = '@users/SET_PASSWORD_USER_ERROR';

export const GET_USER_REQUEST = '@users/GET_USER_REQUEST';
export const GET_USER_SUCCESS = '@users/GET_USER_SUCCESS';
export const GET_USER_ERROR = '@users/GET_USER_ERROR';

export const UPDATE_USER_LOGIN_REQUEST = '@users/UPDATE_USER_LOGIN_REQUEST';
export const UPDATE_USER_LOGIN_SUCCESS = '@users/UPDATE_USER_LOGIN_SUCCESS';
export const UPDATE_USER_LOGIN_ERROR = '@users/UPDATE_USER_LOGIN_ERROR';

export const ONBOARD_USER_REQUEST = '@users/ONBOARD_USER_REQUEST';
export const ONBOARD_USER_SUCCESS = '@users/ONBOARD_USER_SUCCESS';
export const ONBOARD_USER_ERROR = '@users/ONBOARD_USER_ERROR';

export const GET_USERS_REQUEST = '@users/GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = '@users/GET_USERS_SUCCESS';
export const GET_USERS_ERROR = '@users/GET_USERS_ERROR';

export const ADD_USER_REQUEST = '@users/ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = '@users/ADD_USER_SUCCESS';
export const ADD_USER_ERROR = '@users/ADD_USER_ERROR';

export const UPDATE_USER_REQUEST = '@users/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = '@users/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = '@users/UPDATE_USER_ERROR';

export const DELETE_USER_REQUEST = '@users/DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = '@users/DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = '@users/DELETE_USER_ERROR';

export const REGISTER_USER_REQUEST = '@users/REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = '@users/REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = '@users/REGISTER_USER_ERROR';

export const ACTIVATE_USER_REQUEST = '@users/ACTIVATE_USER_REQUEST';
export const ACTIVATE_USER_SUCCESS = '@users/ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_ERROR = '@users/ACTIVATE_USER_ERROR';

export const GET_ME_REQUEST = '@users/GET_ME_REQUEST';
export const GET_ME_SUCCESS = '@users/GET_ME_SUCCESS';
export const GET_ME_ERROR = '@users/GET_ME_ERROR';
