import { FC, useEffect, useState } from 'react';
import { Modal, Popconfirm, Upload } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { authService } from '../../services/authService/authService';
import { AssetBucket, AssetModel } from '../../reducers/assets/model';
import { UploadButton } from './UploadButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { UploadFile } from 'antd/es/upload/interface';
import { RcFile, UploadProps } from 'antd/es/upload';
import { deleteAssetRequest } from '../../reducers/assets/actions';
import { assetsToUploadedFile, getBase64, getCompressFormat, resizeFile } from './utils';

type UploadImageProps = {
  assets: AssetModel[];
  bucket: AssetBucket;
  multiple?: boolean;
  onChange: (assets: AssetModel[]) => void;
};

export const UploadImage: FC<UploadImageProps> = props => {
  const { bucket, assets, onChange, multiple = false } = props;
  const isFetchingAssets = useSelector<RootReducerState, boolean>(state => state.assets.isFetching);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fileList.length === 0 && assets.length > 0) {
      setFileList(assets.map(image => assetsToUploadedFile(image)));
    } else if (fileList.length > 0 && assets.length === fileList.length) {
      setFileList(assets.map(image => assetsToUploadedFile(image)));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [assets]);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url ?? (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList, file }) => {
    setFileList(newFileList);
    if (file.status === 'done') {
      onChange([...assets, file.response.data[0]]);
    }
  };

  const handleRemove = (file: UploadFile) => {
    dispatch(
      deleteAssetRequest(file.uid, () => {
        const filteredImages = assets.filter(image => image._id !== file.uid);
        onChange(filteredImages);
        setFileList(filteredImages.map(image => assetsToUploadedFile(image)));
      }),
    );
  };

  return (
    <>
      <Upload
        name='assets'
        accept='image/*'
        multiple={multiple}
        action={`${process.env.REACT_APP_HOST_API ?? ''}/assets/upload`}
        headers={{
          authorization: String(authService.getToken()),
        }}
        data={{ bucket }}
        listType='picture-card'
        fileList={fileList}
        beforeUpload={file => {
          const compressFormat = getCompressFormat(file.name);
          if (compressFormat) {
            return resizeFile(file, compressFormat);
          }
        }}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={() => false}
        showUploadList={{
          removeIcon: file => (
            <Popconfirm
              title='Usuń obraz'
              description='Czy jesteś pewien aby usunąć obraz?'
              onConfirm={() => handleRemove(file)}
              okText='Tak'
              cancelText='Nie'
            >
              <DeleteOutlined />
            </Popconfirm>
          ),
        }}
      >
        <UploadButton isFetchingAssets={isFetchingAssets} />
      </Upload>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        width={'70%'}
      >
        <img
          alt={previewTitle}
          style={{ width: '100%' }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
