import React, { ReactNode, useState } from 'react';
import { DeleteOutlined, EditOutlined, LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Avatar, Button, Input, Popconfirm, Spin, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import {
  setAppSelectedCategory,
  setAppSelectedDish,
  setAppSelectedMenu,
  setAppSelectedRestaurant,
} from '../../reducers/app/actions';
import { getDishRequest } from '../../reducers/dish/actions';
import { AssetModel } from '../../reducers/assets/model';
import { MultiLangValue } from '../../types/internal/language';
import { getMultiLangValue } from '../../utils/language';
import { useRestaurantLanguages } from '../../utils/customHooks/useRestaurantLanguages';

import './style.scss';

const { Search } = Input;

type CardPageData = {
  _id: string;
  name: MultiLangValue | string;
  assets?: AssetModel[];
  categoryId?: string;
  menuId?: string;
};

type CardPageProps = {
  children?: ReactNode;
  list: CardPageData[];
  isFetching: boolean;
  title: string;
  selectedId: string | null;
  openEditItemModal: (itemId: string) => void;
  handleDeleteItem: (itemId: string) => void;
  openModal: () => void;
  label: string;
  setSelectedId?:
    | typeof setAppSelectedRestaurant
    | typeof setAppSelectedMenu
    | typeof setAppSelectedCategory
    | typeof setAppSelectedDish
    | typeof getDishRequest;
  searchField?: boolean;
  setSearchInput?: (searchText: string) => void;
  openDetailDish?: (itemId: string) => void;
};

export const CardPage: React.FC<CardPageProps> = props => {
  const {
    children,
    list,
    isFetching,
    title,
    selectedId,
    openEditItemModal,
    handleDeleteItem,
    openModal,
    label,
    setSelectedId,
    searchField,
    setSearchInput,
    openDetailDish,
  } = props;
  const [showElement, setShowElement] = useState('');
  const [shortLeftMenu, setShortLeftMenu] = useState('');
  const { defaultLanguage } = useRestaurantLanguages();
  const dispatch = useDispatch();

  const showBoxPage = () => {
    setShortLeftMenu('');
    setTimeout(() => {
      setShowElement('');
    }, 200);
  };

  const hideBoxPage = () => {
    setShowElement('hide');
    setShortLeftMenu('shortLeftMenu');
  };

  const showMenu = (elementId: string) => {
    if (setSelectedId) {
      dispatch(setSelectedId(elementId));
    }
  };

  return (
    <section className='leftMenuWrapper'>
      {searchField && setSearchInput && (
        <div className='searchWrapper'>
          <Search
            className='search'
            placeholder='szukaj dania'
            onSearch={value => setSearchInput(value)}
          />
        </div>
      )}
      <div className={`leftMenu leftFull ${shortLeftMenu}`}>
        <div className='leftMenuHeader'>
          {children}
          <p className={showElement}>{title}</p>
        </div>
        {isFetching && (
          <div className='leftMenuContent'>
            <Spin spinning={isFetching} />
          </div>
        )}
        {list.length > 0 && (
          <div className='leftMenuContent'>
            {showElement && !openDetailDish && (
              <Button
                className='showElement'
                onClick={() => showBoxPage()}
                type='link'
                icon={<RightOutlined />}
              />
            )}
            {showElement === '' && !openDetailDish && (
              <Button
                className='hideElement'
                onClick={() => hideBoxPage()}
                type='link'
                icon={<LeftOutlined />}
              />
            )}
            <ul>
              {list.map((listItem, idx) => {
                const itemName = getMultiLangValue(listItem.name, defaultLanguage);

                return (
                  <li
                    key={idx}
                    className={listItem._id === selectedId ? 'active' : ''}
                  >
                    <div
                      className='menuText'
                      onClick={() => {
                        if (openDetailDish) {
                          return openDetailDish(listItem._id);
                        }
                        return showMenu(listItem._id);
                      }}
                      role='presentation'
                    >
                      <Tooltip
                        placement='topLeft'
                        title={itemName}
                      >
                        {listItem.assets?.[0]?.uri ? (
                          <div>
                            <div className='imageWrapper'>
                              <div
                                className='image'
                                style={{
                                  backgroundImage: `url(${process.env.REACT_APP_HOST_ASSETS_URL ?? ''}${
                                    listItem.assets[0].uri
                                  })`,
                                }}
                              ></div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <Avatar className='avatarColor'>{itemName.substring(0, 2)}</Avatar>
                          </div>
                        )}
                        <span className={`leftMenuText ${showElement}`}>{itemName}</span>
                      </Tooltip>
                    </div>
                    <div className={`leftMenuButtons ${showElement ? 'hide' : 'show'}`}>
                      <Button
                        onClick={() => openEditItemModal(listItem._id)}
                        type='link'
                        icon={<EditOutlined />}
                      />
                      <Popconfirm
                        title={`Czy na pewno chcesz usunąć ${label}?`}
                        onConfirm={() => handleDeleteItem(listItem._id)}
                        okText='Tak'
                        cancelText='Nie'
                      >
                        <Button
                          type='link'
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {!isFetching && (
          <div className='leftMenuFooter'>
            <Button onClick={openModal}>
              <PlusOutlined />
              <span className='leftMenuFooterText'>
                Dodaj <span className='leftMenuFooterTextName'>{label}</span>
              </span>
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};
