import { BaseAPI } from '../base';
import { ResponseModel } from '../../../reducers/model';
import { Dish } from '../../../reducers/dish/model';

class APIDishes extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/dishes`;
  }

  public getDishes = async (): Promise<ResponseModel<Dish[]>> => {
    return this.authFetch<ResponseModel<Dish[]>>(this.apiURL, { method: 'GET' });
  };

  public addDish = async (data: Omit<Dish, '_id' | 'createdAt' | 'updatedAt'>): Promise<ResponseModel<Dish>> => {
    return this.authFetch<ResponseModel<Dish>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateDish = async (data: Dish): Promise<ResponseModel<Dish>> => {
    return this.authFetch<ResponseModel<Dish>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteDish = async (dishId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${dishId}`, { method: 'DELETE' });
  };

  public getDish = async (dishId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${dishId}`, { method: 'GET' });
  };
}

export const apiDishes = new APIDishes();
