import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { Dish } from './model';

export const getDishListRequest = () => action(CONST.GET_DISH_LIST_REQUEST);
export const getDishListSuccess = (dishList: Dish[]) => action(CONST.GET_DISH_LIST_SUCCESS, { dishList });
export const getDishListError = (error: Error) => action(CONST.GET_DISH_LIST_ERROR, { error });

export const addDishRequest = (dish: Omit<Dish, '_id' | 'createdAt' | 'updatedAt'>, onSuccess?: () => void) =>
  action(CONST.ADD_DISH_REQUEST, { dish, onSuccess });
export const addDishSuccess = (dish: Dish) => action(CONST.ADD_DISH_SUCCESS, { dish });
export const addDishError = (error: Error) => action(CONST.ADD_DISH_ERROR, { error });

export const updateDishRequest = (dish: Dish, onSuccess?: () => void) =>
  action(CONST.UPDATE_DISH_REQUEST, { dish, onSuccess });
export const updateDishSuccess = (dish: Dish) => action(CONST.UPDATE_DISH_SUCCESS, { dish });
export const updateDishError = (error: Error) => action(CONST.UPDATE_DISH_ERROR, { error });

export const deleteDishRequest = (dishId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_DISH_REQUEST, { dishId, onSuccess });
export const deleteDishSuccess = (dishId: string) => action(CONST.DELETE_DISH_SUCCESS, { dishId });
export const deleteDishError = (error: Error) => action(CONST.DELETE_DISH_ERROR, { error });

export const getDishRequest = (dishId: string) => action(CONST.GET_DISH_REQUEST, { dishId });
export const getDishSuccess = (dish: Dish) => action(CONST.GET_DISH_SUCCESS, { dish });
export const getDishError = (error: Error) => action(CONST.GET_DISH_ERROR, { error });
