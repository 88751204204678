import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { User, UserOnboardRequest, UserOnboardResponse } from './model';

export const loginRequest = (email: string, password: string) => action(CONST.LOGIN_REQUEST, { email, password });
export const loginSuccess = () => action(CONST.LOGIN_SUCCESS);
export const loginError = (error: unknown) => action(CONST.LOGIN_ERROR, { error });

export const getTokenRequest = (callback?: (token?: string) => void) => action(CONST.GET_TOKEN_REQUEST, { callback });
export const getTokenSuccess = (token: string) => action(CONST.GET_TOKEN_SUCCESS, { token });
export const getTokenError = (error: unknown) => action(CONST.GET_TOKEN_ERROR, { error });

export const setUser = (data: User) => action(CONST.SET_USER, data);

export const logoutUserRequest = (callback?: () => void) => action(CONST.LOGOUT_USER_REQUEST, { callback });
export const logoutUserSuccess = () => action(CONST.LOGOUT_USER_SUCCESS);
export const logoutUserError = (error: unknown) => action(CONST.LOGOUT_USER_SUCCESS, { error });

export const resetPasswordRequest = (email: string, onSuccess?: () => void) =>
  action(CONST.RESET_PASSWORD_USER_REQUEST, { email, onSuccess });
export const resetPasswordSuccess = (data: User) => action(CONST.RESET_PASSWORD_USER_SUCCESS, data);
export const resetPasswordError = (error: unknown) => action(CONST.RESET_PASSWORD_USER_ERROR, { error });

export const setPasswordRequest = (
  data: { password: string; passwordConfirmation: string; hash: string },
  onSuccess?: () => void,
) => action(CONST.SET_PASSWORD_USER_REQUEST, { data, onSuccess });
export const setPasswordSuccess = () => action(CONST.SET_PASSWORD_USER_SUCCESS);
export const setPasswordError = (error: unknown) => action(CONST.SET_PASSWORD_USER_ERROR, { error });

export const getUserRequest = (userId: string) => action(CONST.GET_USER_REQUEST, { userId });
export const getUserSuccess = (data: User) => action(CONST.GET_USER_SUCCESS, { data });
export const getUserError = (error: unknown) => action(CONST.GET_USER_ERROR, { error });

//TODO This does the same what update user does, we can get rid of this action.
export const updateUserLoginRequest = (user: Omit<User, 'salt' | 'resetPasswordToken'>, onSuccess?: () => void) =>
  action(CONST.UPDATE_USER_LOGIN_REQUEST, { user, onSuccess });
export const updateUserLoginSuccess = (user: User) => action(CONST.UPDATE_USER_LOGIN_SUCCESS, { user });
export const updateUserLoginError = (error: unknown) => action(CONST.UPDATE_USER_LOGIN_ERROR, { error });

export const onboardUserRequest = (data: UserOnboardRequest) => action(CONST.ONBOARD_USER_REQUEST, { data });
export const onboardUserSuccess = (data: UserOnboardResponse) => action(CONST.ONBOARD_USER_SUCCESS, { data });
export const onboardUserError = (error: unknown) => action(CONST.ONBOARD_USER_ERROR, { error });

export const getUsersRequest = () => action(CONST.GET_USERS_REQUEST);
export const getUsersSuccess = (users: User[]) => action(CONST.GET_USERS_SUCCESS, { users });
export const getUsersError = (error: unknown) => action(CONST.GET_USERS_ERROR, { error });

export const addUserRequest = (
  user: Omit<User, '_id' | 'salt' | 'createdAt' | 'updatedAt' | 'resetPasswordToken' | 'organisationId' | 'ownerId'>,
  onSuccess?: () => void,
) => action(CONST.ADD_USER_REQUEST, { user, onSuccess });
export const addUserSuccess = (user: User) => action(CONST.ADD_USER_SUCCESS, { user });
export const addUserError = (error: unknown) => action(CONST.ADD_USER_ERROR, { error });

export const updateUserRequest = (user: User, onSuccess?: () => void) =>
  action(CONST.UPDATE_USER_REQUEST, { user, onSuccess });
export const updateUserSuccess = (user: User) => action(CONST.UPDATE_USER_SUCCESS, { user });
export const updateUserError = (error: unknown) => action(CONST.UPDATE_USER_ERROR, { error });

export const deleteUserRequest = (userId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_USER_REQUEST, { userId, onSuccess });
export const deleteUserSuccess = (userId: string) => action(CONST.DELETE_USER_SUCCESS, { userId });
export const deleteUserError = (error: unknown) => action(CONST.DELETE_USER_ERROR, { error });

export const registerUserRequest = (
  email: string,
  password: string,
  passwordConfirmation: string,
  onSuccess?: () => void,
) => action(CONST.REGISTER_USER_REQUEST, { email, password, passwordConfirmation, onSuccess });
export const registerUserSuccess = (user: User) => action(CONST.REGISTER_USER_SUCCESS, { user });
export const registerUserError = (error: unknown) => action(CONST.REGISTER_USER_ERROR, { error });

export const activateUserRequest = (hash: string, onSuccess?: () => void, onError?: () => void) =>
  action(CONST.ACTIVATE_USER_REQUEST, { hash, onSuccess, onError });
export const activateUserSuccess = (user: User) => action(CONST.ACTIVATE_USER_SUCCESS, { user });
export const activateUserError = (error: unknown) => action(CONST.ACTIVATE_USER_ERROR, { error });

export const getMeRequest = () => action(CONST.GET_ME_REQUEST);
export const getMeSuccess = (user: User) => action(CONST.GET_ME_SUCCESS, { user });
export const getMeError = (error: unknown) => action(CONST.GET_ME_ERROR, { error });
