import React, { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SetPasswordForm, SetPasswordFormValues } from '../../../forms/setPassword';
import { setPasswordRequest } from '../../../reducers/user/actions';
import { LayoutLogin } from '../../../layout/login';

export const SetPasswordPage: React.FC = () => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const hash = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('hash');
  }, [location.search]);

  const handleSetPasswordSubmit = (values: SetPasswordFormValues) => {
    const setValues = {
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
      hash: hash ?? '',
    };

    dispatch(
      setPasswordRequest(setValues, () => {
        setShowMessage(true);
      }),
    );
  };

  return (
    <LayoutLogin>
      <SetPasswordForm
        onSubmit={handleSetPasswordSubmit}
        showMessage={showMessage}
      />
    </LayoutLogin>
  );
};
