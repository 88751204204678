import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import * as menuActions from '../menu/actions';
import { GET_RESTAURANT_MENUS_SUCCESS } from '../menu/consts';
import { GET_DISH_LIST_SUCCESS } from '../dish/consts';
import { GET_CATEGORY_LIST_SUCCESS } from '../category/consts';
import * as categoryActions from '../category/actions';
import * as dishActions from '../dish/actions';

export type appAction =
  | ActionType<typeof actions>
  | ActionType<typeof menuActions>
  | ActionType<typeof categoryActions>
  | ActionType<typeof dishActions>;
export type AppState = {
  readonly selectedRestaurantId: string | null;
  readonly selectedMenuId: string | null;
  readonly selectedCategoryId: string | null;
  readonly selectedDishId: string | null;
};

export const appReducer = combineReducers<AppState, appAction>({
  selectedRestaurantId: (state = null, action) => {
    switch (action.type) {
      case CONST.SET_APP_SELECTED_RESTAURANT:
        return action.payload.restaurantId;

      default:
        return state;
    }
  },
  selectedMenuId: (state = null, action) => {
    switch (action.type) {
      case CONST.SET_APP_SELECTED_MENU:
        return action.payload.menuId;

      case GET_RESTAURANT_MENUS_SUCCESS:
        return null;

      default:
        return state;
    }
  },
  selectedCategoryId: (state = null, action) => {
    switch (action.type) {
      case CONST.SET_APP_SELECTED_CATEGORY:
        return action.payload.categoryId;

      case GET_RESTAURANT_MENUS_SUCCESS:
      case GET_CATEGORY_LIST_SUCCESS:
        return null;

      default:
        return state;
    }
  },
  selectedDishId: (state = null, action) => {
    switch (action.type) {
      case CONST.SET_APP_SELECTED_DISH:
        return action.payload.dishId;

      case GET_RESTAURANT_MENUS_SUCCESS:
      case GET_DISH_LIST_SUCCESS:
      case GET_CATEGORY_LIST_SUCCESS:
        return null;

      default:
        return state;
    }
  },
});
