import * as countriesISO2toISO3 from './countriesISO2toISO3.json';
import { countries, Country } from 'countries-list';

export type CountryType = {
  name: string;
  flag: string;
  shortName: string;
};

const countriesDictionary = {};

Object.keys(countries).forEach((countryNameISO2: string) => {
  // @ts-expect-error
  const countryNameISO3 = countriesISO2toISO3[countryNameISO2];
  // @ts-expect-error
  const country: Country = countries[countryNameISO2];

  // @ts-expect-error
  countriesDictionary[countryNameISO3] = {
    name: country.name,
    flag: country.emoji,
    shortName: countryNameISO3,
  };
});

export const countriesMap: Record<string, CountryType> = countriesDictionary;
