import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';
import {
  createCheckoutSessionError,
  createCheckoutSessionRequest,
  createCheckoutSessionSuccess,
  getSubscriptionsError,
  getSubscriptionsSuccess,
} from './actions';
import * as CONST from './consts';
import { ProductModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { apiSubscriptions } from '../../services/apiClient/subscriptions';
import { ActionType } from 'typesafe-actions';

function* getSubscriptions(): Generator<CallEffect | PutEffect, void, ResponseModel<ProductModel[]>> {
  try {
    const response = yield call(apiSubscriptions.getSubscriptions);

    yield put(getSubscriptionsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get subscriptions',
      description: getErrorMessage(err),
    });

    yield put(getSubscriptionsError(err));
  }
}

function* createCheckoutSession(
  action: ActionType<typeof createCheckoutSessionRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<{ checkoutUrl: string }>> {
  try {
    const { priceId } = action.payload;
    const response = yield call(apiSubscriptions.createCheckoutSession, priceId);

    yield put(createCheckoutSessionSuccess());
    window.location.href = response.data.checkoutUrl;
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during create checkout',
      description: getErrorMessage(err),
    });
    yield put(createCheckoutSessionError(err));
  }
}

export function* watchSubscriptionSaga(): Generator {
  yield takeLatest(CONST.GET_SUBSCRIPTIONS_REQUEST, getSubscriptions);
  yield takeLatest(CONST.CREATE_CHECKOUT_SESSION_REQUEST, createCheckoutSession);
}
