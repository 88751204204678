export enum Paths {
  ACTIVATE_USER = '/activate',
  LOGIN_PAGE = '/login',
  HOME_PAGE = '/',
  PAGE_404 = '/404',
  REGISTRATION_USER = '/registration',
  RESET_PASSWORD = '/reset-password',
  SET_PASSWORD = '/set-password',
  SETTINGS_PROFILE = '/settings-profile',
  SETTINGS_USERS = '/settings-users',
  SETTINGS_SUBSCRIPTIONS = '/settings-subscriptions',
  SUBSCRIPTION_CANCEL = '/subscription-cancel',
  SUBSCRIPTION_SUCCESS = '/subscription-success',
}
