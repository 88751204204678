import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { SafetyOutlined, UserOutlined } from '@ant-design/icons';
import { Paths } from '../../router/paths';
import { Header } from '../../component/header/Header';
import { ProfileSettingsMenu } from '../../utils/profileSettings';

import './style.scss';

type Props = {
  showProfileMenu: ProfileSettingsMenu;
  children?: ReactNode;
};
export const LayoutSettings: React.FC<Props> = props => {
  const { showProfileMenu } = props;
  return (
    <div className='appContainer'>
      <Header />
      <div className='settingsContainer'>
        <section className='leftMenuWrapper'>
          <div className='leftMenu leftFull'>
            <div className='leftMenuContent'>
              <ul>
                <li className={ProfileSettingsMenu.PROFILE === showProfileMenu ? 'profile active' : 'profile'}>
                  <Link to={Paths.SETTINGS_PROFILE}>
                    <Button
                      icon={<UserOutlined />}
                      shape='circle'
                      size='large'
                    ></Button>
                    <span>Profil</span>
                  </Link>
                </li>
                <li className={ProfileSettingsMenu.USERS === showProfileMenu ? 'profile active' : 'profile'}>
                  <Link to={Paths.SETTINGS_USERS}>
                    <Button
                      icon={
                        <span className='anticon anticon-user'>
                          <img
                            alt='Użytkownicy'
                            src='/images/people.svg'
                          />
                        </span>
                      }
                      shape='circle'
                      size='large'
                    ></Button>
                    <span>Użytkownicy</span>
                  </Link>
                </li>
                <li className={ProfileSettingsMenu.SUBSCRIPTIONS === showProfileMenu ? 'profile active' : 'profile'}>
                  <Link to={Paths.SETTINGS_SUBSCRIPTIONS}>
                    <Button
                      icon={<SafetyOutlined />}
                      shape='circle'
                      size='large'
                    ></Button>
                    Subskrypcje
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {props.children}
      </div>
    </div>
  );
};
