import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { CategoryFormProps, CategoryFormValues } from '.';
import { createMultiLangSchema } from '../../utils/validation';

export const initFormik = (props: CategoryFormProps, languages: string[]): FormikConfig<CategoryFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: props.category?.name ?? {},
    assets: props.category?.assets ?? [],
  },
  validationSchema: () =>
    yup.object({
      name: createMultiLangSchema(languages, 'Nazwa w języku #lang jest wymagana').required('Nazwa jest wymagana'),
    }),
  onSubmit: values => {
    props.onSubmit(values);
  },
});
