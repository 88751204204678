import { FC, ReactNode } from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/lib';
import { FormikErrors } from 'formik';
import { MultiLangValue } from '../../../types/internal/language';

type Props = Omit<FormItemProps, 'hasFeedback' | 'validateStatus' | 'help'> & {
  children?: ReactNode;
  errors?: FormikErrors<MultiLangValue>;
};
export const FormItemMultiLang: FC<Props> = ({ children, errors, ...rest }) => {
  const errorsHTML = errors ? Object.values(errors).join('<br />') : '';

  return (
    <Form.Item
      {...rest}
      hasFeedback={true}
      validateStatus={errors ? 'error' : ''}
      help={<div dangerouslySetInnerHTML={{ __html: errorsHTML }} />}
    >
      {children}
    </Form.Item>
  );
};
