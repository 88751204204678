import { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import { Button, Form, Input, notification, Row, Select } from 'antd';
import { countriesMap } from '../../utils/countries';
import { getUserRequest, resetPasswordRequest } from '../../reducers/user/actions';
import { User } from '../../reducers/user/model';
import { initFormik } from './formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { FormItem } from '../../component/formItems/formItem/formItem';

import './style.scss';

export type SettingsProfileFormValues = {
  name: string;
  surname: string;
  language: string;
};

export type SettingsProfileFormProps = {
  user: User | null;
  onSubmit: (values: SettingsProfileFormValues) => void;
};

export const SettingsProfileForm: FC<SettingsProfileFormProps> = props => {
  const { user } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.user.isFetching);
  const isFetchingResetPassword = useSelector<RootReducerState, boolean>(state => state.user.isFetchingResetPassword);

  const dispatch = useDispatch();
  const formik = useFormik(initFormik(props));

  useEffect(() => {
    if (user?._id) {
      dispatch(getUserRequest(user._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserRequest]);

  const handleResetPassword = () => {
    if (user) {
      dispatch(
        resetPasswordRequest(user.email, () => {
          notification.success({
            duration: 0,
            message: 'Reset hasła',
            description:
              'Wysłaliśmy Ci e-mail, z linkiem do resetu hasła. Sprawdź pocztę z podanym przez Ciebie adresem e-mail',
          });
        }),
      );
    }
  };

  return (
    <div className='profile-form'>
      <Form>
        <div className='user-profile-data'>
          <div className='left'>
            <FormItem
              label='Imię'
              error={formik.touched.name && formik.errors.name}
            >
              <Input
                name='name'
                placeholder='Imię'
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>
          <div className='right'>
            <FormItem
              label='Nazwisko'
              error={formik.touched.surname && formik.errors.surname}
            >
              <Input
                name='surname'
                placeholder='Nazwisko'
                value={formik.values.surname}
                onChange={formik.handleChange}
              />
            </FormItem>
          </div>
        </div>
        <div className='user-profile-lang'>
          <h2>Zmień język komunikacji</h2>
          <FormItem error={formik.touched.language && formik.errors.language}>
            <Select
              showSearch
              value={formik.values.language}
              onChange={(value: string) => formik.setFieldValue('language', value)}
              filterOption={(input: string, option: any) =>
                (option?.key ?? '').toLowerCase().includes(input.toLowerCase())
              }
            >
              {Object.keys(countriesMap).map((iso3: string) => (
                <Select.Option
                  key={countriesMap[iso3].name}
                  value={iso3}
                >
                  {countriesMap[iso3].flag} {countriesMap[iso3].name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </div>
        <div className='user-profile-password'>
          <h2>Zresetuj swoje hasło</h2>
          <Button
            type='default'
            onClick={handleResetPassword}
            loading={isFetchingResetPassword}
          >
            Zresetuj hasło
          </Button>
        </div>
        <Row
          className='buttonWrapper'
          justify='end'
        >
          <Button
            type='primary'
            size='large'
            htmlType='submit'
            loading={isFetching}
            onClick={formik.submitForm}
          >
            Zapisz
          </Button>
        </Row>
      </Form>
    </div>
  );
};
