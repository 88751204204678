import { DishType } from '../../reducers/dish/model';

export type DishOptionType = {
  label: string;
  name: string;
  value: DishType;
};
export const dishTypeOptions: DishOptionType[] = [
  { label: 'danie', name: 'dania', value: DishType.MEAL },
  { label: 'napój', name: 'napoju', value: DishType.DRINK },
];
