import React, { useState } from 'react';
import { ResetPasswordForm, ResetPasswordFormValues } from '../../../forms/resetPassword';
import { resetPasswordRequest } from '../../../reducers/user/actions';
import { LayoutLogin } from '../../../layout/login';
import { useDispatch } from 'react-redux';

export const ResetPasswordPage: React.FC = () => {
  const dispatch = useDispatch();
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const handleResetPasswordSubmit = (values: ResetPasswordFormValues) => {
    dispatch(
      resetPasswordRequest(values.email, () => {
        setShowMessage(true);
      }),
    );
  };

  return (
    <LayoutLogin>
      <ResetPasswordForm
        onSubmit={handleResetPasswordSubmit}
        showMessage={showMessage}
      />
    </LayoutLogin>
  );
};
