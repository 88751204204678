import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { RestaurantFormProps, RestaurantFormValues } from '.';
import { createMultiLangSchema } from '../../utils/validation';

export const initFormik = (props: RestaurantFormProps, languages: string[]): FormikConfig<RestaurantFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: props.restaurant?.name ?? '',
    about: props.restaurant?.about ?? {},
    assets: props.restaurant?.assets ?? [],
    street: props.restaurant?.address.street ?? '',
    city: props.restaurant?.address.city ?? '',
    zip: props.restaurant?.address.zip ?? '',
    province: props.restaurant?.address.province ?? undefined,
    country: props.restaurant?.address.country ?? 'Polska',
    reviewRestaurant: props.restaurant?.settings.reviewRestaurant ?? true,
    reviewDish: props.restaurant?.settings.reviewDish ?? true,
    facebook: props.restaurant?.settings.socialMediaLinks?.facebook ?? undefined,
    bgColor: props.restaurant?.branding?.bgColor ?? undefined,
    buttonBgColor: props.restaurant?.branding?.buttonBgColor ?? undefined,
    textColor: props.restaurant?.branding?.textColor ?? undefined,
    instagram: props.restaurant?.settings.socialMediaLinks?.instagram ?? undefined,
    linkedIn: props.restaurant?.settings.socialMediaLinks?.linkedIn ?? undefined,
    tiktok: props.restaurant?.settings.socialMediaLinks?.tiktok ?? undefined,
    twitter: props.restaurant?.settings.socialMediaLinks?.twitter ?? undefined,
    languages: props.restaurant?.languages ?? [],
    defaultLanguage: props.restaurant?.defaultLanguage ?? '',
  },
  validationSchema: () =>
    yup.object({
      name: yup.string().required('Nazwa jest wymagana'),
      about: createMultiLangSchema(languages, 'Opis w języku #lang jest wymagany').required('Opis jest wymagany'),
      street: yup.string().required('Ulica jest wymagana'),
      city: yup.string().required('Miasto jest wymagany'),
      zip: yup.string().required('Kod pocztowy jest wymagany'),
      country: yup.string().required('Kraj jest wymagany'),
      defaultLanguage: yup.string().required('Język domyślny jest wymagany'),
      languages: yup.array(),
    }),
  onSubmit: values => {
    props.onSubmit(values);
  },
});
