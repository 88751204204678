import { FC } from 'react';
import { Dish, DishKind, DishKindNames, DishTaste, DishTasteNames, DishType } from '../../reducers/dish/model';
import { Button, Divider, Form, InputNumber, Radio, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { AssetBucket, AssetModel } from '../../reducers/assets/model';
import { DishOptionType, dishTypeOptions } from '../utils/dishTypeOptions';
import { MultiLangValue, MultiLangValueArray } from '../../types/internal/language';
import { initFormik } from './formik';
import { useRestaurantLanguages } from '../../utils/customHooks/useRestaurantLanguages';
import { RootReducerState } from '../../reducers';
import { FormItem } from '../../component/formItems/formItem/formItem';
import { FormItemMultiLang } from '../../component/formItems/formItemMultiLang/FormItemMultiLang';
import { InputMultiLang } from '../../component/formItems/inputMultiLang/InputMultiLang';
import { TextareaMultiLang } from '../../component/formItems/textareaMultiLang/textareaMultiLang';
import { TagDynamicMultiLang } from '../../component/formItems/tagDynamicMultiLang/tagDynamicMultiLang';
import { UploadImage } from '../../component/uploadImage /UploadImage';

import './style.scss';

export type DishFormValues = {
  name: MultiLangValue;
  description: MultiLangValue;
  weight?: number;
  calories?: number;
  kind: DishKind;
  taste: DishTaste;
  price: number;
  assets: AssetModel[];
  type: DishType;
  ingredients: MultiLangValueArray;
  allergens: MultiLangValueArray;
  capacity?: number;
  alcohol?: number;
};

export type DishFormProps = {
  dish?: Dish;
  onSubmit: (values: DishFormValues) => void;
  onCancel: () => void;
};

export const DishForm: FC<DishFormProps> = props => {
  const { dish } = props;
  const { languages } = useRestaurantLanguages();
  const formik = useFormik(initFormik(props, languages));
  const isFetching = useSelector<RootReducerState, boolean>(state => state.dish.isFetching);
  const activeDishName = dishTypeOptions.find(option => option.value === formik.values.type) as DishOptionType;

  return (
    <Form
      className='dishForm'
      layout={'vertical'}
    >
      <div className='dishCheckboxWrapper'>
        <FormItem>
          <Radio.Group
            onChange={evt => formik.setFieldValue('type', evt.target.value)}
            value={formik.values.type}
          >
            {dishTypeOptions.map(option => (
              <Radio
                className={`checkboxDish checkbox${option.value}`}
                key={option.value}
                value={option.value}
                disabled={dish && option.value !== formik.values.type}
              />
            ))}
          </Radio.Group>
        </FormItem>
      </div>
      <div className='dishFormContent'>
        <div className='leftForm'>
          <FormItemMultiLang
            required={true}
            label={`Nazwa ${activeDishName.name}`}
            errors={formik.touched.name && formik.errors.name}
          >
            <InputMultiLang
              placeholder={`Nazwa ${activeDishName.name}`}
              value={formik.values.name}
              onChange={value => formik.setFieldValue('name', value)}
            />
          </FormItemMultiLang>
          <FormItem
            required={true}
            label={`Cena ${activeDishName.name}`}
            error={formik.touched.price && formik.errors.price}
          >
            <InputNumber
              type='number'
              name={'price'}
              addonAfter='PLN'
              step={0.01}
              min={0}
              precision={2}
              placeholder={`Cena ${activeDishName.name}`}
              value={formik.values.price}
              onChange={value => formik.setFieldValue('price', value)}
            />
          </FormItem>
          <Divider />
          <FormItemMultiLang errors={formik.touched.ingredients && (formik.errors.ingredients as MultiLangValue)}>
            <TagDynamicMultiLang
              addLabel='Dodaj składnik'
              tags={formik.values.ingredients}
              onChange={tags => formik.setFieldValue('ingredients', tags)}
            />
          </FormItemMultiLang>
          <Divider />
          <FormItemMultiLang errors={formik.touched.allergens && (formik.errors.allergens as MultiLangValue)}>
            <TagDynamicMultiLang
              addLabel='Dodaj alergen'
              tags={formik.values.allergens}
              onChange={tags => formik.setFieldValue('allergens', tags)}
            />
          </FormItemMultiLang>
          <div className='dishFormDetails'>
            <div className='leftForm'>
              <FormItem
                label='Waga'
                hide={formik.values.type !== DishType.MEAL}
                error={formik.touched.weight && formik.errors.weight}
              >
                <InputNumber
                  type='number'
                  name='weight'
                  placeholder='Waga'
                  addonAfter={'g'}
                  min={0}
                  value={formik.values.weight}
                  onChange={value => formik.setFieldValue('weight', value)}
                />
              </FormItem>
              <FormItem
                label='Pojemność'
                hide={formik.values.type !== DishType.DRINK}
                error={formik.touched.capacity && formik.errors.capacity}
              >
                <InputNumber
                  type='number'
                  name='capacity'
                  placeholder='Pojemność'
                  addonAfter={'ml'}
                  min={0}
                  value={formik.values.capacity}
                  onChange={value => formik.setFieldValue('capacity', value)}
                />
              </FormItem>
              <FormItem
                label='Typ dania'
                hide={formik.values.type !== DishType.MEAL}
                error={formik.touched.kind && formik.errors.kind}
              >
                <Select
                  value={formik.values.kind}
                  onChange={value => formik.setFieldValue('kind', value)}
                  options={Object.values(DishKind).map(kind => ({
                    label: DishKindNames[kind],
                    value: kind,
                  }))}
                />
              </FormItem>
              <FormItem
                label='Alkohol'
                hide={formik.values.type !== DishType.DRINK}
                error={formik.touched.alcohol && formik.errors.alcohol}
              >
                <InputNumber
                  type='number'
                  name='alcohol'
                  placeholder='Alkohol'
                  addonAfter='%'
                  min={0}
                  precision={2}
                  step={0.01}
                  value={formik.values.alcohol}
                  onChange={value => formik.setFieldValue('alcohol', value)}
                />
              </FormItem>
            </div>
            <div className='rightForm'>
              <FormItem
                label='Kalorie'
                error={formik.touched.calories && formik.errors.calories}
              >
                <InputNumber
                  type='number'
                  name='calories'
                  placeholder='Kalorie'
                  addonAfter='kcal'
                  min={0}
                  value={formik.values.calories}
                  onChange={value => formik.setFieldValue('calories', value)}
                />
              </FormItem>
              <FormItem
                label='Ostrość'
                hide={formik.values.type !== DishType.MEAL}
                error={formik.touched.taste && formik.errors.taste}
              >
                <Select
                  defaultValue={DishTaste.MILD}
                  value={formik.values.taste}
                  onChange={value => formik.setFieldValue('taste', value)}
                  options={Object.values(DishTaste).map(taste => ({
                    label: DishTasteNames[taste],
                    value: taste,
                  }))}
                />
              </FormItem>
            </div>
          </div>
        </div>
        <div className='rightForm'>
          <FormItemMultiLang
            label='Opis'
            required={true}
            errors={formik.touched.description && formik.errors.description}
          >
            <TextareaMultiLang
              showCount
              maxLength={200}
              rows={7}
              placeholder='Opis'
              value={formik.values.description}
              onChange={value => formik.setFieldValue('description', value)}
            />
          </FormItemMultiLang>
          <FormItem
            label={`Dodaj zdjęcie ${activeDishName.name}`}
            error={formik.touched.assets && (formik.errors.assets as string)}
          >
            <UploadImage
              assets={formik.values.assets}
              bucket={AssetBucket.DISH}
              multiple={true}
              onChange={assets => formik.setFieldValue('assets', assets)}
            />
          </FormItem>
        </div>
      </div>
      <div className='buttonWrapper'>
        <Button onClick={props.onCancel}>Anuluj</Button>
        <Button
          type='primary'
          htmlType='submit'
          loading={isFetching}
          icon={!dish && <PlusOutlined />}
          onClick={formik.submitForm}
        >
          {!dish ? 'Dodaj' : 'Zapisz'} {activeDishName.label}
        </Button>
      </div>
    </Form>
  );
};
