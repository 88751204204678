import { all } from 'redux-saga/effects';

import { watchUserSaga } from './user/saga';
import { watchRestaurantSaga } from './restaurants/saga';
import { watchMenuSaga } from './menu/saga';
import { watchCategorySaga } from './category/saga';
import { watchAssetsSaga } from './assets/saga';
import { watchDishesSaga } from './dish/saga';
import { watchSubscriptionSaga } from './subscriptions/saga';
import { watchOrganisationSaga } from './organisation/saga';

export default function* rootSaga(): Generator {
  yield all([
    watchUserSaga(),
    watchRestaurantSaga(),
    watchMenuSaga(),
    watchCategorySaga(),
    watchAssetsSaga(),
    watchDishesSaga(),
    watchSubscriptionSaga(),
    watchOrganisationSaga(),
  ]);
}
