import { ReactNode } from 'react';
import { Divider } from 'antd';

import './style.scss';

type ModalHeaderProps = {
  title: string;
  selectedElement: boolean;
  headerIcon?: ReactNode;
};

export const ModalHeader = (props: ModalHeaderProps) => {
  const { title, selectedElement, headerIcon } = props;

  return (
    <div className='headerModal'>
      <p>
        {selectedElement ? 'Edytuj ' : 'Dodaj '}
        {title}
      </p>
      {headerIcon && <Divider plain>{headerIcon}</Divider>}
    </div>
  );
};
