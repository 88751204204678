import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { Category } from './model';

export const getCategoryListRequest = (menuId: string) => action(CONST.GET_CATEGORY_LIST_REQUEST, { menuId });
export const getCategoryListSuccess = (categoryList: Category[]) =>
  action(CONST.GET_CATEGORY_LIST_SUCCESS, { categoryList });
export const getCategoryListError = (error: unknown) => action(CONST.GET_CATEGORY_LIST_ERROR, { error });

export const addCategoryRequest = (
  category: Omit<Category, '_id' | 'createdAt' | 'updatedAt'>,
  onSuccess?: () => void,
) => action(CONST.ADD_CATEGORY_REQUEST, { category, onSuccess });
export const addCategorySuccess = (category: Category) => action(CONST.ADD_CATEGORY_SUCCESS, { category });
export const addCategoryError = (error: unknown) => action(CONST.ADD_CATEGORY_ERROR, { error });

export const updateCategoryRequest = (category: Category, onSuccess?: () => void) =>
  action(CONST.UPDATE_CATEGORY_REQUEST, { category, onSuccess });
export const updateCategorySuccess = (category: Category) => action(CONST.UPDATE_CATEGORY_SUCCESS, { category });
export const updateCategoryError = (error: unknown) => action(CONST.UPDATE_CATEGORY_ERROR, { error });

export const deleteCategoryRequest = (categoryId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_CATEGORY_REQUEST, { categoryId, onSuccess });
export const deleteCategorySuccess = (categoryId: string) => action(CONST.DELETE_CATEGORY_SUCCESS, { categoryId });
export const deleteCategoryError = (error: unknown) => action(CONST.DELETE_CATEGORY_ERROR, { error });

export const getCategoryRequest = (categoryId: string) => action(CONST.GET_CATEGORY_REQUEST, { categoryId });
export const getCategorySuccess = (category: Category) => action(CONST.GET_CATEGORY_SUCCESS, { category });
export const getCategoryError = (error: unknown) => action(CONST.GET_CATEGORY_ERROR, { error });
