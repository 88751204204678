import React, { useEffect, useState } from 'react';
import { Button, Table, Modal, Popconfirm, Switch } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { User, UserGroup, UserGroupNames } from '../../reducers/user/model';
import { UserForm, UserFormValues } from '../../forms/user';
import { addUserRequest, getUsersRequest, updateUserRequest, deleteUserRequest } from '../../reducers/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';

import './style.scss';

export const SettingsUsers: React.FC = () => {
  const user = useSelector<RootReducerState, User | null>(state => state.user.userLogin);
  const usersList = useSelector<RootReducerState, User[]>(state => state.user.usersList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.user.isFetching);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersRequest());
  }, [dispatch]);

  const showModal = () => setIsModalVisible(true);

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedUser(undefined);
  };

  const openEditModal = (id: string) => {
    const selectedUser = usersList.find(user => user._id === id);
    setSelectedUser(selectedUser);
    setIsModalVisible(true);
  };

  const handleDeleteUser = (id: string) => {
    dispatch(
      deleteUserRequest(id, () => {
        setIsModalVisible(false);
        setSelectedUser(undefined);
      }),
    );
  };

  const onChangeActive = (status: boolean, item: any) => {
    dispatch(
      updateUserRequest({
        _id: item._id,
        ownerId: item.ownerId,
        active: status,
        email: item.email,
        group: item.group,
        language: item.language,
        name: item.name,
        surname: item.surname,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        phone: item.phone,
        onBoardingFinished: item.onBoardingFinished,
        organisationId: item.organisationId,
        salt: item.salt,
      }),
    );
  };

  const handleSubmit = (values: UserFormValues) => {
    let createdUser;
    if (user) {
      createdUser = {
        active: values.active,
        email: values.email,
        group: values.group,
        language: values.language,
        name: values.name,
        surname: values.surname,
        phone: '',
        onBoardingFinished: false,
      };
    }
    if (selectedUser) {
      dispatch(
        updateUserRequest(
          {
            ...selectedUser,
            ...createdUser,
          },
          () => {
            setIsModalVisible(false);
            setSelectedUser(undefined);
          },
        ),
      );
    } else {
      if (createdUser) {
        dispatch(
          addUserRequest(createdUser, () => {
            setIsModalVisible(false);
            setSelectedUser(undefined);
          }),
        );
      }
    }
  };

  const columns = [
    {
      title: 'Imię',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Nazwisko',
      dataIndex: 'surname',
      key: 'surname',
    },
    {
      title: 'Rola',
      dataIndex: 'group',
      key: 'group',
      render: (group: UserGroup) => <>{UserGroupNames[group]}</>,
    },
    {
      title: 'Aktywny',
      dataIndex: 'active',
      key: 'active',
      render: (status: boolean, item: any) => (
        <Switch
          checked={status}
          onChange={checked => onChangeActive(checked, item)}
        />
      ),
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      render: (_id: string) => (
        <>
          <Button
            onClick={() => openEditModal(_id)}
            type='link'
            icon={<EditOutlined />}
          />
          <Popconfirm
            title='Czy na pewno chcesz usunąć użytkownika?'
            onConfirm={() => handleDeleteUser(_id)}
            okText='Tak'
            cancelText='Nie'
          >
            <Button
              type='link'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <section className='page'>
      <div className='pageHeader'>
        <h1>Użytkownicy</h1>
        <Button onClick={showModal}>
          <PlusOutlined />
          <span>Dodaj użytkownika</span>
        </Button>
      </div>
      <div className='pageContent'>
        <Table
          rowKey={record => record._id}
          columns={columns}
          dataSource={usersList}
          loading={isFetching}
          pagination={false}
        />
      </div>
      <Modal
        width='80%'
        title={selectedUser ? 'Edytuj użytkownika' : 'Dodaj użytkownika'}
        open={isModalVisible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={null}
      >
        <UserForm
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          user={selectedUser}
        />
      </Modal>
    </section>
  );
};
