import { authService } from '../authService/authService';

export abstract class BaseAPI {
  protected hostAPI: string;

  protected constructor() {
    if (!process.env.REACT_APP_HOST_API) {
      throw Error('BaseAPI::HOST_API undefined');
    }

    this.hostAPI = process.env.REACT_APP_HOST_API;
  }

  private static getError(data: any): string {
    if (data.error?.error?.details) {
      const [detail] = Array.isArray(data.error?.error?.details) ? data.error?.error?.details : [];
      return detail?.message ?? '';
    } else {
      return data.error.message;
    }
  }

  private static async checkStatus(res: Response): Promise<Response> {
    // raises an error in case response status is not a success
    if (res.status >= 200 && res.status < 300) {
      return res;
    } else if (res.status >= 300 && res.status < 400) {
      return res;
    } else {
      if (res.status === 401) {
        authService.logout();
      }

      const err = {
        status: res.status,
        message: res.statusText,
      };

      // check error descriptions from service response
      try {
        const data = await res.json();

        // check error desc
        if (data.error_description != null) {
          err.message = data.error_description;
        } else if (data.message != null) {
          err.message = data.message;
        } else if (data.error != null) {
          if (res.status === 400) {
            err.message = BaseAPI.getError(data);
          } else {
            err.message = data.error.message;
          }
        }
      } catch (error) {
        err.message = `JSON parsing failed when checking response status`;
      }

      throw err;
    }
  }

  private static checkContent(res: Response): Response {
    if (res.status === 204) {
      return res;
    }
    const contentType = res.headers.get('content-type');
    if (contentType?.includes('application/json')) {
      return res;
    } else {
      throw new Error('Response content-type is not JSON.');
    }
  }

  protected async authFetch<T>(url: string, options: RequestInit = {}, customToken?: string): Promise<T> {
    // performs api calls sending the required authentication headers
    const headers: Record<string, string> = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (options.headers) {
      Object.assign(headers, options.headers);
    }

    const token = customToken ?? authService.getToken();
    if (token && !headers.Authorization) {
      headers.Authorization = `${token}`;
    }

    // extend headers
    Object.assign(options, { headers });

    return fetch(url, options)
      .then(BaseAPI.checkContent) // to raise errors for content not JSON
      .then(BaseAPI.checkStatus) // to raise errors for wrong status
      .then(res => (res.status !== 204 ? res.json() : res)); // to parse the response as json
  }

  protected async noAuthFetch<T>(url: string, options: RequestInit = {}): Promise<T> {
    // performs api calls without sending the required authentication headers
    const headers: Record<string, string> = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (options.headers) {
      Object.assign(headers, options.headers);
    }
    // extend headers
    Object.assign(options, { headers });

    return fetch(url, options)
      .then(BaseAPI.checkContent) // to raise errors for content not JSON
      .then(BaseAPI.checkStatus) // to raise errors for wrong status
      .then(res => (res.status !== 204 ? res.json() : res)); // to parse the response as json
  }
}
