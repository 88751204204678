import { FC } from 'react';
import { Button, Col, Input, Form, Row, Typography, Switch } from 'antd';
import { useFormik } from 'formik';
import { User } from '../../reducers/user/model';
import { FormItem } from '../../component/formItems/formItem/formItem';

import { initFormik } from './formik';
import { useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { OrganisationModel } from '../../reducers/organisation/model';

const grid = {
  lg: 12,
  md: 24,
  sm: 24,
  xs: 24,
};

export type OnboardingFormValues = {
  name: string;
  surname: string;
  phone: string;
  isCompany: boolean;
  street: string;
  postalCode: string;
  city: string;
  companyName: string;
  taxNumber: string;
  country: string;
};

export type OnboardingFormProps = {
  onSubmit: (values: OnboardingFormValues) => void;
};

export const OnboardingForm: FC<OnboardingFormProps> = props => {
  const user = useSelector<RootReducerState, User | null>(state => state.user.userLogin);
  const organisation = useSelector<RootReducerState, OrganisationModel | null>(
    state => state.organisation.organisation,
  );
  const isFetchingUser = useSelector<RootReducerState, boolean>(state => state.user.isFetching);
  const formik = useFormik(initFormik(props, user, organisation));

  return (
    <Form layout='vertical'>
      <Row gutter={24}>
        <Col {...grid}>
          <Typography.Title level={4}>Dane Twoje organizacji</Typography.Title>
          <FormItem
            label='Firma'
            required={true}
            error={formik.touched.isCompany && formik.errors.isCompany}
          >
            <Switch
              onChange={checked => formik.setFieldValue('isCompany', checked)}
              checked={formik.values.isCompany}
              style={{ marginBottom: '18px' }}
            />
          </FormItem>
          {formik.values.isCompany && (
            <>
              <FormItem
                label='Nazwa firmy'
                required={true}
                error={formik.touched.companyName && formik.errors.companyName}
              >
                <Input
                  name='companyName'
                  placeholder='Nazwa firmy'
                  size='large'
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                />
              </FormItem>
              <FormItem
                label='NIP'
                required={true}
                error={formik.touched.taxNumber && formik.errors.taxNumber}
              >
                <Input
                  name='taxNumber'
                  placeholder='NIP'
                  size='large'
                  value={formik.values.taxNumber}
                  onChange={formik.handleChange}
                />
              </FormItem>
            </>
          )}
          <FormItem
            label='Ulica, numer domu/numer budynku'
            required={true}
            error={formik.touched.street && formik.errors.street}
          >
            <Input
              name='street'
              placeholder='Ulica, numer domu/numer budynku'
              size='large'
              value={formik.values.street}
              onChange={formik.handleChange}
            />
          </FormItem>
          <FormItem
            label='Kod pocztowy'
            required={true}
            error={formik.touched.postalCode && formik.errors.postalCode}
          >
            <Input
              name='postalCode'
              placeholder='Kod pocztowy'
              size='large'
              value={formik.values.postalCode}
              onChange={formik.handleChange}
            />
          </FormItem>
          <FormItem
            label='Miejscowość'
            required={true}
            error={formik.touched.city && formik.errors.city}
          >
            <Input
              name='city'
              placeholder='Miejscowość'
              size='large'
              value={formik.values.city}
              onChange={formik.handleChange}
            />
          </FormItem>
          <FormItem
            label='Kraj'
            required={true}
            error={formik.touched.country && formik.errors.country}
          >
            <Input
              name='country'
              placeholder='Kraj'
              size='large'
              value={formik.values.country}
              onChange={formik.handleChange}
            />
          </FormItem>
        </Col>
        <Col {...grid}>
          <Typography.Title level={4}>Twoje dane</Typography.Title>
          <FormItem
            label='Imię'
            required={true}
            error={formik.touched.name && formik.errors.name}
          >
            <Input
              name='name'
              placeholder='Imię'
              size='large'
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </FormItem>
          <FormItem
            label='Nazwisko'
            required={true}
            error={formik.touched.surname && formik.errors.surname}
          >
            <Input
              name='surname'
              placeholder='Nazwisko'
              size='large'
              value={formik.values.surname}
              onChange={formik.handleChange}
            />
          </FormItem>
          <FormItem
            label='Telefon'
            required={true}
            error={formik.touched.phone && formik.errors.phone}
          >
            <Input
              name='phone'
              placeholder='Telefon'
              size='large'
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
          </FormItem>
        </Col>
      </Row>
      <Row justify='end'>
        <Col>
          <Button
            type='primary'
            htmlType='submit'
            loading={isFetchingUser}
            onClick={formik.submitForm}
          >
            {user ? 'Zapisz' : 'Dodaj'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
